import { Accordion, Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import IconBox from 'layouts/IconBox';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import Badge, { BadgeColor } from 'atoms/Badge';
import UploadArea, { UploadAreaSize } from 'atoms/FormField/FilesUpload/UploadArea';
import { addToDocHistory, resetUploadPrep, setUploadInProgress } from 'store/transactions/transactionDetailSlice';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { DocumentIntendedToBeUploaded, DocumentTypeClone } from 'common/api/miscellaneous';
import { UserProps, prepTransactionDocForUpload, updateTransaction } from 'common/api/transactions';
import { getDocumentName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { uploadFile } from 'utils/utils-upload';
import UploadedDocs from '../../../../UploadedDocs';
import './Signature.scss';

const Signature = ({
  documentTypeId,
  date,
  user,
  documents,
  signed,
}: {
  documentTypeId: string;
  date: string;
  user: UserProps | undefined;
  documents: DocumentIntendedToBeUploaded[] | null;
  signed: boolean;
}) => {
  const { documentTypesList } = useAppSelector(({ miscellaneous }) => miscellaneous);
  const {
    uploadPrepMultiple,
    activeTransaction: { tenantId },
    uploadInProgress,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const dispatch = useAppDispatch();

  const docKey = `sign-${documentTypeId}`;

  const docName = documentTypesList.find((obj: DocumentTypeClone) => obj.id === documentTypeId)?.name;

  const fileName = `Signed ${docName} v1.pdf`;

  const fileURL = documents !== null ? documents[0].url : null;

  const checkIfDocPrepedForUpload = () => {
    if (uploadPrepMultiple !== null) {
      const uploadMultipleDocsKeys = Object.keys(uploadPrepMultiple);
      for (let i = 0; i < uploadMultipleDocsKeys.length; i++) {
        if (uploadMultipleDocsKeys[i].includes(documentTypeId)) {
          return true;
        }
      }
    }
    return false;
  };

  const addSign = async () => {
    if (uploadPrepMultiple !== null) {
      dispatch(setUploadInProgress(documentTypeId));
      const docUploadDetails = uploadPrepMultiple[docKey];
      const doc = await uploadFile(docUploadDetails.uploadPrep, docUploadDetails.fileDetails, dispatch);

      dispatch(
        addToDocHistory({
          userId: user?.id,
          tenantId,
          pendingChanges: false,
          note: null,
          status: ValidDocumentStatus.Signed,
          documents: [documentTypeId],
          doc,
        }),
      );

      dispatch(updateTransaction({ approval: true }));
    }
  };

  const LowerContent = () => {
    if (uploadInProgress.includes(documentTypeId)) {
      return <Badge badgeColor={BadgeColor.ORANGE}>Upload In Progress</Badge>;
    }

    if (signed) {
      return (
        <div className="person color-blue-very-dark-grayish">
          Attached by &nbsp;
          <Avatar
            src={user?.photoUrl || ''}
            size={AvatarSize.Small}
            className="m-l-xs m-r-xs"
          />
          <span className="bold-person color-blue-very-dark">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
      );
    } else {
      return (
        <>
          <div className="pdf-main">
            <IconBox icon={Icons.Pdf} />
            <p className="inner-text">{fileName}</p>
            <Icon
              className="cross-icon"
              icon={Icons.Cross}
              onClick={() => dispatch(resetUploadPrep({ documentTypeId }))}
            />
          </div>
          <div className="d-flex action-btns m-t-ml">
            <Button
              className="btn grey-outline"
              onClick={() => dispatch(resetUploadPrep({ documentTypeId }))}
            >
              CANCEL
            </Button>
            <Button
              className="btn grey-bg"
              onClick={() => addSign()}
            >
              SAVE
            </Button>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className={`line-dot ${signed && 'bg-green'}`}></div>
      <div className="box-flex">
        <Accordion className={`approval-accordian ${signed && 'border-sm-green'} m-t-sm`}>
          <Accordion.Title
            active={true}
            index={1}
            className="p-sm"
          >
            <p className="date-title color-blue-very-dark-grayish">{date}</p>
            <p className="content-title">
              Signature
              <Badge
                badgeColor={signed ? BadgeColor.GREEN : BadgeColor.ORANGE}
                className="m-l-m"
              >
                {signed ? ValidDocumentStatus.Signed : 'PENDING'}
              </Badge>
            </p>
          </Accordion.Title>
          <Accordion.Content
            active={true}
            className="p-sm p-t-xxs"
          >
            {!signed && !checkIfDocPrepedForUpload() ? (
              <UploadArea
                prepUpload={prepTransactionDocForUpload}
                prepUploadParams={{ docId: docKey }}
                isMultipleUploads={false}
                uploadText={`Upload ${getDocumentName(documentTypeId, documentTypesList)}`}
                size={UploadAreaSize.SMALL}
              />
            ) : (
              <LowerContent />
            )}
          </Accordion.Content>
        </Accordion>
        {signed && (
          <UploadedDocs
            fileName={fileName!}
            fileURL={fileURL}
          />
        )}
      </div>
    </>
  );
};

export default Signature;
