import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export interface NewRoleInput {
  name: string;
}

interface CreateRoleQueryVariables {
  currentLandlordEntityId: string;
  params: NewRoleInput;
}

export const CREATE_ROLE = (role: NewRoleInput) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Create Role: currentLandlordEntityId is required');

  const variables: CreateRoleQueryVariables = {
    currentLandlordEntityId,
    params: role,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: RoleInput!) {
        createRole(currentLandlordEntityId: $currentLandlordEntityId, params: $params) {
          id
          approvalLevel
          name
          members {
            id
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
        }
      }
    `,
    variables,
  };
};

export const createRole = createAsyncThunk('roles/createRole', async (arg, { getState }) => {
  const {
    roles: { activeRole },
  } = getState() as RootState;

  const response = await client.mutate(CREATE_ROLE(activeRole));

  return response;
});
