enum NodeType {
  Provision = 'PROVISION',
  Clause = 'CLAUSE',
  ClauseReference = 'CLAUSE_REFERENCE',
  Text = 'TEXT',
  Parameter = 'PARAMETER',
  Table = 'TABLE',
  Comment = 'COMMENT'
}
export default NodeType;
