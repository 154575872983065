import { PermissionRow } from 'common/_classes/auth/adminPermissions';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { setIsModalOpen } from 'store/adminAuth/permissionDetails';
import { setPermission } from 'store/adminAuth/permissionDetails';
import { listPermissions } from 'common/api/auth/listPermissions';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { adminPermissionsColumnConfig } from './AdminPermissionsConfig';
import AdminPermissionsHeaderToolbar from './AdminPermissionsHeaderToolbar';

const PermissionsPage = () => {
  const dispatch = useAppDispatch();

  const {
    isLoading = false,
    permissionsList = [],
    search,
  } = useAppSelector((state: RootState) => state.adminListPermissions);

  const fetchPermissions = (params: PaginationAPIProps, searchValue?: string | null) => {
    // dispatch(listPermissions({ ...params }));
    dispatch(listPermissions({ ...params }));
  };

  const currentList: PermissionRow[] = permissionsList.map(permission => new PermissionRow(permission));
  const breadCrumb: BreadCrumbItem[] = [{ title: 'Permissions' }];

  const getPageHeaderProps = (): PageHeaderProps => {
    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <AdminPermissionsHeaderToolbar />,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  const onViewDetails = (id: string) => {
    const permission = permissionsList.find(permission => permission.id === id);
    if (permission) {
      dispatch(setPermission(permission));
      dispatch(setIsModalOpen(true));
    } else {
      console.error('Permission not found');
    }
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          fetchPermissions(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={adminPermissionsColumnConfig({ onViewDetails: onViewDetails })}
      />
    </PageLayout>
  );
};

export default PermissionsPage;
