import { IFunctionInputClone, OrganisationListingClone } from 'common/_classes';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import { OrganisationRowActionButtons } from './OrganisationRowActionButtons';

export class OrganisationsRow {
  id: string;
  name: string | null;
  status: string;
  users: number;
  properties: number;
  transactions: number;

  constructor(organisation: OrganisationListingClone) {
    this.id = organisation.id;
    this.name = organisation.name;
    this.status = organisation.operatingStatus;
    //TODO: Make these dynammic
    this.users = 0;
    this.properties = 0;
    this.transactions = 0;
  }
}

const statuses: StatusBadgeArgument[] = [
  { value: 'REVIEWED', color: BadgeColor.GREEN },
  { value: 'DRAFTING', color: BadgeColor.ORANGE },
];

export const OrganisationColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name',
      filter: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'users',
      header: 'Users',
      filter: true,
      filterPlaceholder: 'Search by User ID',
      filterField: 'users',
      sortable: true,
    },
    {
      field: 'properties',
      header: 'Properties',
      filter: true,
      filterPlaceholder: 'Search by Number of inputs',
      filterField: 'inputsLength',
      sortable: true,
      dataType: 'numeric',
      filterMatchMode: 'in',
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
    },
    {
      field: 'transactions',
      header: 'Transactions',
      width: '20rem',
      filterPlaceholder: 'Search by Inputs',
      filter: true,
      filterField: 'inputs',
      sortable: true,
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
    },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      filterPlaceholder: 'Search by status',
      filterField: 'status',
      body: (row: OrganisationsRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      className: 'status-column',
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: OrganisationsRow) => (
        <OrganisationRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
