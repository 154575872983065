import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { Editor as CoreEditor } from 'tinymce';
import { useAppDispatch, useAppSelector } from 'hooks';
import { computeAllIndexesForDocument } from 'components/Editor/Clause/ComputeIndex';
import { removeActiveClassFromTextNode } from 'components/Editor/PageEditor';
import 'components/Editor/PageEditor/EditorStyles.scss';
import 'components/Editor/PageEditor/PageEditor.scss';
import { getClauseIndexFrom } from 'components/Editor/editorGetClauseIndexFrom';
import { DocumentTab, getDocumentTabs } from 'components/PreviewTab/PreviewEditorMain';
import { EditorEvents, setup } from 'store/editor/setup';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import {
  clearActiveParameters,
  setActiveProvisionId,
  updateActivePreviewTabContents,
  updateDocType,
} from 'store/miscellaneous/miscellaneousSlice';
import { resetActiveNode, setClauseIndex } from 'store/nodes/nodesSlice';
import { DiscussionMode, resetTransactionChannels, setDiscussionMode } from 'store/transactions/transactionDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { DocumentTypeClone } from 'common/api/miscellaneous';
import { ContentNodeType, getNode } from 'common/api/nodes';
import { ContentClone } from 'common/api/provisions';
import { parseContent } from 'utils/utils-string';
import { PREVIEW_INFORMATION_TABS_OFFSET } from '../TenantPreviewSideMenu';
import { ProvisionListElementT, getListOfProvisionsFromContent } from '../TenantPreviewSideMenu/Tabs/TableOfContent';

// Get the NodeId from a list of Node Elements
export const getNodeIds = (elements: NodeListOf<Element>) => {
  return Array.from(elements)
    .map(element => element.getAttribute('data-node-id'))
    .filter(element => element) as string[];
};

const TenantPreviewEditorMain = ({ context }: { context: ExecuteContext }): JSX.Element => {
  const dispatch = useAppDispatch();

  const editorRef = useRef<CoreEditor | null>(null);
  const [tabNumber, setTabNumber] = useState<number>(1);
  const [showColor, setShowColor] = useState<boolean>(false);

  const { activePolicy, activePolicyContents } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionContents } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );
  const { formattedAnswersList, conditionAnswersList } = useAppSelector((state: RootState) => state.formattersListing);
  let { documentTypesList, activeDocType, activePreviewTabContents } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  const activeContext = context === ExecuteContext.Policy ? activePolicy : activeTransaction;
  const contents: ContentClone[] = context === ExecuteContext.Policy ? activePolicyContents : activeTransactionContents;

  const contextContents = contents?.map(({ content, documentType }) => {
    return { content: content, documentTypeId: documentType?.id };
  });

  const documentTabs: DocumentTab[] = getDocumentTabs(documentTypesList, activeContext, contents);

  const [hasEditorLoaded, setHasEditorLoaded] = useState<boolean>(false);

  const initCallback = (): void => {
    setHasEditorLoaded(true);
  };

  const editorPlugins = [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'preview',
    'help',
    'wordcount',
    // 'noneditable',
    'nonbreaking',
  ];

  /**
   * Handle clicks on Policy Page
   */
  const handleEditorClickEvents = (editor: CoreEditor): void => {
    // Add a new event on Editor
    editor.selection.editor.on(EditorEvents.Click, event => {
      // Get the ID from the current clicked element
      let nodeId = event.target.getAttribute('data-node-id');
      // Get the Type from the current clicked element
      let nodeType = event.target.getAttribute('data-node-type');

      // Check if clicked over Parameter, Clause or Text nodes
      if (nodeType === null) {
        // Try to get the content from the parent
        const checkParent = event.target.parentElement;
        // Get the ID from the current clicked parent's element
        if (checkParent !== null) {
          nodeId = checkParent.getAttribute('data-node-id');
          // Get the Type from the current clicked parent's element
          nodeType = checkParent.getAttribute('data-node-type');
        }
      }

      // Check if click on the Clause Index element
      if (nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX)) {
        let parent = event.target;

        // Try to find the Clause element
        for (let i = 0; i < 3 && parent; i++) {
          nodeId = nodeId ? nodeId : parent.getAttribute('data-node-id');
          nodeType = nodeType ? nodeType : parent.getAttribute('data-node-type');

          parent = parent.parentElement;
        }

        dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.CONTROL));
        // Get the Index of Clause by the ID
        const clauseIndex = getClauseIndexFrom(editor, nodeId);
        // Set the Index Numbering to be used on the sidebar (Orange Circle)
        dispatch(setClauseIndex(clauseIndex));
      }
      // Check if click on Parameter node, Text node or the Clause Index element
      if (
        nodeId &&
        ([ContentNodeType.PARAMETER, ContentNodeType.TEXT].includes(nodeType) ||
          nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX))
      ) {
        // Fetch the Node from the API and set it on Redux
        if (nodeType === ContentNodeType.PARAMETER) {
          dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.CONTROL));
        }
        if (nodeType === ContentNodeType.TEXT) {
          dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.CONTROL));
        }
        let updateEditor = removeActiveClassFromTextNode(editor);
        const [textNode] = updateEditor.dom.select(`[data-node-id="${nodeId}"]`);
        // Add active text node style
        textNode.classList.add('active-text-node');
        const updateContent = updateEditor.getContent();

        dispatch(
          updateActivePreviewTabContents({
            content: updateContent,
            active: true,
          }),
        );
        dispatch(getNode(nodeId));
      }
    });
  };

  /**
   * Handler for each document tab. It updated the redux with the current document ID
   */
  const updateActiveDoc = (index: number, doc: DocumentTab): void => {
    setHasEditorLoaded(false);
    dispatch(
      updateActivePreviewTabContents({
        content: '',
        active: true,
      }),
    );

    // Set number for specific array tab position
    setTabNumber(index + 1);
    // Update redux with the ID to set the active document on the page
    dispatch(updateDocType({ id: doc.id, ismendment: doc.isAmendment }));

    dispatch(resetActiveNode());

    // Reset Channels and Discussion
    if (context === ExecuteContext.Transaction) {
      dispatch(resetTransactionChannels());
      dispatch(setDiscussionMode(DiscussionMode.List));
    }

    dispatch(clearActiveParameters());
    dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.TABLE_OF_CONTENTS));
  };

  const { provisionsList } = useAppSelector((state: RootState) => state.provisionsListing);

  useEffect(() => {
    // Get the current Document
    const contextContent = contextContents?.find(({ documentTypeId }) => documentTypeId === activeDocType?.id);
    // If exist
    if (contextContent) {
      // Get the content from the current document
      const content: string = contextContent.content;
      // Dispatch a request to API to get clause condition result
      dispatch(
        updateActivePreviewTabContents({
          content,
          active: false,
        }),
      );
    } else {
      // Else: set the current text as an empty string
      dispatch(
        updateActivePreviewTabContents({
          content: '',
          active: false,
        }),
      );
    }

    const compList = getListOfProvisionsFromContent(contents, activeDocType, provisionsList);
    if (compList.length !== 0) {
      dispatch(
        setActiveProvisionId({
          provisionId: (compList[0] as ProvisionListElementT).id,
        }),
      );
    }
  }, [activeDocType, contents]);

  useEffect(() => {
    // Get the content from the current document
    const contextContent = contextContents?.find(({ documentTypeId }) => documentTypeId === activeDocType?.id);
    // Check if exist
    if (contextContent) {
      // Get the content from the current document
      const content: string = contextContent.content;
      // Convert string into HTML object
      const contentParsed: Document = parseContent(content);

      // Updated content with all modification done on the nodes
      const innerHTML = computeAllIndexesForDocument(contentParsed);
      // Update the Redux store with updated and contentParsed HTML
      dispatch(
        updateActivePreviewTabContents({
          content: innerHTML,
          active: false,
        }),
      );
    } else {
      // Else: set the current text as an empty string
      dispatch(
        updateActivePreviewTabContents({
          content: '',
          active: false,
        }),
      );
    }
  }, [formattedAnswersList, conditionAnswersList, showColor]);

  /**
   * Function for the hidden button. It toggles the show/hide colors
   */
  const handleShowColors = (): void => {
    setShowColor(show => !show);
  };

  if (!showColor) {
    activePreviewTabContents = activePreviewTabContents.replace(
      /<div data-node-type="section"[^>]*>[^~]*?<\/div>/g,
      '',
    );
  }

  return (
    <div className="page-body-container">
      <button onClick={handleShowColors}> </button>
      <div
        className="editor-tabs"
        data-test="document-tabs"
      >
        {documentTabs?.map((documentTab: DocumentTab, index: number) => (
          <div
            className={tabNumber === index + 1 ? 'is-active' : ''}
            onClick={() => {
              updateActiveDoc(index, documentTab);
            }}
            key={index}
          >
            {documentTab.name}
          </div>
        ))}
      </div>
      {documentTabs.map((documentTab: DocumentTab, index: number) => {
        if ((tabNumber === index + 1) === false) return null;

        return (
          <div
            key={documentTab.name}
            className="editor-body"
          >
            {!hasEditorLoaded && <h1>Loading editor...</h1>}
            <div
              className={hasEditorLoaded ? 'visible' : 'hidden'}
              data-test="provision-editor-container"
            >
              <Editor
                id={documentTab.name}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(_e, editor) => {
                  editorRef.current = editor;
                  handleEditorClickEvents(editor);
                }}
                value={activePreviewTabContents}
                init={{
                  menubar: false,
                  toolbar: false,
                  plugins: editorPlugins,
                  height: '100%',
                  width: '100%',
                  table_toolbar: '',
                  table_resize_bars: false,
                  contextmenu: false,
                  contextmenu_never_use_native: true,
                  font_size_formats: '8pt 9pt 10pt 11pt 12pt 14pt 15pt 18pt 24pt 36pt',
                  content_css: '/tinymce-css/tenant-view.css',
                  body_class: 'preview-tab-editor',
                  disable_nodechange: true,
                  extended_valid_elements:
                    context === ExecuteContext.Transaction ? 'span[data-iteration|*],svg[*]' : '',
                  setup: editor => setup(editor, initCallback, true),
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TenantPreviewEditorMain;
