import { Button, Icon } from 'semantic-ui-react';
import SelectField from 'atoms/FormField/Select';
import { DataType, FILTER_TYPE_OPTIONS, RowAction, RowProps } from 'components/ComparablesTable/RowConfig/RowProps';
import { Icons } from 'utils/utils-icons';
import { FilterFormLayout } from '.';
import FilterItemForm from '../FilterItemForm';

interface FlexibleFormItemProps {
  layout: FilterFormLayout;
  rowFilter: RowAction & RowProps;
  onFilterTypeChange: (rowFilter: RowAction & RowProps) => (key: string, value: string) => void;
  onChange: (index: number, rowFilter: RowAction & RowProps) => (key: string, value: any, updateRoot?: boolean) => void;
  onDeleteFilterItem: (index: number, rowFilter: RowAction & RowProps) => void;
  onAddFilterItem: (rowFilter: RowAction & RowProps) => void;
}

const FlexibleFormItem = ({
  layout,
  rowFilter,
  onFilterTypeChange,
  onChange,
  onDeleteFilterItem,
  onAddFilterItem,
}: FlexibleFormItemProps) => {
  const filterConditionsExists = rowFilter && rowFilter.filterConditions != undefined;
  const filterConditions = rowFilter?.filterConditions || [];

  const canShowDeleteButton = filterConditions.length > 1;
  const canShowAddButton =
    filterConditions.length < 2 && rowFilter?.dataType && ![DataType.BOOLEAN].includes(rowFilter.dataType);

  return (
    <div className={`inner-container ${layout}`}>
      {layout === FilterFormLayout.Row && <label>{rowFilter.label}</label>}

      <div className="filter-type-box">
        <SelectField
          options={FILTER_TYPE_OPTIONS}
          value={rowFilter.filterMatchType}
          disabled={filterConditions.length <= 1}
          onChange={onFilterTypeChange(rowFilter)}
        />
      </div>
      {filterConditionsExists ? (
        filterConditions.map((_, index) => (
          <div
            key={index}
            className="filter-item"
          >
            <FilterItemForm
              filterItemIndex={index}
              filter={rowFilter}
              onChange={onChange(index, rowFilter)}
            />

            {canShowDeleteButton && (
              <Button
                className="fluid-btn delete m-t-sm"
                onClick={() => onDeleteFilterItem(index, rowFilter)}
              >
                <Icon icon={Icons.Trash} /> Remove Rule
              </Button>
            )}
          </div>
        ))
      ) : (
        <></>
      )}

      {canShowAddButton && (
        <div className="p-l-sm p-r-sm m-b-ml">
          <Button
            className="fluid-btn add"
            onClick={() => onAddFilterItem(rowFilter)}
          >
            <Icon icon={Icons.Add} /> Add Rule
          </Button>
        </div>
      )}
    </div>
  );
};

export default FlexibleFormItem;
