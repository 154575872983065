import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Permission from 'common/model/Permission';
import { createPermission } from 'common/api/auth/createPermission';
import { updatePermission } from 'common/api/auth/updatePermission';

// import { MODE_OPTIONS } from 'common/api/miscellaneous';

// import models here

const initialState: {
  permission: Partial<Permission> | null;
  isLoading: boolean;
  isModalOpen: boolean;
} = {
  permission: null,
  isLoading: false,
  isModalOpen: false,
};

const permissionDetails = createSlice({
  name: 'permissionDetails',
  initialState,
  reducers: {
    updatePermissionProperty: (state, action) => {
      const { key, value } = action.payload;
      state.permission = { ...state.permission, [key]: value };
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(createPermission.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createPermission.fulfilled, (state, action) => {
      state.permission = action.payload.data.createPermission;
      state.isLoading = false;
      state.isModalOpen = false;
      toast.success('Permission created successfully');
    });
    builder.addCase(createPermission.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
      toast.error('Something went wrong trying to create permission');
    });
    builder.addCase(updatePermission.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updatePermission.fulfilled, (state, action) => {
      state.permission = action.payload.data.updatePermission;
      state.isLoading = false;
      state.isModalOpen = false;
      toast.success('Permission updated successfully');
    });
    builder.addCase(updatePermission.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
      toast.error('Something went wrong trying to update permission');
    });
    //add listPermissions reducers here
  },
});

export const { updatePermissionProperty, setIsModalOpen, setPermission } = permissionDetails.actions;

export default permissionDetails.reducer;
