import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetLandlordTransactionQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const GET_LANDLORD_TRANSACTION = (id: string | undefined) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!id) throw new Error('Get Landlord Transaction: id is required');
  if (!currentLandlordEntityId) throw new Error('Get Transaction: currentLandlordEntityId is required');

  const variables: GetLandlordTransactionQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $transactionId: ID!) {
        getLandlordTransaction(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
        ${TRANSACTION_FRAGMENT}
        }
      }
    `,
    variables,
  };
};

export const getLandlordTransaction = createAsyncThunk(
  'transactions/getLandlordTransaction',
  async ({ id }: { id: string | undefined; setContent?: boolean }) => {
    const response = await client.query(GET_LANDLORD_TRANSACTION(id));
    return response;
  },
);

export const TRANSACTION_FRAGMENT = `
  id
  identifier
  description
  documentTypeIds
  regulatories
  owner {
    id
    name
  }
  tenant {
    id
    name
  }
  policy {
    id
    documentTypeIds
    regulatories
    sequences {
      documentType {
        id
        name
      }
      elements {
        provision {
          id
          name
          provisionCategory {
            color
          }
        }
        order
      }
    }
  }
  contents {
    content
    isAmendment
    documentType {
      name
      id
    }
  }
  tenantContents {
    content
    documentType {
      name
      id
    }
  }
  answers {
    answer
    answerType
    dateOfAnswer
    fromPolicy
    fromInventory
    paramRef {
      parameterId
      tableId
      index
    }
    user {
      id
      firstName
      lastName
    }
    transaction {
      id
    }
  }
  premises {
    property {
      id
      buildingName
      measurementUnit
      floors {
        id
        name
        index
        comments
        characteristics
        ceilingHeight
        floorLoading
        activationDate
        deactivationDate
        spaces {
          id
          name
          registeredName
          type
          usedForOccupancyRate
          grossArea
          netArea
          lettableArea
          saleableArea
          efficiencyRatio
          comments
          characteristics
          activationDate
          deactivationDate
        }
        floorPlans {
          id
          reviewFlag
          floorPlan {
            id
            name
            path
            public
            url
          }
          revisionDate
          unitsNumber
          whollyOwned
          grossArea
          netArea
          lettableArea
          saleableArea
          efficiencyRatio
          insertedAt
        }
      }
    }
    spaceIds
    floorsStatus {
      floorId
      whole
    }
  }
  historyGeneral {
    date
    documents {
      document {
        id
        name
        path
        public
        url
        comment
        dateSigned
      }
      documentTypeId
    }
    status
    notes
    user {
      id
      firstName
      lastName
      photoUrl
    }
  }
  historyDocuments {
    documentTypeId
    history {
      user {
        id
        firstName
        lastName
        photoUrl
      }
      tenant {
        id
        name   
      }
      pendingChanges
      note
      date
      documents {
        id
        name
        path
        public
        url
      }
      version
      status
    }
  }
  alerts {
    type
    date
    message
    detail
  }
  translationInfo {
    contract {
      id
      identifier
    }
    date
  }
  documents {
    name
    document {
      id
      name
      path
      public
      comment
      dateSigned
      url
      insertedAt
    }
  }
  insertedAt
  updatedAt
`;
