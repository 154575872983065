import OrganisationsDashboard from 'views/organisation/Dashboard';
import OrganisationCreator from 'views/organisation/OrgCreator/OrganisationCreator';
import OrganisationDetails from 'views/organisation/OrgDetails/OrganisationDetails';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const OrganisationsRoutes = () => {
  const children = [
    {
      path: '/organisations/dashboard',
      element: isAuthenticated(<OrganisationsDashboard />),
    },
    {
      path: '/organisations/create',
      element: isAuthenticated(<OrganisationCreator />),
    },
    {
      path: '/organisations/:id/details',
      element: isAuthenticated(<OrganisationDetails />),
    },
  ];

  return [
    {
      path: '/organisations',
      children,
    },
  ];
};
export default OrganisationsRoutes;
