import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { TransactionMessageClone } from 'common/_classes/TransactionMessageClone';
import { RootState } from 'store';
import ThreadType from 'common/model/ThreadType';

interface ListTransactionMessagesQueryVariables {
  transactionChannelId: string | null | undefined;
  threads: ThreadType[];
}

export const LIST_TRANSACTION_MESSAGES = (transactionChannelId: string | null | undefined, threads: ThreadType[]) => {
  const variables: ListTransactionMessagesQueryVariables = {
    transactionChannelId: transactionChannelId,
    threads,
  };

  return {
    query: gql`
      query ($transactionChannelId: ID!, $threads: [ThreadType!]) {
        listTransactionMessages(transactionChannelId: $transactionChannelId, threads: $threads) {
          ${TransactionMessageClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const listTransactionMessages = createAsyncThunk(
  'transactions/listTransactionMessages',
  async ({ threads, channelId }: { threads: ThreadType[]; channelId?: string }, { getState }) => {
    const {
      transactionDetail: { activeTransactionChannel },
    } = getState() as RootState;
    const selectedChannelId = channelId ? channelId : activeTransactionChannel.id;

    const response = await client.query(LIST_TRANSACTION_MESSAGES(selectedChannelId, threads));
    return response;
  },
);
