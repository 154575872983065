import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import { Editor } from 'tinymce';
import { v4 } from 'uuid';
import NodeType from 'common/model/NodeType';
import { CREATE_NODE } from './createNode';
import { ContentNodeType } from './types';

export enum ContextType {
  fullContext = 'full-context',
  noContext = 'no-context',
}

export interface RefData {
  provisionId: string;
  docId: string;
  clauseId: string;
  contextType: ContextType;
}

const createClauseReferenceNode = createAsyncThunk(
  'nodes/createClauseReferenceNode',
  async (args: { editor: Editor; innerContent: string; refData: RefData; checkIfUpdate: boolean }, { getState }) => {
    const { editor, innerContent, refData, checkIfUpdate } = args;
    const state = getState() as RootState;
    const { activeProvision } = state.provisionDetail;
    const { activeNode } = state.nodes;

    let node;
    // Update existing node
    if (checkIfUpdate && activeNode) {
      node = editor.dom.select(`[data-node-id='${activeNode.id}']`)[0];
      node.textContent = innerContent;
    } else {
      // Create new node
      node = editor.dom.create('code', {}, innerContent);
      node.classList.add('clause-reference-wrapper');
      node.setAttribute('data-node-type', ContentNodeType.CLAUSE_REFERENCE);
      node.setAttribute('contenteditable', 'false');
    }

    node.setAttribute('data-clause-id', refData.clauseId);
    node.setAttribute('data-doc-id', refData.docId);
    node.setAttribute('data-provision-id', refData.provisionId);
    node.setAttribute('data-context-type', refData.contextType);

    // Create a new clause ref node
    if (!checkIfUpdate) {
      const bodyRequest = {
        level: 1,
        id: v4(),
        type: NodeType.ClauseReference,
        name: innerContent,
        provisionId: activeProvision.id,
        formatterId: '',
      };
      const responseCreate = await adminClient.mutate(CREATE_NODE(bodyRequest));
      const { id } = responseCreate.data.createNode;
      node.setAttribute('data-node-id', id || '');
      editor.selection.setNode(node);
      return responseCreate;
    } else {
      // Updates clause ref node
      editor.fire('change');
    }
  },
);

export { createClauseReferenceNode };
