import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetTransactionHistoryDocumentsQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const GET_TRANSACTION_HISTORY_DOCUMENTS = (id: string | undefined) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!id) throw new Error('Get Transaction History Documents: id is required');
  if (!currentLandlordEntityId)
    throw new Error('Get Transaction History Documents: currentLandlordEntityId is required');

  const variables: GetTransactionHistoryDocumentsQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $transactionId: ID!) {
        getLandlordTransaction(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
          historyDocuments {
            documentTypeId
            history {
              user {
                id
                firstName
                lastName
                photoUrl
              }
              tenant {
                id
                name
              }
              pendingChanges
              note
              date
              documents {
                id
                name
                path
                public
                url
              }
              version
              status
            }
          }
        }
      }
    `,
    variables,
  };
};

export const getTransactionHistoryDocuments = createAsyncThunk(
  'transactions/getTransactionHistoryDocuments',
  async ({ id }: { id: string | undefined }) => {
    const response = await client.query(GET_TRANSACTION_HISTORY_DOCUMENTS(id));
    return response;
  },
);
