import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { AmendmentInput } from '.';

const amendmentQueryFields = `
  id
  name
  index
  content
  category {
    id
    name
  }
  documentType {
    id
    name
  }
`;

interface UpdateAmendmentQueryVariables {
  amendmentId: string;
  params: ActiveAmendment;
}

const UPDATE_AMENDMENT = (amendment: ActiveAmendment) => {
  const amendmentId = amendment.id!;

  if (!amendmentId) throw new Error('Update Amendment: amendmentId is required');

  // Input on change is converting number to string
  const params: ActiveAmendment = amendment;

  delete params.id;

  const variables: UpdateAmendmentQueryVariables = {
    amendmentId,
    params,
  };

  return {
    mutation: gql`
      mutation ($amendmentId: ID!, $params: AmendmentInput!) {
        updateAmendment(amendmentId: $amendmentId, params: $params) {
         ${amendmentQueryFields}
        }
      }
    `,
    variables,
  };
};

export const updateAmendment = createAsyncThunk('transactions/updateAmendment', async (_args, { getState }) => {
  const {
    transactionDetail: { activeAmendment },
  } = getState() as RootState;

  const response = await client.mutate(UPDATE_AMENDMENT(activeAmendment));
  return response;
});
