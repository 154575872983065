import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import Country from 'common/model/Country';
import { evaluateAIQuestion } from 'common/api/aiQuestions';
import {
  CountryClone,
  DocumentTypeClone,
  getPDFDocument,
  getPdfHtml,
  listCountries,
  listDocumentTypes,
  listLanguages,
} from 'common/api/miscellaneous';
import { generateDocxDocument } from 'common/api/miscellaneous/generateDocxDocument';
import { listLandlordProperties } from 'common/api/miscellaneous/listLandlordProperties';
import { DROPDOWN_OPTION } from 'utils/UI';
import { downloadContextInDocx, downloadContextInPDF } from 'utils/utils-download';

// Information on usage of the different store data:
//
// *** generic data used across the system ***
//
// - countries : stores the list of countries
// - languages : stores the list of languages
// - documentTypesList/documentTypesOptions : stores the list of applicable
//   documents we use in the whole system (Offer letter, Lease, Side Agreement...)
//
// *** filters in policy/transaction ***
//
// - selectedProvisionFilter : used to store the provisionId filter:
//    a) in policy/transaction, in the parameter tab, used to store the provision filter
//    b) in policy/transaction, in the provision tab, when user clicks on the body of a
//       provision, it navigates to the parameter tab, and set the provision filter
// - parameterTypeFilter: used to store the parameterType filter:
//    a) in policy/transaction, in the parameter tab, used to store the parameterType filter
//    b) in policy/transaction, after creating a policy/transaction, the filter is initialized
//       to a specific set
//    c) in policy/transaction, in the preview tab, when user clicks on a specific provision,
//       he will want to see all the parameters of that provision => we reset the filter, in case.

export enum ParameterModes {
  Normal = 'NORMAL',
  Detailed = 'DETAILED',
}

export type ActiveDocType = {
  id: string | null;
  isAmendment?: boolean;
} | null;

interface MiscellaneousState {
  isLoading: boolean;
  countries: CountryClone[];
  languages: string[];
  documentTypesList: DocumentTypeClone[];
  documentTypesOptions: DROPDOWN_OPTION[];
  landlordProperties: any[];
  // TODO, maybe those specific Data should be on a specific store...
  evaluatedAnswers: any; // TODO check if it is needed ?
  activeDocType: ActiveDocType; // Indicate the active documentTypeId of the preview tab
  activePreviewTabContents: any;
  activeParameters: any[];
  selectedProvisionFilter: null;
  docProvisionsList: any[];
  activeProvisionId: string | null;
  conditional: boolean;
  parameterMode: ParameterModes;
  questionResult: any;
}

const initialState: MiscellaneousState = {
  isLoading: false,
  countries: [],
  languages: [],
  documentTypesList: [],
  documentTypesOptions: [],
  landlordProperties: [],
  // TODO, maybe those specific Data should be on a specific store...
  evaluatedAnswers: null, // TODO check if it is needed ?
  activeDocType: null,
  activePreviewTabContents: '',
  activeParameters: [],
  selectedProvisionFilter: null,
  docProvisionsList: [],
  activeProvisionId: null,
  conditional: true,
  parameterMode: ParameterModes.Normal,
  questionResult: '',
};

const miscellaneous = createSlice({
  name: 'miscellaneous',
  initialState,
  reducers: {
    updateDocType: (state, action) => {
      state.activeDocType = action.payload;
    },
    updateActivePreviewTabContents: (state, action) => {
      let { content, active } = action.payload;
      let updatedContent = '';
      if (action.payload !== '' && !active) {
        // remove active node class
        updatedContent = content.replace(/ active-text-node/gi, '');
      } else {
        updatedContent = content;
      }
      state.activePreviewTabContents = updatedContent;
    },
    setActiveParameters: (state, action) => {
      state.activeParameters = [...state.activeParameters, action.payload.data];
    },
    clearActiveParameters: state => {
      state.activeParameters = [];
    },
    setSelectedProvisionFilter: (state, action) => {
      state.selectedProvisionFilter = action.payload.provisionId;
    },
    setConditionalFilter: (state, action) => {
      state.conditional = action.payload.conditional;
    },
    setProvisionsList: (state, action) => {
      const provisions = action.payload.provisions;
      if (state.activeProvisionId === null && provisions.length !== 0) {
        state.activeProvisionId = provisions[0].id;
      }
      state.docProvisionsList = provisions;
    },
    setActiveProvisionId: (state, action) => {
      if (action.payload && state.activeProvisionId !== action.payload) {
        state.activeProvisionId = action.payload.provisionId;
      }
    },
    updateParameterMode: (state, action) => {
      state.parameterMode = action.payload;
    },
  },
  extraReducers: builder => {
    // listLandlordProperties
    builder.addCase(listLandlordProperties.fulfilled, (state, action) => {
      const propertiesList = action.payload.data.listProperties?.edges?.map((element: any) => element.node) || [];
      state.landlordProperties = propertiesList;
    });
    builder.addCase(listLandlordProperties.rejected, (state, action) => {
      console.warn(action.error);
      toast.error('listLandlordProperties API request failed');
    });

    //listCountries
    builder.addCase(listCountries.fulfilled, (state, action) => {
      const { listCountries } = action.payload.data;

      const countries = orderBy(
        listCountries.map((country: Country) => {
          let code: string = country.code;
          let name: string = country.name;

          // Manage cases with flags that are not available.
          let flag = code.toLowerCase();
          const noFlag = ['aq', 'bl', 'bq', 'cw', 'gg', 'im', 'je', 'mf', 'ss', 'sx', 'xk'];
          const checkNoFlag = noFlag.includes(flag);
          flag = checkNoFlag ? '' : flag;

          // In order to have the countries sorted with HK first...
          let index;
          if (code === 'HK') {
            index = 0;
            name = 'Hong Kong (China)';
          } else if (code === 'SG') {
            index = 1;
          } else if (code === 'CN') {
            index = 2;
          } else if (code === 'US') {
            index = 3;
          } else if (code === 'GB') {
            index = 4;
            name = 'United Kingdom';
          } else {
            index = 5;
          }

          const result: CountryClone = {
            name: name,
            code: code,
            flag: flag,
            index: index,
            key: code,
            value: code,
            text: name,
          };
          return result;
        }),
        ['index', 'text', ['asc']],
      );

      state.countries = countries;
    });
    builder.addCase(listCountries.rejected, (state, action) => {
      console.warn(action.error);
      toast.error('listCountries API request failed');
    });

    // listLanguages
    builder.addCase(listLanguages.fulfilled, (state, action) => {
      state.languages = action.payload.data.listLanguages.list;
    });
    builder.addCase(listLanguages.rejected, (_state, action) => {
      console.warn(action.error);
      toast.error('listLanguages API request failed');
    });

    //listDocumentTypes
    builder.addCase(listDocumentTypes.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listDocumentTypes.fulfilled, (state, action) => {
      const documentTypesList = action.payload.data.listDocumentTypes.edges.map((element: any) => element.node);
      const documentTypesListOrdered = orderBy(documentTypesList, 'order', 'asc');

      state.documentTypesList = documentTypesListOrdered;
      state.documentTypesOptions = documentTypesListOrdered.map((element: DocumentTypeClone, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: element.name,
          value: element.id,
        };
        return result;
      });
      state.isLoading = false;
    });
    builder.addCase(listDocumentTypes.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to load the Document Types');
    });

    //getPDFDocument
    builder.addCase(getPDFDocument.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getPDFDocument.fulfilled, (state, action) => {
      const { context, documentTypeId, contextName } = action.meta.arg;

      if (!documentTypeId) {
        downloadContextInPDF(action.payload.data.getPDFDocument, context, contextName);
      }
      state.isLoading = false;
    });
    builder.addCase(getPDFDocument.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('getPDFDocument API request failed');
    });

    //getPdfHtml
    builder.addCase(getPdfHtml.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getPdfHtml.fulfilled, (state, action) => {
      // const { html } = action.payload.data.getPdfHtml;
      // NOTE: This statement above is expecint and object, but a string is being returned.
      const html: string = action.payload.data.getPdfHtml;
      // const endOfInternalStyle = html.indexOf('</style>');
      // const newHtml = html.slice(0, endOfInternalStyle) + 'body{padding: 5rem}' + html.slice(endOfInternalStyle);
      // console.log(newHtml);
      const tab = window.open();
      // tab?.document.write(newHtml);
      tab?.document.write(html);
      tab?.document.close();
      state.isLoading = false;
    });
    builder.addCase(getPdfHtml.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('getPdfHtml API request rejected');
    });

    //generateDocxDocument
    builder.addCase(generateDocxDocument.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(generateDocxDocument.fulfilled, (state, action) => {
      const { context, documentTypeId, contextName } = action.meta.arg;

      if (!documentTypeId) {
        downloadContextInDocx(action.payload.data.generateDocxDocument, context, contextName);
      }
      state.isLoading = false;
    });
    builder.addCase(generateDocxDocument.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('generateDocxDocument API request failed');
    });

    /*
    builder.addCase(evaluateAIQuestion.fulfilled, (state, action) => {
      state.questionResult = action.payload.data.evaluateAiQuestion.result;
      state.isLoading = false;
    });
    builder.addCase(evaluateAIQuestion.rejected, (state, action) => {
      console.warn(action.error);
      toast.error('evaluateAIQuestion API request rejected');
    });*/
  },
});

export const {
  clearActiveParameters,
  setActiveParameters,
  setActiveProvisionId,
  setConditionalFilter,
  setProvisionsList,
  setSelectedProvisionFilter,
  updateActivePreviewTabContents,
  updateDocType,
  updateParameterMode,
} = miscellaneous.actions;

export default miscellaneous.reducer;
