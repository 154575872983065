import Parameter from 'common/model/Parameter';
import ParameterGroup from 'common/model/ParameterGroup';
import MParameterSubGroup from 'common/model/ParameterSubGroup';
import ParameterSubGroup from 'common/model/ParameterSubGroup';
import { OmitMultiple } from 'utils/UI';
import { TParameterGroupOnlyId, TParameterGroupPickName } from './ParameterGroupDetailClone';

export interface ParameterPickIndex extends OmitMultiple<Pick<Parameter, 'id' | 'index'>, 'index'> {
  index: number;
}

export type TParameterSubGroupPickName = Pick<ParameterSubGroup, 'id' | 'name'>;

export class ParameterSubGroupPickName implements TParameterSubGroupPickName {
  id: string;
  name: string;

  constructor(input: TParameterSubGroupPickName) {
    this.id = input.id;
    this.name = input.name;
  }

  static fragments(): string {
    return `
      id
      name
    `;
  }
}

interface IParameterSubGroupClone
  extends OmitMultiple<OmitMultiple<ParameterSubGroup, 'insertedAt' | 'updatedAt'>, 'parameterGroup'> {
  parameterGroup: ParameterGroup;
}

export class ParameterSubGroupClone implements IParameterSubGroupClone {
  id: string;
  name: string;
  index: number;
  parameterGroup: ParameterGroup;

  constructor(input: IParameterSubGroupClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.parameterGroup = input.parameterGroup;
  }

  static fragments(): string {
    return `
      id
      name
      index
      parameterGroup {
        id
        name
      }
    `;
  }
}

export interface ParameterSubGroupMainDataClone
  extends OmitMultiple<Pick<MParameterSubGroup, 'id' | 'name' | 'parameterGroup'>, 'parameterGroup'> {
  parameterGroup: TParameterGroupPickName;
}

interface IParameterSubGroupDetailClone
  extends OmitMultiple<
    Pick<MParameterSubGroup, 'id' | 'name' | 'index' | 'parameterGroup'>,
    'id' | 'name' | 'index' | 'parameterGroup'
  > {
  id?: string;
  name: string | null;
  index: number | null;
  parameterGroup: TParameterGroupOnlyId | null;
}

export class ParameterSubGroupDetailClone implements IParameterSubGroupDetailClone {
  id?: string;
  name: string | null;
  index: number | null;
  parameterGroup: TParameterGroupOnlyId | null;

  constructor(input: IParameterSubGroupDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.parameterGroup = input.parameterGroup;
  }

  static fragments(): string {
    return `
      id
      name
      index
      parameterGroup {
        id
      }
    `;
  }
}
