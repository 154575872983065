import { store } from 'store';
import { Editor } from 'tinymce';
import { setEditorShortcut } from 'store/editor/editorSlice';
import { KeyboardKeys } from 'utils/utils-clean-input';
import { hierarchyDown, hierarchyUp } from '../ContextClauseToolbar';
import { createClauseNode } from '../ContextNodeToolbar/NodeToolbarOptions';

/** Function to build context-menu based on the defined array of ToolbarButtons
 * @param {Editor} editor
 */
export const EditorShortcut = (editor: Editor) => {
  editor.on('keydown', event => {
    // Clause Creation
    if (event.shiftKey && event.key === KeyboardKeys.Enter) {
      event.preventDefault(); // Prevent default behavior
      // Fix for context menu appearing on click
      store.dispatch(setEditorShortcut(true));
      createClauseNode(editor);
      setTimeout(() => {
        store.dispatch(setEditorShortcut(false));
      }, 500);
    }
    // Hierarchy down
    else if (event.shiftKey && event.key === KeyboardKeys.Tab) {
      event.preventDefault(); // Prevent default behavior
      hierarchyDown(editor);
    }
    // Hierarchy up
    else if (event.key === KeyboardKeys.Tab) {
      event.preventDefault(); // Prevent default behavior
      hierarchyUp(editor);
    }
  });
};
