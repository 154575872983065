import { CDuration, CValuationReport } from 'common/_classes/valuationReport2';
import SortOrder from 'common/model/SortOrder';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterDataType } from '.';
import { applyBooleanFilter } from './FilterPopup/FilterItemForm/BooleanFilter';
import { applyDateFilter } from './FilterPopup/FilterItemForm/DateFilter';
import { applyDurationFilter } from './FilterPopup/FilterItemForm/DurationFilter';
import { applyListFilter } from './FilterPopup/FilterItemForm/ListFilter';
import { applyNumbersFilter } from './FilterPopup/FilterItemForm/NumberFilter';
import { applyPremiseFilter } from './FilterPopup/FilterItemForm/PremiseFilter';
import { applyStringFilter } from './FilterPopup/FilterItemForm/StringFilter';
import { DataType, RowAction, RowProps, getRowAction } from './RowConfig/RowProps';

/**
 * Apply Global Filters to contents
 * @param contents
 * @param rows
 * @param rowActions
 * @returns
 */
export const applyGlobalFilters = (
  contents: CValuationReport[],
  rows: RowProps[],
  filterData: FilterDataType,
): CValuationReport[] => {
  const filteredContent = contents.filter((content, index) =>
    index === 0 ? true : applyRowFilters(rows, content, filterData),
  );

  // If a row with sort order exists, sort!
  const rowActionWithSortOrder = Object.entries(filterData).find(data => {
    if (data[0] === 'activeConfigId') return false;

    const value = data[1] as RowAction & RowProps;

    if (value?.sortOrder) return true;

    return false;
  });

  if (rowActionWithSortOrder) {
    const config = rowActionWithSortOrder[1] as RowAction & RowProps;

    const rowConfig = rows.find(row => row.id === config.id);

    if (rowConfig) return applySorting(filteredContent, rowConfig, config.sortOrder);
  }

  return filteredContent;
};

export const applyRowFilters = (rows: RowProps[], content: CValuationReport, filterData: FilterDataType): boolean => {
  let output: boolean = true;
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    const rowAction = filterData[row.id];
    output = applyFilter(row, content, rowAction);
    if (!output) break;
  }
  return output;
};

const applyFilter = (row: RowProps, content: CValuationReport, rowAction?: RowAction): boolean => {
  if (
    !rowAction ||
    !rowAction.filterActive ||
    checkIfNotDefined(rowAction.filterConditions) ||
    checkIfNotDefined(rowAction.filterMatchType)
  )
    return true;

  if (row.filterFunction) {
    return row.filterFunction(row, content, rowAction);
  }

  switch (row.dataType) {
    case DataType.STRING:
      return applyStringFilter(row, content, rowAction);
    case DataType.NUMBER:
    case DataType.DECIMAL:
      return applyNumbersFilter(row, content, rowAction);
    case DataType.DATE:
      return applyDateFilter(row, content, rowAction);
    case DataType.DURATION:
      return applyDurationFilter(row, content, rowAction);
    case DataType.BOOLEAN:
      return applyBooleanFilter(row, content, rowAction);
    case DataType.LIST:
      return applyListFilter(row, content, rowAction);
    case DataType.PREMISE:
      return applyPremiseFilter(row, content, rowAction);
  }
  return true;
};

export const ascSort = (
  contents: CValuationReport[],
  field: string | string[],
  rowConfig: RowProps,
): CValuationReport[] => {
  return contents.sort((a, b) => {
    let fieldA = getNestedValue(a, field);
    let fieldB = getNestedValue(b, field);

    console.log(fieldA, fieldB);

    if (rowConfig.dataType === DataType.STRING) {
      fieldA = fieldA.trim().toLowerCase();
      fieldB = fieldB.trim().toLowerCase();
    }

    if (rowConfig.dataType === DataType.NUMBER) {
      return fieldA - fieldB;
    }

    if (rowConfig.dataType === DataType.DURATION) {
      return CDuration.compare(fieldA, fieldB);
    }

    if (fieldA < fieldB) {
      return -1;
    }

    if (fieldA > fieldB) {
      return 1;
    }

    return 0;
  });
};

export const descSort = (
  contents: CValuationReport[],
  field: string | string[],
  rowConfig: RowProps,
): CValuationReport[] => {
  return contents.sort((a, b) => {
    let fieldA = getNestedValue(a, field);
    let fieldB = getNestedValue(b, field);

    if (rowConfig.dataType === DataType.STRING) {
      fieldA = fieldA.trim().toLowerCase();
      fieldB = fieldB.trim().toLowerCase();
    }

    if (rowConfig.dataType === DataType.NUMBER) {
      return fieldB - fieldA;
    }

    if (rowConfig.dataType === DataType.DURATION) {
      return CDuration.compare(fieldB, fieldA);
    }

    if (fieldA < fieldB) {
      return 1;
    }

    if (fieldA > fieldB) {
      return -1;
    }

    return 0;
  });
};

/**
 * Apply Sorting
 * @param row
 * @param sortOrder
 * @returns
 *
 * Note: the sorting is done on all columns except the first one.
 */
export const applySorting = (
  contracts: CValuationReport[],
  rowConfig: RowProps,
  sortOrder?: SortOrder,
): CValuationReport[] => {
  const firstContract = contracts.shift();
  let sortedContracts: CValuationReport[] =
    sortOrder === SortOrder.Asc
      ? ascSort([...contracts], rowConfig.field!, rowConfig)
      : descSort([...contracts], rowConfig.field!, rowConfig);
  if (firstContract) sortedContracts.unshift(firstContract);
  return sortedContracts;
};
