import { useAppDispatch } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import { updateActiveTranslateToContract } from 'store/translatorsToContract/translatorDetailSlice';
import { ParameterGroup as IParameterGroup, Parameter } from 'common/missingModels';
import ParameterField from './ParameterField';

const ParameterGroup = ({
  parameterGroup,
  onChangeParamGroup,
  mode,
}: {
  parameterGroup: IParameterGroup;
  onChangeParamGroup: (updatedParams: Parameter[]) => void;
  mode: string;
}) => {
  const dispatch = useAppDispatch();
  const onChangeParam = (parameterKey: string, id: string) => {
    const updatedParams = parameters.map(param => (param.key === parameterKey ? { ...param, value: id } : param));
    onChangeParamGroup(updatedParams);
  };
  const parameters = parameterGroup?.value ?? [];
  return (
    <SectionComponent
      title={parameterGroup.name}
      key={parameterGroup.key}
      className="m-b-sm"
    >
      {parameters.map(parameter => (
        <ParameterField
          parameter={parameter}
          key={parameter.key}
          onChangeParam={onChangeParam}
          mode={mode}
        />
      ))}
    </SectionComponent>
  );
};

export default ParameterGroup;
