import { IFormatterDetail } from 'common/_classes';
import moment from 'moment';
import { checkIfNoneOrAnyComparator } from 'components/ConditionsTemplate/ConditionEquationRow';
import { ApprovalRequest } from 'views/transactions/CreatorViewer/Tabs/History/Tabs/Doc/ConfigureSendOutToTenantModal';
import { ActiveAmendment } from 'store/transactions/transactionDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ConditionType from 'common/model/ConditionType';
import { AnswerJsonMultiChoice, FormatterAnswerInput } from 'common/api/formatters';
import { PolicyProps } from 'common/api/policies';
import { TransactionProps } from 'common/api/transactions';
import { TranslateToContractClone } from 'common/api/translatorsToContract';
import { LocalRentPeriod } from '../common/api/contracts';

export const checkNotEmpty = (value: any | null | undefined): boolean => {
  return value !== null && value !== '' && value !== undefined;
};

export const checkEmpty = (value: null | string): boolean => {
  return value === null || value === '';
};

export const checkIfValueExists = (value: any | null | undefined): boolean => {
  return value !== null && value !== undefined;
};

/**
 * @description
 * Use for scenarios where both an empty string and
 * the string representation of zero are to be treated
 * as "no value" or "null-like"
 */
export const isNil = (value: string): boolean => !value || value === '0';

export const formatterDisabled = (activeFormatter: IFormatterDetail): boolean => {
  if (activeFormatter.inputs.some(input => !checkNotEmpty(input.type) || !checkNotEmpty(input.label))) {
    return true;
  }

  return (
    !checkNotEmpty(activeFormatter.name) ||
    activeFormatter.inputs.length === 0 ||
    !checkNotEmpty(activeFormatter.function)
  );
};

export const policyDisabled = ({ name, documentTypeIds }: PolicyProps): boolean => {
  return !checkNotEmpty(name) || documentTypeIds.length === 0;
};

export const amendmentDisabled = (amendment: ActiveAmendment): boolean => {
  return !checkNotEmpty(amendment.name) || !checkNotEmpty(amendment.content) || !checkNotEmpty(amendment.categoryId);
};

export const checkRequired = (required: boolean | undefined, value: string | null): ArrayofBoolAndString =>
  required && (!checkIfValueExists(value) || value?.length === 0) && value !== '0' ? [true, 'Required'] : [false, ''];

export const runValidations = (
  defaultRequired: RequiredValidationFunc,
  otherValidations: ValidationFuncs,
  value: string | null,
  required: boolean | undefined,
  setError: React.Dispatch<React.SetStateAction<string>>,
): void => {
  const [notValid, errorMessage] = defaultRequired(required, value);

  if (notValid) {
    setError(errorMessage);
    return;
  }

  if (otherValidations) {
    for (let i = 0; i < otherValidations.length; i++) {
      const [notValid, errorMessage] = otherValidations[i](value);

      if (notValid) {
        setError(errorMessage);
        return;
      }
    }
  }

  setError('');
};

export const floorPlanRevisionDateValidation = (previousDate: string | null, nextDate: string | null) => [
  (currentDate: string | null | number | Date): [boolean, string] => {
    if (previousDate === null || currentDate === null) {
      return [false, ''];
    }

    if (previousDate && currentDate && moment(previousDate).isAfter(currentDate)) {
      return [true, 'date should be after previous revision date'];
    }

    return [false, ''];
  },
  (currentDate: string | null): [boolean, string] => {
    if (nextDate === null || currentDate === null) {
      return [false, ''];
    }

    if (nextDate && currentDate && moment(nextDate).isBefore(currentDate)) {
      return [true, 'date should be before next revision date'];
    }

    return [false, ''];
  },
];

export const floorDisabled = (activeFloor: any): boolean => {
  const { name, index, activationDate, spaces, floorPlans, deactivationDate } = activeFloor;
  let isDisabled = false;
  isDisabled = !checkNotEmpty(name) || !checkNotEmpty(index) || !checkNotEmpty(activationDate);

  if (isDisabled) {
    return isDisabled;
  }

  if (deactivationDate && activationDate && moment(activationDate).isAfter(deactivationDate)) {
    return true;
  }

  if (spaces.length !== 0) {
    for (let i = 0; i < spaces.length; i++) {
      const { name, type, grossArea, efficiencyRatio, activationDate } = spaces[i];
      isDisabled =
        !checkNotEmpty(name) ||
        !checkNotEmpty(type) ||
        !checkNotEmpty(grossArea) ||
        !checkNotEmpty(efficiencyRatio) ||
        !checkNotEmpty(activationDate);
      if (isDisabled) {
        floorDisabled;
        return isDisabled;
      }
    }
  }
  if (floorPlans.length !== 0) {
    for (let i = 0; i < floorPlans.length; i++) {
      const { revisionDate, unitsNumber, whollyOwned, grossArea } = floorPlans[i];
      isDisabled =
        !checkNotEmpty(revisionDate) ||
        !checkNotEmpty(unitsNumber) ||
        !checkNotEmpty(whollyOwned) ||
        !checkNotEmpty(grossArea);
      if (isDisabled) {
        return isDisabled;
      }

      let previousRevisionDate = null;
      let nextRevisionDate = null;

      if (i > 0) {
        ({ revisionDate: previousRevisionDate } = floorPlans[i - 1]);
      }

      if (i + 1 <= floorPlans.length - 1) {
        ({ revisionDate: nextRevisionDate } = floorPlans[i + 1]);
      }

      const validations = floorPlanRevisionDateValidation(previousRevisionDate, nextRevisionDate);

      for (let j = 0; j < validations.length; j++) {
        [isDisabled] = validations[j](revisionDate);
        if (isDisabled) {
          return isDisabled;
        }
      }
    }
  }

  return isDisabled;
};

export const formatterAnswerDisabled = (
  answerInputs: FormatterAnswerInput[],
  formatterFunction: string | null,
): boolean => {
  if (!checkNotEmpty(formatterFunction)) {
    return true;
  }

  for (let i = 0; i < answerInputs.length; i++) {
    const { answer, label, answerType } = answerInputs[i];

    switch (answerType) {
      case AnswerTypes.SingleChoice:
        if (
          (answer as AnswerJsonMultiChoice).values.length === 0 &&
          (answer as AnswerJsonMultiChoice).inverses.length === 0
        ) {
          return true;
        }
        break;

      case AnswerTypes.MultiChoice:
        const noneOption = document.getElementById(`none-option-${i}`) as HTMLInputElement;
        if (noneOption === null) {
          return true;
        }
        if ((answer as AnswerJsonMultiChoice).values.length === 0 && !noneOption.checked) {
          return true;
        }
        break;
    }

    if (!checkNotEmpty(label)) return true;

    for (const [key, value] of Object.entries(answer)) {
      if (!checkNotEmpty(value)) return true;
    }
  }
  return false;
};

export const checkIfValidNodeConditions = (nodeConditions: any): boolean => {
  for (let i = 0; i < nodeConditions.length; i++) {
    const { list, operator } = nodeConditions[i];
    if (!checkNotEmpty(operator) || list.length === 0) {
      return false;
    } else {
      for (let j = 0; j < list.length; j++) {
        const { type, answer, comparator, paramRef, paramRef2 } = list[j];

        const isNoneOrAnySelected: boolean = checkIfNoneOrAnyComparator(comparator);

        switch (type) {
          case ConditionType.ParamValue:
            if (isNoneOrAnySelected) {
              if (!checkNotEmpty(paramRef.parameterId)) {
                return false;
              }
            } else {
              if (!checkNotEmpty(answer.answer) || !checkNotEmpty(comparator) || !checkNotEmpty(paramRef.parameterId)) {
                return false;
              } else if (answer.answer.hasOwnProperty('days')) {
                const { days, months, years } = answer.answer;
                if (!checkNotEmpty(days) || !checkNotEmpty(months) || !checkNotEmpty(years)) {
                  return false;
                }
              }
            }
            break;
          case ConditionType.ParamParam:
            if (
              !checkNotEmpty(comparator) ||
              !checkNotEmpty(paramRef.parameterId) ||
              !checkNotEmpty(paramRef2.parameterId)
            ) {
              return false;
            }
            break;
          case ConditionType.TableIndex:
            if (!checkNotEmpty(comparator) || !checkNotEmpty(paramRef.parameterId)) {
              return false;
            }
            break;
        }
      }
    }
  }

  return true;
};

export const transactionDisabled = ({
  identifier,
  ownerId,
  policyId,
  tenantId,
  premises,
}: TransactionProps): boolean => {
  const firstPremises = premises[0];
  const floorIds = firstPremises?.floorIds ?? [];
  const property = firstPremises?.property;
  for (let i = 0; i < floorIds.length; i++) {
    const { id, spaceIds, floorPortion } = floorIds[i];
    if (id === null || spaceIds.length === 0 || !checkNotEmpty(floorPortion)) {
      return true;
    }
  }

  if (!checkNotEmpty(property)) {
    return true;
  }

  return !checkNotEmpty(identifier) || !checkNotEmpty(ownerId) || !checkNotEmpty(policyId) || !checkNotEmpty(tenantId);
};

export const approvalRequestDisabled = ({ documents, pendingChanges, note }: ApprovalRequest): boolean =>
  documents.length === 0 || !checkNotEmpty(pendingChanges) || !checkNotEmpty(note);

export const translateToContractDisabled = ({
  active,
  index,
  function: translateFunc,
}: TranslateToContractClone): boolean => {
  return !checkNotEmpty(active) || !checkNotEmpty(index) || !checkNotEmpty(translateFunc);
};

/**
 * Checks that required attributes needed for
 * calculateEffectiveRent in 'LocalRentPeriod'/rentDescription
 * are present
 *
 * @param rentDescription
 * @returns boolean
 */
export const validateLocalRentPeriod = (rentDescription: LocalRentPeriod): boolean => {
  const {
    description,
    freePeriod: { hasRentFreePeriods, freePeriods },
    review,
  } = rentDescription;

  if (!description.startDate || !description.endDate) return false;

  if (hasRentFreePeriods || (freePeriods && freePeriods.length > 0)) {
    for (let j = 0; j < freePeriods.length; j++) {
      const free = freePeriods[j];
      if (!free.startDate || !free.endDate) return false;
    }
  }

  if (review && !review.type) return false;

  return true;
};
