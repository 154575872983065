import { createSlice } from '@reduxjs/toolkit';
import { OrganisationDetailClone } from 'common/_classes/organisations/OrganisationDetail';
import { toast } from 'react-toastify';
import { createOrganisation, getOrganisation } from 'common/api/organisations';
import { getFrozenObjectCopy } from 'utils/tsHelper';

interface OrganisationDetailState {
  activeOrganisation: OrganisationDetailClone;
  isLoading: boolean;
  activeOrganisationFrozenCopy: Readonly<OrganisationDetailClone>;
}

const initOrganisation: OrganisationDetailClone = {
  name: null,
  operatingStatus: null,
  orgKey: null,
  index: null,
  logo: null,
  archived: false,
  parentId: null,
  type: null,
};

const initialState: OrganisationDetailState = {
  activeOrganisation: initOrganisation,
  isLoading: false,
  activeOrganisationFrozenCopy: initOrganisation,
};

const organisationDetailSlice = createSlice({
  name: 'organisationDetail',
  initialState,
  reducers: {
    createNewOrganisation: state => {
      state.activeOrganisation = initOrganisation;
    },
    updateActiveOrganisationt: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeOrganisation[key] = value;
    },
  },
  extraReducers: builder => {
    //createOrganisation
    builder.addCase(createOrganisation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createOrganisation.fulfilled, (state, action) => {
      const { createOrganisation } = action.payload.data;
      state.activeOrganisation = createOrganisation;
    });
    builder.addCase(createOrganisation.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('createOrganisation API request rejected');
    });

    //getOrganisation
    builder.addCase(getOrganisation.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getOrganisation.fulfilled, (state, action) => {
      const organisation: OrganisationDetailClone = action.payload.data?.getOrganisation;
      state.activeOrganisation = organisation;
      state.activeOrganisationFrozenCopy = getFrozenObjectCopy(organisation) as OrganisationDetailClone;
      state.isLoading = false;
    });
    builder.addCase(getOrganisation.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getOrganisation API request rejected');
    });
  },
});

export const { createNewOrganisation, updateActiveOrganisationt } = organisationDetailSlice.actions;

export default organisationDetailSlice.reducer;
