import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeleteOrganisationQueryVariables {
  id: string;
}

export const DELETE_ORGANISATION = (id: string) => {
  if (!id) throw new Error('Organisation ID is required for deleting an organisation');

  const variables: DeleteOrganisationQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteOrganisation(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteOrganisation = createAsyncThunk(
  'organisations/deleteOrganisation',
  async ({ id }: { id: string }) => {
    const response = await client.mutate(DELETE_ORGANISATION(id));
    return response;
  },
);
