import { CValuationReport } from 'common/_classes/valuationReport2';

export const partAndWholeFloorCountsBody = ({
  data: {
    premisesCharacteristics: { partFloorsCount, wholeFloorsCount },
  },
}: CValuationReport): string => {
  let wholeFloor = '';
  let partFloor = '';

  if (wholeFloorsCount) wholeFloor += `${wholeFloorsCount} whole floor${wholeFloorsCount > 1 ? 's' : ''}`;
  if (partFloorsCount) partFloor += `${partFloorsCount} part floor${partFloorsCount > 1 ? 's' : ''}`;

  return `${wholeFloor}${wholeFloor ? `, ${partFloor}` : partFloor}`;
};
