import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { useDebounce } from 'components/Editor/PageEditor';
import ModalTemplate from 'components/ModalTemplate';
import Discussion from 'components/PreviewTab/Discussion';
import DiscussionTabs from 'components/PreviewTab/Discussion/DiscussionTabs';
import { DiscussionMode, setDiscussionMode, updateApprovalAnswer } from 'store/transactions/transactionDetailSlice';
import ThreadType from 'common/model/ThreadType';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import { updateTransactionDiscussionApproval } from 'common/api/transactions/approvals/updateTransactionDiscussionApproval';
import { getCurrentDateAndTime } from 'utils/utils-date';
import { ChangesBoxType } from '.';
import ChangesBox from './ChangesBox';
import './ParameterChanges.scss';

const ParameterChanges = ({
  isOpen,
  onModalStateUpdate,
  messageAnswer,
}: {
  isOpen: boolean;
  onModalStateUpdate: (isOpen: boolean) => void;
  messageAnswer: TransactionApprovalAnswer | undefined;
}) => {
  const dispatch = useAppDispatch();
  const date = getCurrentDateAndTime(messageAnswer?.transactionDiscussionApproval.dateSubmitted);
  const title = messageAnswer
    ? 'Parameter Changes linked to this discussion'
    : 'Latest Correspondence externally or internally on this issue';

  const draftApprovalMessage = messageAnswer?.transactionDiscussionApproval.draftMessage;
  const [draftMessage, setDraftMessage] = useState(draftApprovalMessage);
  const approvalId = messageAnswer?.transactionDiscussionApproval.id;

  useEffect(() => {
    setDraftMessage(draftApprovalMessage);
  }, [draftApprovalMessage]);

  const autoSaveDraft = useDebounce(() => {
    dispatch(updateTransactionDiscussionApproval({ approvalId: approvalId as string, draftMessage }));
  }, 2000);

  const onChangeApprovalMessage = (key: string, value: string) => {
    setDraftMessage(value);
    autoSaveDraft(approvalId, value, dispatch);
    dispatch(updateApprovalAnswer({ answerId: messageAnswer?.id as string, key: 'draftMessage', value }));
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={onModalStateUpdate}
      className="parameter-changes-modal"
      title={title}
      showHeader={true}
      exitBtnTitle="Exit"
    >
      <div className="grid grid-container">
        {messageAnswer ? (
          <>
            <div className="col-8">
              <ChangesBox
                type={ChangesBoxType.Parameter}
                date={date}
                rows={messageAnswer ? [messageAnswer] : []}
              />
            </div>
            <div className="col-4">
              <Discussion
                approval={true}
                draftMessage={draftMessage}
                onChangeApprovalMessage={onChangeApprovalMessage}
              />
            </div>
          </>
        ) : (
          <div className="col-12 ">
            <Discussion approval={true} />
          </div>
        )}
      </div>
    </ModalTemplate>
  );
};

export default ParameterChanges;
