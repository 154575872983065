import { Dispatch as DispatchRedux } from '@reduxjs/toolkit';
import { Editor } from '@tinymce/tinymce-react';
import { Dispatch, useEffect, useState } from 'react';
import { Button, Input, Popup, TextArea } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import AmendmentCreatorModal from 'views/transactions/AmendmentCreatorModal';
import TextAreaField from 'atoms/FormField/TextArea';
import DiscussionsTable from 'views/transactions/CreatorViewer/Tabs/History/Tabs/Doc/DiscussionsModal/DiscussionTable';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import {
  DiscussionMode,
  setDiscussionMode,
  setEditedMessage,
  toggleDiscussionStatus,
  updateChannel,
  updateDiscussionModalStatus,
} from 'store/transactions/transactionDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import ThreadType from 'common/model/ThreadType';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import TransactionChannel from 'common/model/TransactionChannel';
import TransactionChannelStatus from 'common/model/TransactionChannelStatus';
import { listContextParameters } from 'common/api/parameters';
import { AnswerProps } from 'common/api/policies/types';
import {
  ActiveTransactionChannel,
  createTransactionMessage,
  listTransactionChannels,
  listTransactionMessages,
  updateTransactionChannel,
  updateTransactionMessage,
} from 'common/api/transactions';
import { listDiscussionGroups } from 'common/api/transactions/listDiscussionGroups';
import { conditionalClassName, getActiveDocument } from 'utils/tsHelper';
import { ParameterCount, getParameterCount } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import DiscussionTabs from './DiscussionTabs';
import './Discussion.scss';

export interface TransactionChannelWithClause extends TransactionChannel {
  clauseName: string | null | undefined;
  position: number;
  isGeneralDiscussion: boolean;
}

export enum TabType {
  Approval = 'APPROVAL',
  Preview = 'PREVIEW',
}

export const scrollDownChat = ({ tabType, approvalId }: { tabType: TabType; approvalId?: string }) => {
  const query = tabType === TabType.Approval ? `#list-of-messages-${approvalId}` : '.discussion-tab > .tab';
  let elem = document.querySelector(query);
  if (elem !== null) {
    elem.scrollTop = elem.scrollHeight;
  }
};

export const getClause = (clauseId: string | undefined, activeDocument: any) => {
  const scrollItem = activeDocument?.querySelector(`[data-node-id="${clauseId}"]`);
  return scrollItem;
};

export const scrollToClause = (clauseId: string | undefined, activeDocument: any) => {
  const clause = getClause(clauseId, activeDocument);
  if (clause && clause !== null) {
    clause.scrollIntoView();
    window.scroll(0, 0);
  }
};

const getClauseNumberValue = (clause: Element, crossReference: boolean) => {
  let clauseNumberChilds;
  if (crossReference) {
    if (clause.childNodes.length === 1) {
      clauseNumberChilds = clause.childNodes[0].childNodes[0];
    } else {
      clauseNumberChilds = clause.childNodes[1].childNodes[0];
    }
  } else {
    clauseNumberChilds = clause.childNodes[0].childNodes[0];
  }

  if (clauseNumberChilds) {
    // Check if another inner tag exists ex. <strong>1.</strong>
    if (clauseNumberChilds.childNodes.length !== 0) {
      return clauseNumberChilds.childNodes[0].nodeValue;
    } else {
      return clauseNumberChilds.nodeValue;
    }
  } else {
    return '';
  }
};

// Get all the clause parents numbers and club them
// ex. For clause (A) with top level 1.1 -> 1.1.(a).(i).(A)
const getNumberWithParents = (clause: Element, currentLevelValue: string, crossReference: boolean): string => {
  // Push current level
  let clauseNumber = [currentLevelValue];
  while (clause !== null) {
    clause = clause.parentNode as Element;
    if (clause !== null) {
      // Get the value of the clause
      const number = getClauseNumberValue(clause, crossReference);
      // Top most level achieved
      if ([null, 'Provision: '].includes(number)) {
        break;
      }
      clauseNumber.push(number as string);
    }
  }
  // Remove level 1
  clauseNumber.pop();
  return clauseNumber.reverse().join('.');
};

/*
- Level 1 & 2 -> Parent not needed
- Level 7 & above -> Parent already present in the number
ex. Level 1 -> 1
    Level 2 -> 1.2
    Level 7 -> 2.1.1.1.1.1.1
*/
const getNumberWithoutParents = (clauseValue: string, dotCount: number): string => {
  // Level 1 & 2
  if (dotCount === 1) {
    // Remove dot from level 1
    return clauseValue?.length <= 2 ? clauseValue.replace('.', '') : clauseValue;
  }
  // Level 7 & above
  else {
    return clauseValue;
  }
};

// Get the clause number for different levels
export const getClauseNumber = (clause: Element, crossReference: boolean): string => {
  const clauseNumber = getClauseNumberValue(clause, crossReference);
  if (clauseNumber !== null) {
    // Count number of dots inside the number
    const dots = clauseNumber.match(/\./g);
    // If dots exists
    if (dots !== null) {
      // Level (1,2) & (7 & above)
      return getNumberWithoutParents(clauseNumber, dots.length);
    } else {
      // Level 3 to 6
      return getNumberWithParents(clause, clauseNumber, crossReference);
    }
  }
  return '';
};

export interface OnCreateTransactionMessageParams {
  message?: string;
  setMessage: Dispatch<any>;
  setMessagesList?: Dispatch<any>;
  approvalId?: string;
  channelId?: string;
  amendmentFlag: boolean;
  thread: ThreadType;
  dispatch: DispatchRedux;
}
export const onCreateMessage = ({
  message,
  setMessage,
  setMessagesList,
  channelId,
  approvalId,
  amendmentFlag,
  thread,
  dispatch,
}: OnCreateTransactionMessageParams): void => {
  if (!message || !channelId) return;

  dispatch(createTransactionMessage({ message, channelId, amendmentFlag, thread })).then((response: any) => {
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(listTransactionMessages({ channelId, threads: [thread] })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          if (setMessagesList) {
            const messages = response.payload.data.listTransactionMessages;
            setMessagesList(messages);
            setTimeout(() => {
              scrollDownChat({ tabType: TabType.Approval, approvalId });
            }, 300);
          } else {
            scrollDownChat({ tabType: TabType.Preview });
          }
        }
      });
    }
  });
  setMessage('');
};

export const MessageInputBox = ({
  message,
  setMessage,
  amendmentFlag,
  thread,
  channelId,
  setMessagesList,
  approvalId,
  edited,
}: {
  message: string;
  setMessage: Dispatch<any>;
  amendmentFlag: boolean;
  thread: ThreadType;
  channelId?: string;
  setMessagesList?: Dispatch<any>;
  approvalId?: string;
  edited?: boolean;
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <TextArea
        value={message}
        className="text-box p-xs"
        data-test="message-editor-box"
        onChange={e => setMessage(e.target.value)}
        placeholder="Type message..."
      />
      <Icon
        className={`message-icon ${conditionalClassName(
          amendmentFlag,
          'amendment',
        )} p-xxs m-r-sm m-t-xs ${conditionalClassName(message, 'cursor')}`}
        data-test="send-message-icon"
        icon={Icons.EmailMessage}
        onClick={() =>
          edited
            ? dispatch(updateTransactionMessage({ editedMessageText: message })).then((response: any) => {
                setMessage('');
                if (response.meta.requestStatus === 'fulfilled') {
                  dispatch(listTransactionMessages({ threads: [ThreadType.Landlord] }));
                }
              })
            : onCreateMessage({
                message,
                setMessage,
                setMessagesList,
                approvalId,
                channelId,
                amendmentFlag,
                thread,
                dispatch,
              })
        }
      />
    </>
  );
};

const Discussion = ({
  approval,
  draftMessage,
  setDraftMessage,
  onChangeApprovalMessage,
}: {
  approval?: boolean;
  draftMessage?: string;
  setDraftMessage?: Dispatch<any>;
  onChangeApprovalMessage?: (key: string, value: string) => void;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [amendmentFlag, setAmendmentFlag] = useState<boolean>(false);
  const [thread, setThread] = useState<ThreadType>(ThreadType.Common);

  const {
    activeTransactionChannel,
    discussionMode,
    activeTransactionAnswers,
    activeTransaction,
    editedMessage,
    discussionGroups,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { parametersCollection, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);

  const parametersAndDefinedAnswers: ParameterCount = getParameterCount(
    [...parametersCollection, ...parameterTablesCollection],
    activeTransactionAnswers,
  );

  const checkIfAllParametersDefined: boolean =
    parametersAndDefinedAnswers.definedAnswers === parametersAndDefinedAnswers.totalQuestions;

  const activeChannel: ActiveTransactionChannel = activeTransactionChannel;
  const trimTitle = () => {
    return activeChannel.title.length < 35 ? activeChannel.title : `${activeChannel.title.substring(0, 32)}...`;
  };

  useEffect(() => {
    if (activeChannel.id) {
      dispatch(listTransactionMessages({ threads: [thread] })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          setTimeout(() => {
            scrollDownChat({ tabType: TabType.Preview });
          }, 100);
        }
      });
    }
  }, [activeChannel.id, thread]);

  useEffect(() => {
    dispatch(listDiscussionGroups({}));
  }, [DiscussionMode.List]);

  useEffect(() => {
    scrollDownChat({ tabType: TabType.Preview });
  }, [message]);

  useEffect(() => {
    dispatch(
      listContextParameters({
        context: ExecuteContext.Transaction,
        contextId: activeTransaction.id,
        provisionId: null,
        conditional: true,
        mode: ParameterModes.Detailed,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (editedMessage) {
      setMessage(editedMessage.message);
    }
  }, [editedMessage]);

  const save = (): void => {
    dispatch(updateTransactionChannel());
    setIsOpen(false);
  };

  const onSwitchStatus = (): void => {
    dispatch(toggleDiscussionStatus());
    dispatch(updateTransactionChannel());
  };

  const open: boolean = activeTransactionChannel.status === TransactionChannelStatus.Open;

  const goBackToList = (): void => {
    dispatch(listTransactionChannels({ search }));
    dispatch(setDiscussionMode(DiscussionMode.List));
    setDraftMessage ? setDraftMessage('') : null;
  };

  const groupsSize: number = discussionGroups !== null ? discussionGroups.length : 0;

  const activeDocument: HTMLElement | undefined = getActiveDocument()?.documentElement;

  const getClauses = () => {
    if (activeDocument !== null) {
      const scrollElements: NodeListOf<Element> | undefined =
        activeDocument?.querySelectorAll(`[data-node-type="clause"]`);
      return scrollElements;
    } else {
      return [];
    }
  };

  const openCreateDiscussionModal = () => {
    dispatch(updateDiscussionModalStatus(true));
  };

  const DiscussionsList = () => {
    if (groupsSize === 0) {
      return (
        <p>
          No discussions created yet, please click the "ADD GENERAL DISCUSSION" button above to create a general
          discussion or click on a clause number in a document to open a clause related discussion.
        </p>
      );
    } else {
      return <DiscussionsTable condensed={true} />;
    }
  };

  const updateAmendment = (status: boolean) => {
    setMessage('');
    setAmendmentFlag(status);
  };

  let views: JSX.Element = <></>;

  /* There are 2 different views:
   *  - DiscussionMode.List : list all the discussion related to that transaction
   *  - DiscussionMode.View : to view the discussion thread of a specific discussion
   **/
  if (discussionMode === DiscussionMode.View || approval) {
    views = (
      <>
        {/* Discussion tab Header part (channel thread mode) with
             - 'breadcrumb' to go back to discussion list
             - title
             - button with popup to modify title
             - status/status switch button
         */}
        {!approval && (
          <div className="discussion-header p-b-xs d-flex justify-space-between align-center">
            <div className="d-flex align-center">
              <span
                className="edit-btn p-xxs m-r-s"
                onClick={goBackToList}
              >
                <Icon
                  icon={Icons.ArrowBackIOS}
                  className="m-l-xs"
                />
              </span>
              <h2
                className="title m-none"
                data-test="channel-title"
              >
                {trimTitle()}
              </h2>
            </div>

            <div className="d-flex align-center">
              <Popup
                trigger={
                  <span
                    className="edit-btn p-xxs m-r-s"
                    data-test="edit-button"
                  >
                    <Icon icon={Icons.Pencil} />
                  </span>
                }
                className="popup-title"
                open={isOpen}
                onOpen={() => setIsOpen(!isOpen)}
                content={
                  <>
                    <Input
                      className="title-input"
                      data-test="edit-channel-title"
                      value={activeChannel.title}
                      onChange={e => dispatch(updateChannel({ key: 'title', value: e.target.value }))}
                    />
                    <div className="flex">
                      <Button
                        className="cancel-btn m-t-xs p-t-xs"
                        onClick={() => setIsOpen(false)}
                      >
                        CANCEL
                      </Button>
                      <Button
                        className="save-btn m-t-xs p-t-xs"
                        onClick={save}
                      >
                        SAVE
                      </Button>
                    </div>
                  </>
                }
                on="click"
              />
              <Button
                size="mini"
                className={`status ${!open ? 'status-resolved' : ''}`}
                data-test="update-status"
                onClick={onSwitchStatus}
              >
                {activeTransactionChannel.status}
              </Button>
            </div>
          </div>
        )}

        <div className="discussion-tabs-wrapper">
          {/* Discussion threads, inluding the thread switch */}
          <DiscussionTabs
            setThread={setThread}
            thread={thread}
            checkIfAllParametersDefined={checkIfAllParametersDefined}
          />
        </div>

        {/* Toggle message type between {message, Amendment} */}
        <div className="m-t-xxs m-b-xxs message-type-selector">
          <span>
            <Button
              className={`type-selector-btn ${conditionalClassName(!amendmentFlag, 'active')}`}
              data-test="message-type"
              onClick={() => updateAmendment(false)}
            >
              {thread === ThreadType.Common ? <span>Draft Message</span> : 'Message'}
            </Button>
            <Button
              className={`type-selector-btn ${conditionalClassName(amendmentFlag, 'active')}`}
              data-test="amendment-type"
              onClick={() => updateAmendment(true)}
            >
              Amendment
            </Button>
          </span>
        </div>

        {/* Text Area to type message or Amendment  */}
        <div className={`${!amendmentFlag ? 'message-box' : 'message-box-editor'}`}>
          {thread === ThreadType.Common ? (
            <div>
              <TextAreaField
                fieldKey="draftMessage"
                value={draftMessage || ''}
                onChange={onChangeApprovalMessage || (() => {})}
              />
            </div>
          ) : (
            <>
              {amendmentFlag ? (
                <div
                  className="editor-box"
                  data-test="amendment-editor"
                >
                  <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    value={message}
                    data-test="mimi"
                    init={{
                      paste_as_text: true,
                      menubar: false,
                      statusbar: false,
                      plugins: 'lists codesample code',
                      placeholder: 'Type proposed amendment...',
                      height: '14.7857rem',
                      toolbar:
                        'formatselect bold underline italic forecolor backcolor bullist numlist alignleft aligncenter alignright alignjustify codesample code removeformat',
                      content_style:
                        'body { font-family:Urbanist; font-size:14px; color: #041630; font-weight: 400; caret-color: #E69701}',
                    }}
                    onEditorChange={(content: string) => setMessage(content)}
                  />
                </div>
              ) : (
                <MessageInputBox
                  message={message}
                  setMessage={setMessage}
                  amendmentFlag={amendmentFlag}
                  thread={thread}
                  channelId={activeChannel.id}
                  edited={editedMessage !== null}
                />
              )}
            </>
          )}
        </div>

        <AmendmentCreatorModal
          isOpen={amendmentFlag}
          setIsOpen={updateAmendment}
          fromTransactionDiscussion={true}
          onAfterAmendmentCreated={{
            params: {
              setMessage,
              channelId: activeChannel.id,
              amendmentFlag,
              thread,
              dispatch,
            },
            action: onCreateMessage,
          }}
        />
      </>
    );
  } else {
    views = (
      <>
        {/* Discussion tab Header part (list channel mode) with
             - title
             - button to add new channel discussion
          */}
        <div className="discussion-header p-b-xs m-b-sm d-flex justify-space-between align-center">
          <h2
            className="title m-none"
            onClick={() => dispatch(listTransactionChannels({}))}
          >
            List of discussions
          </h2>

          {/* NOTE
            The CreateDiscussionModal the button below activates is already on this page.
            It has already been imported in "src\components\PreviewTab\index.tsx" file, so
            no need to re-import it here */}
          <Button
            className="btn grey-bg"
            onClick={openCreateDiscussionModal}
          >
            ADD GENERAL DISCUSSION
          </Button>
        </div>

        <DiscussionsList />
      </>
    );
  }

  return <div className="discussion-wrapper p-t-xs">{views}</div>;
};

export default Discussion;
