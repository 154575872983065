import { CValuationReport } from 'common/_classes/valuationReport2';
import { ReactElement, ReactNode } from 'react';
import SortOrder from 'common/model/SortOrder';
import { DROPDOWN_OPTION } from 'utils/UI';
import { FilterItemChildProps } from '../FilterPopup/FilterItemForm';

export enum DataType {
  STRING,
  NUMBER,
  DECIMAL,
  BOOLEAN,
  DATE,
  DURATION,
  LIST,
  PREMISE,
}

export interface ComparablesTableFilterDropdownOptions extends Omit<DROPDOWN_OPTION, 'value'> {
  value: FilterMode;
}

export enum FilterOnchangeKeys {
  filterMode = 'filterMode',
  filterValue = 'filterValue',
}

export enum FilterMode {
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  STARTS_WITH = 'STARTS_WITH',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS',
}

export enum FilterMatchType {
  ALL = 'ALL',
  ANY = 'ANY',
}

interface ComparablesTableFilterMatchTypeDropdownOptions extends Omit<DROPDOWN_OPTION, 'value'> {
  value: FilterMatchType;
}

export const FILTER_TYPE_OPTIONS: ComparablesTableFilterMatchTypeDropdownOptions[] = [
  { key: 1, text: 'Match All', value: FilterMatchType.ALL },
  { key: 2, text: 'Match Any', value: FilterMatchType.ANY },
];

export interface FilterItem {
  filterMode: FilterMode | null;
  filterValue: any;
}

/**
 *
 * @returns new instance of "FilterItem"
 */
export const initFilterItem = (dataType?: DataType): FilterItem => ({
  filterMode: null,
  filterValue:
    dataType === DataType.DURATION
      ? {
          years: null,
          months: null,
          days: null,
        }
      : null,
});

export type FilterConditions = FilterItem[];

/**
 *
 * @returns new instance of "FilterItem"
 */
export const initFilterCondition = (dataType?: DataType): FilterConditions => [initFilterItem(dataType)];

export enum RowLabel {
  BUILDING = 'Building',
  PREMISES = 'Premises',
  TENANT = 'Tenant',
  PREMISES_SIZE = 'Premises size',
  FACE_RENT_PSF_PCM = 'Face rent (psf pcm)',
  EFFECTIVE_RENT_PSF_PCM = 'Effective rent (psf pcm)',
  TERM_SECTION = 'TERM',
  TERM_START_DATE = 'Term start date',
  TERM_END_DATE = 'Term end date',
  TERM_LENGTH = 'Term length',
  RENT_FREE_AND_EARLY_OCCUPATION_SECTION = 'RENT FREE & EARLY OCCUPATION',
  RENT_FREE_PERIOD = 'Rent free period',
  INCENTIVE_RENT_FREE = 'Incentive rent free',
  EARLY_OCCUPATION_LICENSE = 'Early occupation license',
  RENT_AND_RENT_REVIEW_SECTION = 'RENT & RENT REVIEW',
  DATE_INITIAL_RENT_FIRST_AGREED = 'Date initial rent first agreed',
  DATE_BINDING_CONTRACT_WAS_ORIGINALLY_AGREED = 'Date binding contract was originally agreed',
  VACANCY_RATE_AT_RENT_AGREEMENT = 'Vacancy rate at the time rent is agreed',
  RENT_REVIEW_DETAILS = 'Rent review details',
  RENTS_AGREED_OR_DETERMINED = 'Rents agreed/determined',
  SECURITY_SECTION = 'SECURITY',
  SECURITY_MONTHS = 'Security (# months)',
  SECURITY_AMOUNT = 'Security (HKD$)',
  SPECIAL_RIGHTS_SECTION = 'SPECIAL RIGHTS',
  OPTION_TO_RENEW = 'Option to renew',
  RIGHT_TO_SURRENDER = 'Right to surrender',
  EXPANSION_RIGHT = 'Expansion right',
  RIGHT_TO_SUBLET = 'Right to sublet',
  RIGHT_TO_SHARE = 'Right to share',
  RIGHT_TO_ASSIGN = 'Right to assign',
  RIGHT_TO_REFUSE = 'Right to refuse',
  SALE_AND_REDEVELOPMENT = 'Sale & redevelopment',
  BUILDING_CHARACTERISTICS_SECTION = 'BUILDING CHARACTERISTICS',
  BUILDING_TYPE = 'Building type',
  COVERED_ACCESS_TO_MTR = 'Covered access to MTR',
  CENTRAL_AC = 'Central AC',
  CURTAIN_WALL = 'Curtain wall',
  PREMISES_CHARACTERISTICS_SECTION = 'PREMISES CHARACTERISTICS',
  PERMITTED_USE = 'Permitted use',
  SPECIFIC_USE = 'Specific use',
  HANDOVER_CONDITION = 'Handover condition',
  WHOLE_OR_PART_FLOORS = '# whole floors, # part floors',
  VIEW = 'View',
  EFFICIENCY_PERCENTAGE = '% efficiency',
  OTHER_SECTION = 'OTHER',
  SPECIAL_REMARKS = 'Special remarks',
  TENANT_INDUSTRY = 'Tenant industry',
  NATURE_OF_LEASE = 'Nature of lease',
  AGENT_COMPANY = 'Agent Company',
  AGENT_NAME = 'Agent Name',
}

export interface RowProps {
  /**
   * Unique ID
   */
  id: number;

  /**
   * Label of Row element
   */
  label: RowLabel;

  /**
   * Key for the row in content object
   */
  field?: string | string[];

  /**
   * Custom Class for row
   */
  columnClass?: string;

  /**
   * true if row is an heading item
   */
  isHeading?: boolean;

  /**
   * Data Type of row item
   *
   */
  dataType?: DataType;

  /**
   * Options for multiselect dropdown
   */
  options?: Array<{ value: string }>;

  /**
   * Custom template for row item
   * @param item
   * @returns
   */
  body?: (item: any) => string | ReactNode;

  /**
   * Custom Filter element for row item
   * @param props
   * @returns
   */
  filterElement?: (props: FilterItemChildProps) => ReactElement;

  /**
   * Function that runs to get default filters for this row
   */
  defaultFilter?: (report: CValuationReport, rowConfig: RowProps) => RowProps & RowAction;

  /**
   * Custom filter function for row item
   */
  filterFunction?: (row: RowProps, content: any, rowAction?: RowAction) => boolean;

  /**
   * true if you want to hide filter
   */
  hideFilter?: boolean;

  /**
   * True if you want to hide sorting
   */
  hideSorting?: boolean;
}

/**
 * RowAction is used to saves all the actions (filtering and sorting) that needs to be done on each row.
 * When user clicks on the 'sort' button of a row, rowActions is updated.
 */
export interface RowAction {
  /**
   * Unique ID
   */
  id: number;

  /**
   * Determines if the filter should match all items in "filterConditions" or any item in "filterConditions"
   */
  filterMatchType?: FilterMatchType;

  /**
   * An array of filter items with a maximum of two entries.
   */
  filterConditions?: FilterConditions;

  /**
   * Sorting type of row
   */
  sortOrder?: SortOrder;

  filterActive?: boolean;
}

/**
 *
 * @param rowConfig
 * @param rowActions
 * @returns the RowAction that corresponds to the rrowConfig.
 */
export const getRowAction = (rowConfig: RowProps, rowActions: RowAction[]): RowAction | undefined => {
  return rowActions.find(row => row.id === rowConfig.id);
};
