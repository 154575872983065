import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import { FilterOnchangeKeys, RowAction, RowProps } from '../../RowConfig/RowProps';

export function applyBooleanFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyBooleanFilter: field key is missing');

  let value: string = getNestedValue(content, rowConfig.field);
  if (checkIfNotDefined(value) || !rowAction.filterConditions) {
    return false;
  }

  return rowAction.filterConditions[0].filterValue === value;
}

const BooleanFilter = ({ label, onChange, filterItem }: FilterItemChildProps): JSX.Element => {
  return (
    <div className="d-flex-column align-center">
      <label
        htmlFor="verified-filter"
        className="font-bold m-b-xs"
      >
        {label}
      </label>

      <TriStateCheckbox
        value={filterItem.filterValue}
        onChange={e => onChange(FilterOnchangeKeys.filterValue, e.value as boolean)}
      />
    </div>
  );
};

export default BooleanFilter;
