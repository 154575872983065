import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import { WHOLE_OR_PART_OPTIONS } from 'utils/UI';

export const surrenderRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { surrenderFlag, surrenderRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(surrenderFlag)}</div>}
      content={
        surrenderRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === surrenderRights.wholeOrPart,
            )?.text;

            const earliestDate = surrenderRights.earliestDate
              ? moment(surrenderRights.earliestDate).format('DD/MM/YYYY')
              : null;
            const latestDate = surrenderRights.latestDate
              ? moment(surrenderRights.latestDate).format('DD/MM/YYYY')
              : null;

            return (
              <div className="d-flex-column flex-gap-xxs">
                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {earliestDate ? (
                  <span>
                    Earliest date: <strong>{earliestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {latestDate ? (
                  <span>
                    Latest date: <strong>{latestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {surrenderRights.noticePeriodMonths ? (
                  <span>
                    Notice period:{' '}
                    <strong>
                      {surrenderRights.noticePeriodMonths} month{surrenderRights.noticePeriodMonths > 1 ? 's' : ''}
                    </strong>
                  </span>
                ) : (
                  <></>
                )}

                {surrenderRights.compensationFormula ? (
                  <span>
                    Compensation formula: <strong>{surrenderRights.compensationFormula}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!surrenderFlag}
    />
  );
};
