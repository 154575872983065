import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { TransactionChannelClone } from 'common/_classes';
import { RootState } from 'store';

interface ListTransactionChannelsQueryVariables {
  transactionId: string | null | undefined;
  documentTypeId: string | null;
  search: string | undefined;
}

const LIST_TRANSACTION_CHANNELS = (
  transactionId: string | null | undefined,
  documentTypeId: string | null,
  search: string | undefined,
) => {
  if (!transactionId) throw new Error('List Transaction Channels: transactionId is required');
  if (!documentTypeId) throw new Error('List Transaction Channels: documentTypeId is required');

  const variables: ListTransactionChannelsQueryVariables = {
    transactionId: transactionId,
    documentTypeId: documentTypeId,
    search,
  };

  return {
    query: gql`
      query ($transactionId: ID!, $documentTypeId: ID!, $search: String) {
        listTransactionChannels(
          transactionId: $transactionId
          documentTypeId: $documentTypeId
          filter: { search: $search }
        ) {
          ${TransactionChannelClone.fragments()}
        }
      }
    `,
    variables,
  };
};

/**
 * Chat/discussion room for negotiation API calls
 */
export const listTransactionChannels = createAsyncThunk(
  'transactions/listTransactionChannels',
  async ({ search }: { search?: string; isSearching?: boolean }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
      miscellaneous: { activeDocType },
    } = getState() as RootState;

    const response = await client.query(
      LIST_TRANSACTION_CHANNELS(activeTransaction.id, activeDocType?.id || null, search),
    );
    return response;
  },
);
