import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './IconBox.scss';

const IconBox = ({ icon }: { icon: Icons }) => {
  const pdfIcon = icon === Icons.Pdf;
  return (
    <div className={`pdf-box border-sm-blue-very-dark ${pdfIcon ? 'bg-gray-very-light' : ''}`}>
      <Icon icon={icon} />
    </div>
  );
};

export default IconBox;
