import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { POLICY_TABS_OFFSET } from 'views/policy/CreatorViewer/Tabs/PolicyTabs';
import ExecuteContext from 'common/model/ExecuteContext';
import { DropdownProps } from 'common/api/miscellaneous';
import { AnswerProps, PolicyProps, PolicyProvisionDetailClone, SequenceProps } from 'common/api/policies';
import {
  createPolicy,
  createPolicyAnswers,
  createPolicyProvision,
  deletePolicyAllAnswers,
  deletePolicyParamRefAnswers,
  deletePolicyProvision,
  getPolicy,
  listPolicyProvisions,
  listPolicySequences,
  prepPolicyAnswerOfImageTypeForUpload,
  updatePolicy,
  updatePolicyContent,
  updatePolicyProvision,
  updatePolicySequences,
} from 'common/api/policies';
import { setCrossRefInPolicy } from 'common/api/policies/setCrossRefInPolicy';
import { ContentClone } from 'common/api/provisions';
import { checkIfNotDefined, getCurrentLandlordEntityId } from 'utils/tsHelper';
import { provisionsDropdown } from 'utils/tsHelper';
import { CounterOptions, updateIterationHelper } from 'utils/utils-answer';
import { getCurrentDateTimeISO } from 'utils/utils-date';

/*
 * We are going to have 3 modes for displaying the form:
 *
 *   - 'create' shows an empty form, that can be saved to create a new policy.
 *     Upon clicking 'save', it is saved and edition is disabled
 *
 *   - 'show' exhibits an existing policy, the fields have the values for that policy,
 *     but you can't edit it before you click 'Edit'
 *
 *   - 'edit' shows an existing policy, the fields start with their values for
 *     the policy, but edition is enabled. Upon clicking 'save', it is saved
 *     and edition is again disabled
 */

const newPolicy = {
  name: '',
  documentTypeIds: [],
  description: '',
  entityId: null as string | null,
  regulatories: [],
  language: '',
  useType: '',
  jurisdiction: '',
};

interface PolicyDetailState {
  isLoading: boolean;
  activePolicy: PolicyProps;
  activePolicySequences: SequenceProps[];
  activePolicyContents: ContentClone[];
  activePolicyProvisions: PolicyProvisionDetailClone[];
  activePolicyAnswers: AnswerProps[];
  showParametersForm: boolean;
  activeTabIndex: POLICY_TABS_OFFSET;
  policyProvisionDropdown: DropdownProps[];
}

const initialState: PolicyDetailState = {
  isLoading: false,
  activePolicy: newPolicy,
  activePolicySequences: [],
  activePolicyContents: [],
  activePolicyProvisions: [],
  activePolicyAnswers: [],
  showParametersForm: false,
  activeTabIndex: POLICY_TABS_OFFSET.GENERAL,
  policyProvisionDropdown: [],
};

export const formatSequencesForInput = (sequences: any[]) => {
  const result = sequences.map(sequence => {
    const elementsCopy = sequence.elements.map((element: { provision: { id: any }; order: any }) => {
      return {
        provisionId: element.provision?.id,
        order: Number(element.order),
      };
    });

    return {
      id: sequence.id,
      policyId: sequence.policy?.id,
      documentTypeId: sequence.documentType?.id,
      elements: elementsCopy,
    };
  });

  return result;
};

const policyDetailSlice = createSlice({
  name: 'policyDetail',
  initialState,
  reducers: {
    updatePolicyViewDetailsTab: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },
    updateActivePolicy: (state, action: PayloadAction<any>) => {
      const { key, value } = action.payload;
      state.activePolicy = { ...state.activePolicy, [key]: value };
    },
    addPolicyAnswerOfTypeImageFiles: (state, action) => {
      const {
        files: [{ url, name, file, local }],
        index,
        answerType,
        paramRef,
        userId,
      } = action.payload;

      const newAnswer = {
        blockRef: index,
        paramRef,
        userId,
        answerType,
        dateOfAnswer: getCurrentDateTimeISO(),
        answer: {
          url,
          name,
          file,
          local,
        },
      };

      const answerIndex: number = state.activePolicyAnswers.findIndex(
        ({ blockRef, paramRef: answerParam }) =>
          blockRef === index &&
          answerParam.parameterId === paramRef.parameterId &&
          answerParam.tableId === paramRef.tableId &&
          answerParam.index === answerParam.index,
      );

      if (answerIndex === -1) {
        state.activePolicyAnswers = [...state.activePolicyAnswers, newAnswer];
      } else {
        state.activePolicyAnswers[answerIndex] = newAnswer;
      }
    },
    deletePolicyAnswerOfTypeImageFiles: (state, action) => {
      const { index, answerType, paramRef, userId } = action.payload;

      const newAnswer = {
        index,
        paramRef,
        userId,
        answerType,
        dateOfAnswer: getCurrentDateTimeISO(),
        answer: {},
      };

      const answerIndex: number = state.activePolicyAnswers.findIndex(
        ({ blockRef: blockRef, paramRef: answerParam }) =>
          blockRef === index &&
          answerParam.parameterId === paramRef.parameterId &&
          answerParam.tableId === paramRef.tableId &&
          answerParam.index === answerParam.index,
      );

      if (answerIndex === -1) {
        state.activePolicyAnswers = [...state.activePolicyAnswers, newAnswer];
      } else {
        state.activePolicyAnswers = state.activePolicyAnswers.filter((item, index) => index !== answerIndex);
      }
    },
    updatePolicyLatestAnswers: (state, action) => {
      const { paramRef } = action.payload.answer;

      // Check the temporary answers of param ref
      const temporaryParamRefAnswers = state.activePolicyAnswers.filter(
        obj =>
          obj.paramRef.parameterId === paramRef.parameterId &&
          obj.paramRef.tableId === paramRef.tableId &&
          obj.paramRef.index === paramRef.index &&
          obj.blockRef !== undefined,
      );

      const newAnswer = { ...action.payload.answer };
      newAnswer.policyId = state.activePolicy.id;

      if (paramRef.tableId) {
        action.payload.answer.blockRef = `${paramRef.parameterId}-${paramRef.index}`;
      }

      // If no temporary answers then add new answer
      if (temporaryParamRefAnswers.length === 0) {
        state.activePolicyAnswers = [...state.activePolicyAnswers, newAnswer];
      }
      // If temporary answer is already present update that answer
      else {
        const checkBlockRefCount = current(state.activePolicyAnswers).filter(
          obj =>
            obj.paramRef.parameterId === paramRef.parameterId &&
            obj.paramRef.tableId === paramRef.tableId &&
            obj.paramRef.index === paramRef.index &&
            obj.blockRef !== undefined,
        ).length;

        const index = current(state.activePolicyAnswers).findIndex(
          obj =>
            obj.paramRef.parameterId === paramRef.parameterId &&
            obj.paramRef.tableId === paramRef.tableId &&
            obj.paramRef.index === paramRef.index &&
            obj.blockRef !== undefined,
        );

        if (checkBlockRefCount === 2) {
          state.activePolicyAnswers.splice(index, 1);
        }

        state.activePolicyAnswers[index] = newAnswer;
      }
    },
    createNewPolicy: state => {
      let policyCopy = { ...newPolicy };
      policyCopy.entityId = getCurrentLandlordEntityId();
      state.activePolicy = policyCopy;
      state.activeTabIndex = POLICY_TABS_OFFSET.GENERAL;
    },
    updateShowParametersForm: (state, action) => {
      state.showParametersForm = action.payload.status;
    },
    // User modifies the order of the sequence, one element is moved from
    // `currentPos` index to `newPos` index. Once reordering of the list is
    // completed, we need to recalculate all the `order`.
    updateSequenceOrder: (state, action) => {
      const { currentPos, newPos, sequenceIndex } = action.payload;
      const { elements } = state.activePolicySequences[sequenceIndex];

      // Reorder the elements in sequence
      const element = elements[currentPos];
      elements.splice(currentPos, 1);
      elements.splice(newPos, 0, element);

      for (let i = 0; i < elements.length; i++) {
        elements[i].order = i;
      }
      state.activePolicySequences = formatSequencesForInput(state.activePolicySequences);
    },
    deleteIterationAnswers: (state, action) => {
      const { tabIndex, tableData, parentId, answerType, checkCounter } = action.payload;
      const { id, columns } = tableData;

      if (checkCounter === null) {
        state.activePolicyAnswers = updateIterationHelper(
          state.activePolicyAnswers,
          answerType,
          parentId,
          CounterOptions.Decrement,
          null,
          ExecuteContext.Policy,
        ) as AnswerProps[];
      }
      for (let i = 0; i < columns.length; i++) {
        state.activePolicyAnswers = state.activePolicyAnswers.filter((obj: AnswerProps) => {
          if (checkIfNotDefined(obj.paramRef.tableId)) {
            return true;
          } else {
            return !(
              obj.paramRef.parameterId === columns[i].parameter.id &&
              obj.paramRef.tableId === id &&
              obj.paramRef.index === tabIndex
            );
          }
        });
      }

      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < state.activePolicyAnswers.length; j++) {
          let answer = state.activePolicyAnswers[j] as any;
          if (answer.paramRef.parameterId === columns[i].parameter.id && answer.paramRef.tableId === id) {
            if (tabIndex < answer.paramRef.index) {
              answer.paramRef.index = answer.paramRef.index - 1;
            }
          }
        }
      }
    },
    updateIterationValue: (state, action) => {
      const { parentId, answerType } = action.payload;
      state.activePolicyAnswers = updateIterationHelper(
        state.activePolicyAnswers,
        answerType,
        parentId,
        CounterOptions.Increment,
        null,
        ExecuteContext.Policy,
      ) as AnswerProps[];
    },
    // if blockRef is defined, this means a new answer is defined. so we should keep only those where it is undefined.
    resetPolicyParameterGroupAnswers: state => {
      const oldAnswers = state.activePolicyAnswers.filter((answer: AnswerProps) => answer.blockRef === undefined);
      state.activePolicyAnswers = oldAnswers;
    },
  },
  extraReducers: builder => {
    //getPolicy
    builder.addCase(getPolicy.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getPolicy.fulfilled, (state, action) => {
      const data = action.payload.data.getPolicy;
      state.activePolicy = data;
      state.activePolicySequences = [];
      state.activePolicyContents = data.contents;
      state.activePolicyAnswers = data.answers;
      state.isLoading = false;
    });
    builder.addCase(getPolicy.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('getPolicy API request failed');
    });

    //Create Policy
    builder.addCase(createPolicy.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createPolicy.fulfilled, state => {
      state.isLoading = false;
      toast.success(`Policy created Successfully`);
    });
    builder.addCase(createPolicy.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(`Some error occurred when trying to add Policy`);
    });

    //Update Policy
    builder.addCase(updatePolicy.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(updatePolicy.fulfilled, (state, action) => {
      const { answers } = action.payload.data.updatePolicy;
      state.activePolicyAnswers = answers;
      state.isLoading = false;
      toast.success('Policy updated Successfully');
    });
    builder.addCase(updatePolicy.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to update Policy');
    });

    //Update Policy content
    builder.addCase(updatePolicyContent.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(updatePolicyContent.fulfilled, (state, action) => {
      const { contents } = action.payload.data.updatePolicyContent;
      state.activePolicyContents = contents;
      state.isLoading = false;
      toast.success('Policy content updated Successfully');
    });
    builder.addCase(updatePolicyContent.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to update Policy Content');
    });

    //Update Policy Answers
    builder.addCase(createPolicyAnswers.fulfilled, (state, action) => {
      if (action.payload) {
        const newAnswers = action.payload.data.createPolicyAnswers;
        const oldAnswers = state.activePolicyAnswers.filter((answer: AnswerProps) => answer.blockRef === undefined);
        state.activePolicyAnswers = [...oldAnswers, ...newAnswers];
        state.isLoading = false;
        toast.success('Policy answers updated successfully');
      }
    });
    builder.addCase(createPolicyAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to update Policy Answers');
    });

    // deletePolicyAllAnswers: remove all answers of the policy are cleared
    builder.addCase(deletePolicyAllAnswers.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(deletePolicyAllAnswers.fulfilled, state => {
      state.activePolicyAnswers = [];
      state.isLoading = false;
      toast.success('Policy answers deleted successfully');
    });
    builder.addCase(deletePolicyAllAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to delete the Policy Answers');
    });

    // deletePolicyParamRefAnswers: remove all the answers of a specific parameter (in a table or not) are cleared
    builder.addCase(deletePolicyParamRefAnswers.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(deletePolicyParamRefAnswers.fulfilled, (state, action) => {
      const { parameterId, tableId, index } = action.meta.arg;

      state.activePolicyAnswers = state.activePolicyAnswers.filter(
        (answer: AnswerProps) =>
          answer.paramRef.parameterId !== parameterId ||
          answer.paramRef.tableId !== tableId ||
          answer.paramRef.index !== index,
      );

      state.isLoading = false;
      toast.success('Policy parameter reference answers deleted successfully');
    });
    builder.addCase(deletePolicyParamRefAnswers.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to delete the Policy ParamRef Answers');
    });

    //prepPolicyAnswerOfImageTypeForUpload
    builder.addCase(prepPolicyAnswerOfImageTypeForUpload.fulfilled, (state, action) => {
      const { index, paramRef } = action.meta.arg;
      const { cdnUrl, uploadUrl } = action.payload.data.prepPolicyImageAnswer;

      const answerIndex = state.activePolicyAnswers.findIndex(
        ({ blockRef: answerIndex, paramRef: answerParam }) =>
          answerIndex === index &&
          answerParam.parameterId === paramRef.parameterId &&
          answerParam.tableId === paramRef.tableId &&
          answerParam.index === answerParam.index,
      );

      const newAnswer = {
        ...state.activePolicyAnswers[answerIndex].answer,
        cdnUrl,
        uploadUrl,
      };

      state.activePolicyAnswers[answerIndex].answer = newAnswer;
    });

    builder.addCase(prepPolicyAnswerOfImageTypeForUpload.rejected, (_state, action) => {
      console.warn(action.error);
      toast.error('prepPolicyAnswerOfImageTypeForUpload API request failed');
    });

    // Create Policy Provision
    builder.addCase(createPolicyProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createPolicyProvision.fulfilled, (state, action) => {
      state.activePolicyProvisions = [...state.activePolicyProvisions, action.payload.data.createPolicyProvision];
      state.isLoading = false;
      toast.success('Policy provision added Successfully');
    });
    builder.addCase(createPolicyProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(`Some error occurred when trying to add policy provision`);
    });

    // Update Policy Provision
    builder.addCase(updatePolicyProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updatePolicyProvision.fulfilled, (state, action) => {
      const provisionId = action.meta.arg.id;
      const index: number = state.activePolicyProvisions.findIndex(
        (provision: PolicyProvisionDetailClone) => provision.id === provisionId,
      );
      state.activePolicyProvisions[index] = action.payload.data.updatePolicyProvision;
      state.isLoading = false;
      toast.success('Policy provision updated Successfully');
    });
    builder.addCase(updatePolicyProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(`Some error occurred when trying to update policy provision`);
    });

    // Delete Policy Provision
    builder.addCase(deletePolicyProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deletePolicyProvision.fulfilled, (state, action) => {
      state.activePolicyProvisions = state.activePolicyProvisions.filter(
        (element: PolicyProvisionDetailClone) => element.id !== action.meta.arg.id,
      );
      state.isLoading = false;
      toast.success('Policy provision deleted successfully');
    });
    builder.addCase(deletePolicyProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('deletePolicyProvision API request failed');
    });

    // Get policy provisions
    builder.addCase(listPolicyProvisions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listPolicyProvisions.fulfilled, (state, action) => {
      state.activePolicyProvisions = action.payload.data.listPolicyProvisions;
      state.policyProvisionDropdown = provisionsDropdown(state.activePolicyProvisions);
      state.isLoading = false;
    });
    builder.addCase(listPolicyProvisions.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to loading the Provisions');
    });

    // Get policy provisions
    builder.addCase(listPolicySequences.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listPolicySequences.fulfilled, (state, action) => {
      state.activePolicySequences = action.payload.data.listPolicySequences;
      state.isLoading = false;
    });
    builder.addCase(listPolicySequences.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to loading the sequences');
    });

    // Update Policy Sequences
    builder.addCase(updatePolicySequences.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updatePolicySequences.fulfilled, (state, action) => {
      state.activePolicySequences = action.payload.data.updatePolicySequences;
      state.isLoading = false;
      toast.success('Policy sequences updated Successfully');
    });
    builder.addCase(updatePolicySequences.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(`Some error occurred when trying to update policy sequences`);
    });

    //setCrossRefInPolicy
    builder.addCase(setCrossRefInPolicy.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(setCrossRefInPolicy.fulfilled, (state, action) => {
      const data = action.payload.data.setCrossRefInPolicy;
      state.activePolicyContents = data;
      state.isLoading = false;
    });
    builder.addCase(setCrossRefInPolicy.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('setCrossRefInPolicy API request failed');
    });
  },
});

export const {
  updateActivePolicy,
  createNewPolicy,
  updateShowParametersForm,
  updateSequenceOrder,
  updatePolicyLatestAnswers,
  deleteIterationAnswers,
  updatePolicyViewDetailsTab,
  updateIterationValue,
  addPolicyAnswerOfTypeImageFiles,
  deletePolicyAnswerOfTypeImageFiles,
  resetPolicyParameterGroupAnswers,
} = policyDetailSlice.actions;

export default policyDetailSlice.reducer;
