import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import Role from 'common/model/Role';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { NewRoleInput } from './createRole';

interface UpdateRoleQueryVariables {
  currentLandlordEntityId: string;
  roleId: string;
  params: NewRoleInput;
}

export const UPDATE_ROLE = (role: Role) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Update Role: currentLandlordEntityId is required');

  const { id: roleId, name } = role;

  const variables: UpdateRoleQueryVariables = {
    currentLandlordEntityId,
    roleId,
    params: { name },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $roleId: ID!, $params: RoleInput!) {
        updateRole(currentLandlordEntityId: $currentLandlordEntityId, roleId: $roleId, params: $params) {
          id
          name
          approvalLevel
          members {
            id
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
        }
      }
    `,
    variables,
  };
};

export const updateRole = createAsyncThunk('roles/updateRole', async (arg, { getState }) => {
  const {
    roles: { activeRole },
  } = getState() as RootState;

  const response = await client.mutate(UPDATE_ROLE(activeRole as Role));

  return response;
});
