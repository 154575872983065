import moment from 'moment';
import DropdownSelect from 'atoms/DropdownSelect';
import DateField from 'atoms/FormField/DateInput';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowConfig/RowProps';
import { filterWhile } from './StringFilter';

const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Date is on or after', value: FilterMode.GREATER_THAN_OR_EQUALS },
  { key: 2, text: 'Date is on or before', value: FilterMode.LESS_THAN_OR_EQUALS },
  { key: 3, text: 'Date is', value: FilterMode.EQUALS },
];

const compare = (value: Date, filterValue: Date, filterMode?: FilterMode) => {
  switch (filterMode) {
    case FilterMode.EQUALS:
      return moment(value).isSame(filterValue, 'day');
    case FilterMode.GREATER_THAN_OR_EQUALS:
      return moment(value).isSameOrAfter(filterValue, 'day');
    case FilterMode.LESS_THAN_OR_EQUALS:
      return moment(value).isSameOrBefore(filterValue, 'day');
    default:
      return true;
  }
};

export function applyDateFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyDateFilter: field key is missing');

  let value: Date = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  return filterWhile(value, rowAction, compare);
}

const DateFilter = ({
  filterItem,

  onChange,
}: FilterItemChildProps): JSX.Element => {
  return (
    <>
      <DropdownSelect
        placeholder="Select..."
        fieldKey={FilterOnchangeKeys.filterMode}
        value={filterItem.filterMode}
        options={FILTER_OPTIONS}
        onChange={onChange}
      />

      <DateField
        fieldKey={FilterOnchangeKeys.filterValue}
        value={filterItem.filterValue}
        onChange={onChange}
        placeholder="Select date"
      />
    </>
  );
};

export default DateFilter;
