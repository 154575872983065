interface IOrganisationListingClone {
  id: string;
  index: number;
  name: string;
  operatingStatus: string;
  logo: string;
  orgKey: string;
}

export class OrganisationListingClone implements IOrganisationListingClone {
  id: string;
  name: string;
  operatingStatus: string;
  index: number;
  logo: string;
  orgKey: string;

  constructor(input: IOrganisationListingClone) {
    this.id = input.id;
    this.name = input.name;
    this.operatingStatus = input.operatingStatus;
    this.index = input.index;
    this.logo = input.logo;
    this.orgKey = input.orgKey;
  }

  static fragments(): string {
    return `
      id
      name
      orgKey
      index
      logo
      operating_status
    `;
  }
}
