import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ApprovalLevel from 'common/model/ApprovalLevel';
import PageInfo from 'common/model/PageInfo';
import Role from 'common/model/Role';
import RoleConnection from 'common/model/RoleConnection';
import { createRole, NewRoleInput } from 'common/api/roles';
import { deleteRole, listRoles, listRolesOptions, updateRole } from 'common/api/roles';
import { RoleInput } from 'common/api/roles/types';
import { DROPDOWN_OPTION } from 'utils/UI';

interface RolesSliceState {
  rolesList: Role[];
  activeRole: NewRoleInput;
  pageInfo: PageInfo | null;
  search: null;
  isLoading: boolean;
  optionsLoading: boolean;
  roleOptions: DROPDOWN_OPTION[];
  isSearching: boolean;
}

const initNewRole: NewRoleInput = {
  name: ''
};

const initialState: RolesSliceState = {
  rolesList: [],
  roleOptions: [],
  activeRole: initNewRole,
  pageInfo: null,
  search: null,
  isLoading: false,
  optionsLoading: false,
  isSearching: false,
};

const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    updateInput: (state, action) => {
      const { key, value } = action.payload;
      state.activeRole[key as keyof NewRoleInput] = value;
    },
    discardRoleCreation: state => {
      state.activeRole = initNewRole;
    },
    onEditDetails: (state, action) => {
      const { id } = action.payload;

      const role = state.rolesList.find(element => element.id === id);

      if (role) state.activeRole = role;
    },
    clearRoleOptions: state => {
      state.roleOptions = [];
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    //createRole
    builder.addCase(createRole.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createRole.fulfilled, (state, action) => {
      const createRole = action.payload.data.createRole;
      state.activeRole = initNewRole;
      state.rolesList = [...state.rolesList, createRole];
      toast.success('Role Created Successfully');
      state.isLoading = false;
    });
    builder.addCase(createRole.rejected, (state, action) => {
      console.error(action.error);
      toast.error('createRole API request rejected');
      state.isLoading = false;
    });

    //listRoles
    builder.addCase(listRoles.pending, (state, action) => {
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listRoles.fulfilled, (state, action) => {
      const { edges = [], pageInfo }: RoleConnection = action.payload?.data?.listRoles ?? {};
      state.rolesList = edges.map(element => element.node);
      state.pageInfo = pageInfo;
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listRoles.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('listRoles API request rejected');
    });

    //createRole
    builder.addCase(updateRole.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      const role = action.payload.data.updateRole;
      const index = state.rolesList.findIndex(({ id }) => role.id === id);
      state.rolesList.splice(index, 1, role);
      state.activeRole = initNewRole;
      toast.success('Role Update Successfully');
      state.isLoading = false;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      console.error(action.error);
      toast.error('updateRole API request rejected');
      state.isLoading = false;
    });

    //listRolesOptions
    builder.addCase(listRolesOptions.pending, state => {
      state.optionsLoading = true;
    });
    builder.addCase(listRolesOptions.fulfilled, (state, action) => {
      const { edges }: RoleConnection = action.payload.data.listRoles;

      state.roleOptions = edges.map((element, index) => ({
        key: index,
        text: element.node.name,
        value: element.node.id,
      }));

      state.optionsLoading = false;
    });
    builder.addCase(listRolesOptions.rejected, (state, action) => {
      state.optionsLoading = false;
      console.error(action.error);
      toast.error('listRolesOptioons API request rejected');
    });

    //deleteRole
    builder.addCase(deleteRole.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.rolesList = state.rolesList.filter(role => role.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Role deleted successfully');
    });
    builder.addCase(deleteRole.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('deleteRole API request rejected');
    });
  },
});

export const { updateInput, discardRoleCreation, onEditDetails, clearRoleOptions, updateSearch } = roles.actions;

export default roles.reducer;
