import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { conditionalClassName } from 'utils/tsHelper';
import { addSpaceOrComma } from 'utils/utils-number';
import { fillString } from './rentReviewDetailsBody';

export const rentAgreementDetailsBody = (item: CValuationReport): JSX.Element[] => {
  const {
    data: { rentReview },
  } = item;

  return rentReview.map((review, index) => {
    const firstItem = index === 0;
    const secondItem = index === 1;

    const rentAgreementDate = review.rentAgreementDate ? moment(review.rentAgreementDate).format('DD/MM/YYYY') : null;
    const rentDeterminationDate = review.rentDeterminationDate
      ? moment(review.rentDeterminationDate).format('DD/MM/YYYY')
      : null;
    const vacancyRate = review.vacancyRate ? review.vacancyRate.toString() : null;
    const faceRentPsfPcm = review.faceRentPsfPcm ? review.faceRentPsfPcm.toString() : null;
    const effectiveRentPsfPcm = review.effectiveRentPsfPcm ? review.effectiveRentPsfPcm.toString() : null;

    return firstItem ? (
      <React.Fragment key={`rent-agreement-${index}`}></React.Fragment>
    ) : (
      <Popup
        hoverable={true}
        position="top center"
        key={`rent-agreement-${index}`}
        trigger={
          <div
            className={`d-flex flex-gap-s align-center ${conditionalClassName(!secondItem, 'm-t-s p-t-s border-top')}`}
          >
            <span className="width-auto">{index}</span>

            <div className="d-flex align-center">
              {`
                  ${fillString(rentAgreementDate, _ => `${rentAgreementDate}`)} 
                  ${fillString(rentDeterminationDate, _ => `${rentAgreementDate ? ', ' : ''}${rentDeterminationDate}`)}
                  ${fillString(vacancyRate, vacancyRate => `${rentAgreementDate || rentDeterminationDate ? ', ' : ''}${vacancyRate}%`)} 
                  ${fillString(faceRentPsfPcm, faceRentPsfPcm => `${rentAgreementDate || rentDeterminationDate || vacancyRate ? ',' : ''} ${addSpaceOrComma(faceRentPsfPcm, false)} (HK$/sf/mo)`)} 
                  ${fillString(effectiveRentPsfPcm, effectiveRentPsfPcm => `${rentAgreementDate || rentDeterminationDate || vacancyRate || faceRentPsfPcm ? ',' : ''} ${addSpaceOrComma(effectiveRentPsfPcm, false)} (HK$/sf/mo)`)} 
                  `}
            </div>
          </div>
        }
        content={
          <div className="d-flex-column flex-gap-s">
            {rentAgreementDate ? (
              <div>
                Rent agreement date: <strong>{rentAgreementDate}</strong>
              </div>
            ) : (
              <></>
            )}

            {rentDeterminationDate ? (
              <div>
                Rent determination date: <strong>{rentDeterminationDate}</strong>
              </div>
            ) : (
              <></>
            )}

            {vacancyRate ? (
              <div>
                Vacancy rate: <strong>{vacancyRate}%</strong>
              </div>
            ) : (
              <></>
            )}

            {faceRentPsfPcm ? (
              <div>
                Face Rent (psf pcm): <strong>{addSpaceOrComma(faceRentPsfPcm, false)} (HK$/sf/mo) </strong>
              </div>
            ) : (
              <></>
            )}

            {effectiveRentPsfPcm ? (
              <div>
                Face Rent (psf pcm):
                <strong>{addSpaceOrComma(effectiveRentPsfPcm, false)} (HK$/sf/mo) </strong>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />
    );
  });
};
