import { useState } from 'react';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';

const OrganisationCreator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const onSaveOrganisation = () => {
    console.log('onSaveOrganisation');
  };

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Organisations' }];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };
  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <div>OrganisationCreator: - add form here</div>
    </PageLayout>
  );
};

export default OrganisationCreator;
