import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { isArray } from 'lodash';
import { RENT_REVIEW_METHOD_OPTIONS } from 'utils/UI';
import { conditionalClassName } from 'utils/tsHelper';
import { getDateOfAddedDays } from 'utils/utils-date';
import { addSpaceOrComma } from 'utils/utils-number';

export const rentReviewDetailsBody = (item: CValuationReport): JSX.Element[] => {
  let rentReview = [] as any;
  if (isArray(item)) {
    rentReview = item;
  } else {
    rentReview = item.data.rentReview;
  }

  return rentReview.map((review: any, index: number) => {
    const firstItem = index === 0;
    const secondItem = index === 1;

    const reviewDate = review.reviewDate ? moment(review.reviewDate).format('DD/MM/YYYY') : '';
    const startDate = moment(getDateOfAddedDays(review.reviewDate, 1)).format('DD/MM/YYYY');

    const unformattedEndDate =
      index + 1 < rentReview.length ? rentReview[index + 1].reviewDate : item.data?.term?.endDate;
    const endDate = moment(unformattedEndDate).format('DD/MM/YYYY');

    const reviewType = review.type ? review.type.toUpperCase() : review.type;
    const method = RENT_REVIEW_METHOD_OPTIONS.find(option => option.value === reviewType)?.text;

    return firstItem ? (
      <React.Fragment key={`review-details-${index}`}></React.Fragment>
    ) : (
      <Popup
        hoverable={true}
        position="top center"
        key={`review-details-${index}`}
        trigger={
          <div
            className={`d-flex flex-gap-s align-center ${conditionalClassName(!secondItem, 'm-t-s p-t-s border-top')}`}
          >
            <span className="width-auto">{index}</span>

            <div className="d-flex align-center">
              {reviewDate ? reviewDate + ', ' : ''}
              {method}
              {`
                    ${fillString(review.cap, cap => `, ${cap} (%) cap`)}
                    ${fillString(review.collar, collar => `, ${collar} (%) collar`)} 
                    ${fillString(review.increment, increment => `, ${increment}(%) increment`)} 
                    ${fillString(review.faceRentPsfPcm, faceRentPsfPcm => `, ${addSpaceOrComma(faceRentPsfPcm, false)} (HK$/sf/mo)`)}
                  `}
            </div>
          </div>
        }
        content={
          <div className="d-flex-column flex-gap-s">
            {reviewDate ? (
              <div>
                Review date: <strong>{reviewDate}</strong>
              </div>
            ) : (
              ''
            )}
            <div>
              Start Date: <strong>{startDate}</strong>
            </div>
            <div>
              End Date: <strong>{endDate}</strong>
            </div>
            <div>
              Review Method: <strong>{method}</strong>
            </div>

            {review.cap ? (
              <div>
                Cap: <strong>{review.cap.toString()}%</strong>
              </div>
            ) : (
              <></>
            )}

            {review.collar ? (
              <div>
                Collar: <strong>{review.collar.toString()}%</strong>
              </div>
            ) : (
              <></>
            )}

            {review.increment ? (
              <div>
                Increment: <strong>{review.increment.toString()}%</strong>
              </div>
            ) : (
              <></>
            )}

            {review.faceRentPsfPcm ? (
              <div>
                Face Rent (psf pcm):{' '}
                <strong>{addSpaceOrComma(review.faceRentPsfPcm.toString(), false)} (HK$/sf/mo) </strong>
              </div>
            ) : (
              <></>
            )}
          </div>
        }
      />
    );
  });
};

export const fillString = (value: any, stringFormat: (value: any) => string) => {
  return value === null ? '' : stringFormat(value);
};
