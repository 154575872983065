import { ColumnBodyOptions } from 'primereact/column';
import { InputFieldType } from 'atoms/FormField/Input';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { SPACE_TYPE_OPTIONS } from 'utils/UI';
import { GRANT_TYPE_OPTIONS, LICENSE_TYPE_OPTIONS, dropDownElement, inputElement } from './configBody';

export const columnConfig = (onChange: Function, mode?: MODE_OPTIONS): CustomColumnProps[] => {
  const disabled: boolean = mode === MODE_OPTIONS.READ;

  const config: CustomColumnProps[] = [
    {
      field: 'floor',
      header: 'Floor',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.TEXT),
    },
    {
      field: 'unit',
      header: 'Unit/Space',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.TEXT),
    },
    {
      field: 'type',
      header: 'Use',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        dropDownElement(row, onChange, field, rowIndex, SPACE_TYPE_OPTIONS),
    },
    {
      field: 'grossArea',
      header: 'Gross area',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'lettableArea',
      header: 'Lettable area',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'netArea',
      header: 'Net area',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'saleableArea',
      header: 'Saleable area',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'efficiencyRatio',
      header: 'Efficency',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'managementCharge',
      header: 'Standard Mgt. Charges (pcm)',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'governmentRate',
      header: 'Govt. Rate (pcm)',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'governmentRent',
      header: 'Govt. Rent (pcm)',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'acFee',
      header: 'Standard Monthly AC Fees (pcm)',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'occupancyRateArea',
      header: 'Area included in Occupancy Rate (sf)',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'rentGeneratingArea',
      header: 'Rent Generating Area (sf)',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.NUMBER),
    },
    {
      field: 'license',
      header: 'Premises, Dedicated Area or Non-Exclusive License?',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        dropDownElement(row, onChange, field, rowIndex, LICENSE_TYPE_OPTIONS),
    },
    {
      field: 'grant',
      header: 'Granted via Lease or License?',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        dropDownElement(row, onChange, field, rowIndex, GRANT_TYPE_OPTIONS),
    },
    {
      field: 'comment',
      header: 'Comments',
      filter: false,
      sortable: false,
      body: (row: SpaceSelectorRow, { field, rowIndex }: ColumnBodyOptions) =>
        inputElement(row, onChange, field, rowIndex, InputFieldType.TEXT),
    },
  ];

  return config;
};

export class SpaceSelectorRow {
  floor: string;
  unit: string;
  type: string;
  grossArea: number;
  netArea: number;
  lettableArea: number;
  saleableArea: number;
  efficiencyRatio: number;
  managementCharge: number;
  governmentRent: number;
  governmentRate: number;
  acFee: number;
  occupancyRateArea: number;
  rentGeneratingArea: number;
  license: string;
  grant: string;
  comment: string;

  constructor(space: SpaceSelectorRow) {
    this.floor = space.floor;
    this.unit = space.unit;
    this.type = space.type;
    this.grossArea = space.grossArea;
    this.netArea = space.netArea;
    this.lettableArea = space.lettableArea;
    this.saleableArea = space.saleableArea;
    this.efficiencyRatio = space.efficiencyRatio;
    this.managementCharge = space.managementCharge;
    this.governmentRate = space.governmentRate;
    this.governmentRent = space.governmentRent;
    this.acFee = space.acFee;
    this.occupancyRateArea = space.occupancyRateArea;
    this.rentGeneratingArea = space.rentGeneratingArea;
    this.license = space.license;
    this.grant = space.grant;
    this.comment = space.comment;
  }
}
