import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeletePermissionQueryVariables {
  permissionId: string;
}

export const DELETE_PERMISSION = (id: string) => {
  if (!id) throw new Error('DELETE_PERMISSION: id is required');

  const variables: DeletePermissionQueryVariables = { permissionId: id };

  return {
    mutation: gql`
      mutation ($permissionId: ID!) {
        deletePermission(id: $permissionId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deletePermission = createAsyncThunk('permissions/deletePermission', async ({ id }: { id: string }) => {
  const response = await adminClient.mutate(DELETE_PERMISSION(id));
  return response;
});
