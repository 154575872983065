import { useEffect } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import ModalTemplate from 'components/ModalTemplate';
import {
  resetTransactionChannel,
  updateChannel,
  updateDiscussionModalStatus,
} from 'store/transactions/transactionDetailSlice';
import { DiscussionMode, setDiscussionMode } from 'store/transactions/transactionDetailSlice';
import { getNode } from 'common/api/nodes';
import { createTransactionChannel } from 'common/api/transactions';
import { listDiscussionGroups } from 'common/api/transactions/listDiscussionGroups';
import './CreateDiscussionModal.scss';

interface CreateDiscussionModalProps {}

const CreateDiscussionModal = ({}: CreateDiscussionModalProps) => {
  const { createDiscussionModalStatus, activeTransactionChannel } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );

  const dispatch = useAppDispatch();

  const createDiscussion = () => {
    dispatch(updateDiscussionModalStatus(false));
    dispatch(createTransactionChannel()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(listDiscussionGroups({}));
      }
    });
    dispatch(setDiscussionMode(DiscussionMode.View));
  };

  const onChange = (key: string, value: string) => {
    dispatch(updateChannel({ key, value }));
  };

  useEffect(() => {
    if (activeTransactionChannel.nodeId) {
      dispatch(getNode(activeTransactionChannel.nodeId)).then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          onChange('title', response.payload.getNode.name);
        }
      });
    }
  }, [activeTransactionChannel.nodeId]);

  useEffect(() => {
    return () => {
      dispatch(resetTransactionChannel());
    };
  }, []);

  return (
    <ModalTemplate
      title="Create Discussion"
      showHeader={true}
      isOpen={createDiscussionModalStatus}
      onToggleModalStatus={isOpen => dispatch(updateDiscussionModalStatus(isOpen))}
      className="discussion-modal"
    >
      <Grid>
        <Grid.Row className="p-none btn-row">
          <Grid.Column
            width={16}
            className="p-none"
          >
            <InputField
              label="What is the topic of discussion?"
              value={activeTransactionChannel.title}
              fieldKey="title"
              dataTest="text-block-name"
              onChange={onChange}
              placeholder="Topic title..."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none btn-row">
          <Button
            className="cancel-btn"
            data-test="cancel-button"
            onClick={() => dispatch(updateDiscussionModalStatus(false))}
          >
            CANCEL
          </Button>
          <Button
            className="btn grey-bg"
            data-test="save-button"
            disabled={false}
            onClick={() => createDiscussion()}
          >
            SAVE
          </Button>
        </Grid.Row>
      </Grid>
    </ModalTemplate>
  );
};

export default CreateDiscussionModal;
