export interface Permission {
  id?: string;
  action: string | null;
  resource: string | null;
  insertedAt: Date;
  updatedAt: Date;
}

export class PermissionRow {
  id?: string;
  action: string | null;
  resource: string | null;
  insertedAt: Date;
  updatedAt: Date;

  constructor(permission: Permission) {
    this.id = permission.id;
    this.action = permission.action;
    this.resource = permission.resource;
    this.insertedAt = permission.insertedAt;
    this.updatedAt = permission.updatedAt;
  }
}
