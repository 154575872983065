import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { isArray } from 'lodash';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import { RENT_REVIEW_METHOD_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';
import { conditionalClassName } from 'utils/tsHelper';
import { addSpaceOrComma } from 'utils/utils-number';

export const renewalRightsBody = (item: CValuationReport): JSX.Element => {
  let renewFlag, renewalRights;
  if (isArray(item)) {
    if (item.length !== 0) {
      renewFlag = true;
    }
    renewalRights = item;
  } else {
    renewalRights = item.data.specialRights.renewalRights;
    renewFlag = item.data.specialRights.renewFlag;
  }

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(renewFlag as boolean)}</div>}
      content={renewalRights.map((right, index) => {
        const wholeOrPart = right.wholeOrPart ? right.wholeOrPart.toUpperCase() : right.wholeOrPart;
        const rightType = right.type ? right.type.toUpperCase() : right.type;

        const exercisability = WHOLE_OR_PART_OPTIONS.find(option => option.value === wholeOrPart)?.text;
        const method = RENT_REVIEW_METHOD_OPTIONS.find(option => option.value === rightType)?.text;

        const cap = right.cap ? right.cap.toString() : null;
        const collar = right.collar ? right.collar.toString() : null;
        const increment = right.increment ? right.increment.toString() : null;
        const rent = right.rent ? addSpaceOrComma(right.rent.toString(), false) : null;

        return (
          <div
            className={`d-flex ${conditionalClassName(index !== 0, 'm-t-sm')}`}
            style={{ minWidth: '30rem' }}
          >
            <div
              className="m-r-xs"
              style={{ whiteSpace: 'nowrap' }}
            >
              Option {index + 1}:
            </div>

            <div className="d-flex-column flex-gap-xxs">
              <span>
                Duration: <strong>{moment(right.startDate).format('DD/MM/YYYY')}</strong> to{' '}
                <strong>{moment(right.endDate).format('DD/MM/YYYY')}</strong>
              </span>

              <span>
                Term length: <strong>{right.renewalTermLength} months</strong>
              </span>

              {exercisability ? (
                <span>
                  Exercisable in: <strong>{exercisability}</strong>
                </span>
              ) : (
                <></>
              )}

              <span style={{ whiteSpace: 'nowrap' }}>
                Rent determination method: <strong>{method}</strong>
              </span>

              {cap ? (
                <span>
                  Cap: <strong>{cap}%</strong>
                </span>
              ) : (
                <></>
              )}

              {collar ? (
                <span>
                  Collar: <strong>{collar}%</strong>
                </span>
              ) : (
                <></>
              )}

              {increment ? (
                <span>
                  Increment: <strong>{increment}%</strong>
                </span>
              ) : (
                <></>
              )}

              {rent ? (
                <span>
                  Fixed rent (psf pcm): <strong>{rent} (HK$/sf/mo)</strong>
                </span>
              ) : (
                <></>
              )}

              {right.noticePeriodMonths ? (
                <span>
                  Notice period:{' '}
                  <strong>
                    {right.noticePeriodMonths} month{right.noticePeriodMonths > 1 ? 's' : ''}
                  </strong>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
      disabled={!renewFlag}
    />
  );
};
