import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateInput } from 'store/roles/rolesSlice';
import { TEAM_ROLE_APPROVAL_LEVEL_OPTIONS } from 'utils/UI';

const RoleModalForm = () => {
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state: RootState) => state.roles.activeRole);

  const onChange = (key: string, value: string) => dispatch(updateInput({ key, value }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Role"
            fieldKey="name"
            placeholder="Type role.."
            value={name}
            onChange={onChange}
            required={true}
            info={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RoleModalForm;
