import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import Permission from 'common/model/Permission';
import PermissionInput from 'common/model/PermissionInput';

export const UPDATE_PERMISSION = (params: Permission) => {
  const variables: {
    id: string;
    params: PermissionInput;
  } = {
    id: params.id,
    params: {
      resource: params.resource,
      action: params.action,
    },
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: PermissionInput!) {
        updatePermission(id: $id, params: $params) {
          id
          resource
          action
          insertedAt
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const updatePermission = createAsyncThunk('auth/updatePermission', async (args: { params: Permission }) => {
  const response = await adminClient.mutate(UPDATE_PERMISSION(args.params));
  return response;
});
