import { useState } from 'react';
import { Accordion, Form, Icon } from 'semantic-ui-react';
import { ModeProps } from 'common/api/miscellaneous';
import PartiesForm from './PartiesForm';
import PolicyDocuments from './PolicyDocuments';
import Premises from './Premises';
import PremisesCharges from './PremisesCharges';
import SpecificCircumstances from './SpecificCircumstances';

const TransactionSetupContent = ({ mode }: ModeProps) => {
  // Status of Accordians inside the Tab
  const [active1, setActive1] = useState<boolean>(true);
  const [active2, setActive2] = useState<boolean>(true);
  const [active3, setActive3] = useState<boolean>(true);
  const [active4, setActive4] = useState<boolean>(true);

  const handleHideOrShowTab = (e: React.MouseEvent<HTMLElement>, titleProps: any): void => {
    const { index } = titleProps;
    if (index === 1) {
      setActive1(!active1);
    } else if (index === 2) {
      setActive2(!active2);
    } else if (index === 3) {
      setActive3(!active3);
    } else if (index === 4) {
      setActive4(!active4);
    }
  };

  return (
    <Form>
      <Accordion className="setup-accordian m-none m-b-xs border-sm-grayish-magenta-light">
        <Accordion.Title
          active={active1}
          index={1}
          onClick={handleHideOrShowTab}
          className="pm-none setup-title-accordion"
        >
          <h4 className="setup-title color-blue-very-dark">1. Premises</h4>
          <Icon
            name="dropdown"
            className="setup-icon"
          />
        </Accordion.Title>
        <Accordion.Content
          active={active1}
          className="accordion-content m-t-none p-t-none"
        >
          <Premises mode={mode} />
        </Accordion.Content>
      </Accordion>
      <Accordion className="setup-accordian m-none m-b-xs border-sm-grayish-magenta-light m-t-l">
        <Accordion.Title
          active={active2}
          index={2}
          onClick={handleHideOrShowTab}
          className="pm-none setup-title-accordion"
        >
          <h4 className="setup-title color-blue-very-dark">2. Parties</h4>
          <Icon
            name="dropdown"
            className="setup-icon"
          />
        </Accordion.Title>
        <Accordion.Content
          active={active2}
          className="accordion-content m-t-none p-t-none"
        >
          <PartiesForm mode={mode} />
        </Accordion.Content>
      </Accordion>
      <Accordion className="setup-accordian m-none m-b-xs border-sm-grayish-magenta-light m-t-l">
        <Accordion.Title
          active={active3}
          index={3}
          onClick={handleHideOrShowTab}
          className="pm-none setup-title-accordion"
        >
          <h4 className="setup-title color-blue-very-dark">3. Special remarks</h4>
          <Icon
            name="dropdown"
            className="setup-icon"
          />
        </Accordion.Title>
        <Accordion.Content
          active={active3}
          className="accordion-content m-t-none p-t-none"
        >
          <SpecificCircumstances mode={mode} />
        </Accordion.Content>
      </Accordion>
      <Accordion className="setup-accordian m-none m-b-xs border-sm-grayish-magenta-light m-t-l">
        <Accordion.Title
          active={active4}
          index={4}
          onClick={handleHideOrShowTab}
          className="pm-none setup-title-accordion"
        >
          <h4 className="setup-title color-blue-very-dark">4. Policy and documents</h4>
          <Icon
            name="dropdown"
            className="setup-icon"
          />
        </Accordion.Title>
        <Accordion.Content
          active={active4}
          className="accordion-content m-t-none p-t-none"
        >
          <PolicyDocuments mode={mode} />
        </Accordion.Content>
      </Accordion>
      <Accordion className="setup-accordian m-none m-b-xs border-sm-grayish-magenta-light m-t-l">
        <Accordion.Title
          active={active4}
          index={4}
          onClick={handleHideOrShowTab}
          className="pm-none setup-title-accordion"
        >
          <h4 className="setup-title color-blue-very-dark">
            5. Measurements, building charges, analysis configuration
          </h4>
          <Icon
            name="dropdown"
            className="setup-icon"
          />
        </Accordion.Title>
        <Accordion.Content
          active={active4}
          className="accordion-content m-t-none p-t-m"
        >
          <PremisesCharges mode={mode} />
        </Accordion.Content>
      </Accordion>
    </Form>
  );
};

export default TransactionSetupContent;
