import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';

interface GetProvisionPDFDocumentQueryVariables {
  provisionId: string;
  documentTypeId: string | null;
  final: boolean;
}

export const GET_PROVISION_PDF = (provisionId: string, documentTypeId: string | null) => {
  if (!provisionId) throw new Error('Delete Provision Category: provisionId is required');

  if (!documentTypeId) throw new Error('Delete Provision Category: id is required');

  const variables: GetProvisionPDFDocumentQueryVariables = {
    provisionId,
    documentTypeId,
    final: false,
  };

  return {
    query: gql`
      query ($provisionId: ID!, $documentTypeId: ID!, $final: Boolean!) {
        getProvisionPDFDocument(provisionId: $provisionId, documentTypeId: $documentTypeId, final: $final)
      }
    `,
    variables,
  };
};

export const getProvisionPDFDocument = createAsyncThunk(
  'provisions/getProvisionPDFDocument',
  async (
    {
      provisionId,
      documentTypeId,
    }: {
      provisionId: string;
      documentTypeId: string | undefined;
    },
    { getState },
  ) => {
    const {
      miscellaneous: { activeDocType },
    } = getState() as RootState;
    const docId = documentTypeId ? documentTypeId : activeDocType?.id || null;
    const response = await adminClient.query(GET_PROVISION_PDF(provisionId, docId));
    return response;
  },
);
