import { CValuationReport } from 'common/_classes/valuationReport2';
import { Popup } from 'semantic-ui-react';
import { FilterDataType } from 'components/ComparablesTable';
import { RowProps } from '../RowConfig/RowProps';
import FilterForm from './FilterForm';
import './FilterPopup.scss';

export interface FilterPopupProps {
  filterData: FilterDataType;
  setFilterData: (filterData: FilterDataType) => void;
  onFilter: (filterData: FilterDataType) => void;
  trigger: JSX.Element;
  onFilterOpen: () => void;
}

export const initializeDefaultFIlters = (rowConfigs: RowProps[], report: CValuationReport) => {
  const filterData: FilterDataType = { activeConfigId: null, defaultFilterIds: [] };

  for (let i = 0; i < rowConfigs.length; i++) {
    const config = rowConfigs[i];
    if (config.defaultFilter) {
      filterData[config.id] = config.defaultFilter(report, config);
      filterData.defaultFilterIds.push(config.id);
    }
  }

  return filterData;
};

const FilterPopup = (props: FilterPopupProps) => {
  const { setFilterData, filterData, trigger, onFilterOpen } = props;

  return (
    <Popup
      trigger={trigger}
      onOpen={() => onFilterOpen()}
      onClose={() => setFilterData({ ...filterData, activeConfigId: null })}
      content={<FilterForm {...props} />}
      hoverable={true}
      position="bottom left"
      className="pm-none"
    />
  );
};

export default FilterPopup;
