import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import { REFUSAL_RIGHT_RENT_ASSESSMENT_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';
import { conditionalClassName } from 'utils/tsHelper';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';
import { valuationDataFloorMap } from './premisesBody';

export const refusalRightsBody = (item: CValuationReport): JSX.Element => {
  let refusalFlag,
    refusalRights,
    justContent = false;
  if (item?.data) {
    refusalFlag = item.data.specialRights.refusalFlag;
    refusalRights = item.data.specialRights.refusalRights;
  } else {
    refusalFlag = true;
    refusalRights = item as any;
    justContent = true;
  }

  if (!refusalRights) return filterBooleanElementBody(refusalFlag);

  const wholeOrPart = refusalRights?.wholeOrPart ? refusalRights.wholeOrPart.toUpperCase() : refusalRights?.wholeOrPart;
  const rentAssessmentValue = refusalRights?.rentAssessment
    ? refusalRights?.rentAssessment.toUpperCase()
    : refusalRights?.rentAssessment;

  const buildingName = refusalRights?.premises ? refusalRights.premises.buildingName : refusalRights.building;
  const floors = refusalRights?.premises ? refusalRights.premises.floors : refusalRights.floors;

  const content = refusalRights ? (
    (() => {
      const exercisability = WHOLE_OR_PART_OPTIONS.find(option => option.value === wholeOrPart)?.text;

      const rentAssessment = REFUSAL_RIGHT_RENT_ASSESSMENT_OPTIONS.find(
        option => option.value === rentAssessmentValue,
      )?.text;

      const exercisableFrom = refusalRights.exercisableFrom
        ? moment(refusalRights.exercisableFrom).format('DD/MM/YYYY')
        : null;

      const priority =
        refusalRights.priority.length > 0
          ? refusalRights.priority.map((priority: any, index: number) => (
              <Badge
                badgeColor={BadgeColor.GRAY}
                key={`priority-${index}`}
                className={conditionalClassName(index !== 0, 'm-l-xs')}
              >
                {replaceUnderscoreWithSpace(priority)}
              </Badge>
            ))
          : null;

      return (
        <div
          className="d-flex-column rofo-option-content flex-gap-s"
          style={{ minWidth: '30rem' }}
        >
          <span>
            Building: <strong>{buildingName}</strong>
          </span>

          <span className="d-flex flex-gap-s">
            Floors: <strong>{valuationDataFloorMap(floors)}</strong>
          </span>

          {exercisability ? (
            <span>
              Exercisable in: <strong>{exercisability}</strong>
            </span>
          ) : (
            <></>
          )}

          {exercisableFrom ? (
            <span>
              Exercisable from: <strong>{exercisableFrom}</strong>
            </span>
          ) : (
            <></>
          )}

          {priority ? <span>Priority: {priority}</span> : <></>}

          <span>
            Rent assessment: <strong>{rentAssessment}</strong>
          </span>

          <span>
            Reoffer to tenant before leasing at lower rent:{' '}
            <strong>{refusalRights.reofferBeforeLeasingAtLowerRent ? 'Yes' : 'No'}</strong>
          </span>
        </div>
      );
    })()
  ) : (
    <></>
  );

  if (justContent) {
    return content;
  }

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(refusalFlag)}</div>}
      content={content}
      disabled={!refusalFlag}
    />
  );
};
