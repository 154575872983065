import { DataType, FilterItem, RowAction, RowProps } from '../../RowConfig/RowProps';
import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import DurationFilter from './DurationFilter';
import ListFilter from './ListFilter';
import NumberFilter from './NumberFilter';
import PremiseFilter from './PremiseFilter';
import StringFilter from './StringFilter';

export interface FilterItemChildProps {
  filterItem: FilterItem;
  dataType?: DataType;
  label: string;
  onChange: (key: string, value: string | number | Duration | boolean | any, updateRoot?: boolean) => void;
  options?: Array<any>;
  id: number;
}

interface FilterItemProps {
  filterItemIndex: number;
  filter: RowAction & RowProps;
  onChange: (key: string, value: string | number | Duration | boolean | any, updateRoot?: boolean) => void;
}

/**
 * Contents for row filter in filter popup
 * @returns
 */
function FilterItemForm({ filter, onChange, filterItemIndex }: FilterItemProps): JSX.Element {
  if (!filter || !filter.filterConditions) {
    return <></>;
  }

  const { dataType, filterConditions, filterElement, options, label, id } = filter;

  const filterProps: FilterItemChildProps = {
    filterItem: filterConditions[filterItemIndex],
    onChange,
    label,
    options,
    dataType,
    id,
  };

  if (filterElement) {
    return filterElement(filterProps);
  }

  switch (dataType) {
    case DataType.STRING:
      return <StringFilter {...filterProps} />;
    case DataType.NUMBER:
      return <NumberFilter {...filterProps} />;
    case DataType.DECIMAL:
      return <NumberFilter {...filterProps} />;
    case DataType.DATE:
      return <DateFilter {...filterProps} />;
    case DataType.DURATION:
      return <DurationFilter {...filterProps} />;
    case DataType.BOOLEAN:
      return <BooleanFilter {...filterProps} />;
    case DataType.LIST:
      return <ListFilter {...filterProps} />;
    case DataType.PREMISE:
      return <PremiseFilter {...filterProps} />;
    default:
      return <></>;
  }
}

export default FilterItemForm;
