import TableReactPrime from 'atoms/TableReactPrime';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { updateNestedObject } from 'utils/utils-nested-object-update';
import { SpaceSelectorRow, columnConfig } from './columnConfig';

interface PremiseChargesTableProps extends ModeProps {
  content: any[];
  onChange: (updatedContent: SpaceSelectorRow[]) => void;
}

const PremiseChargesTable = ({ mode, content, onChange }: PremiseChargesTableProps) => {
  const disabled: boolean = mode === MODE_OPTIONS.READ;

  const onRowColumnChange = (fieldKey: string, value: any, rowIndex: number) => {
    const updatedContent = updateNestedObject(content, [`${rowIndex}`, fieldKey], value);

    onChange(updatedContent);
  };

  return (
    <div className="space-table-container premise-charges-table">
      <div className={`space-units-listing ${disabled && 'disabled-inputs'}`}>
        <TableReactPrime
          isFetching={false}
          content={content}
          dataTest=""
          footerColumnGroup={[]}
          columnConfig={columnConfig(onRowColumnChange, mode)}
        />
      </div>
    </div>
  );
};

export default PremiseChargesTable;
