import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface GetParameterSubGroupQueryVariables {
  parameterSubGroupId: string;
}

const GET_PARAMETER_SUB_GROUP = (id: string) => {
  if (!id) throw new Error('Get Parameter Sub Group: id is required');

  const variables: GetParameterSubGroupQueryVariables = { parameterSubGroupId: id };

  return {
    query: gql`
      query ($parameterSubGroupId: ID!) {
        getParameterSubGroup(parameterSubGroupId: $parameterSubGroupId)
      }
    `,
    variables,
  };
};

export const getParameterSubGroup = createAsyncThunk(
  'parameters/getParameterSubGroup',
  async ({ id }: { id: string }) => {
    const response = await adminClient.query(GET_PARAMETER_SUB_GROUP(id));
    return response;
  },
);
