import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { TransactionProvisionTemp, listTransactionProvisions } from 'common/api/transactions';

const GeneralForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => {
    dispatch(updateActiveAmendment({ key, value }));
  };

  const {
    activeAmendment,
    activeTransactionProvisions,
    activeTransaction: { id: transactionId },
  } = useAppSelector((state: RootState) => state.transactionDetail);
  const { provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);

  // list the provisions of the policy that are USED in this transaction
  const transactionProvisionsUsed: TransactionProvisionTemp[] = activeTransactionProvisions.filter(
    ({ mandatory, selectedByDefault, selected }: TransactionProvisionTemp) =>
      mandatory || selectedByDefault || selected,
  );

  // List only the categories that have a provision
  const categoryList: ProvisionCategoryClone[] = provisionCategoriesList.filter(category => {
    const hasProvisions = transactionProvisionsUsed.find(
      (element: TransactionProvisionTemp) => element.provision?.provisionCategory?.id === category.id,
    );
    return !!hasProvisions;
  });

  const categoryOptions = categoryList.map(({ name, id }) => ({
    key: id,
    value: id,
    text: name,
  }));

  useEffect(() => {
    if (transactionId) {
      dispatch(listTransactionProvisions({ transactionId }));
    }
  }, [transactionId]);

  return (
    <div className="grid">
      <div className="col-12">
        <InputField
          className="m-none"
          label="Name"
          value={activeAmendment?.name || ''}
          disabled={mode === MODE_OPTIONS.READ}
          required={mode === MODE_OPTIONS.EDIT}
          onChange={onChange}
          fieldKey="name"
        />
      </div>

      <div className="col-12">
        <SelectField
          label="Provision Category"
          value={activeAmendment.categoryId}
          disabled={mode === MODE_OPTIONS.READ}
          required={mode === MODE_OPTIONS.EDIT}
          search={true}
          placeholder="Select..."
          options={categoryOptions}
          onChange={onChange}
          fieldKey="categoryId"
        />
      </div>
    </div>
  );
};

export default GeneralForm;
