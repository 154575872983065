import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';

export const saleAndRedevelopmentBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { saleAndRedevelopmentFlag, saleAndRedevelopmentRights },
    },
  } = item;

  const earliestDate = saleAndRedevelopmentRights?.earliestDate
    ? moment(saleAndRedevelopmentRights?.earliestDate).format('DD/MM/YYYY')
    : null;

  const compensationFormula = saleAndRedevelopmentRights?.compensationFormula;
  const noticePeriodMonths = saleAndRedevelopmentRights?.noticePeriodMonths;

  const disabled = !saleAndRedevelopmentFlag || !compensationFormula || !noticePeriodMonths;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(saleAndRedevelopmentFlag)}</div>}
      content={
        saleAndRedevelopmentRights ? (
          (() => {
            return (
              <div className="d-flex-column flex-gap-xxs">
                {earliestDate ? (
                  <span>
                    Earliest date: <strong>{earliestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {noticePeriodMonths ? (
                  <span>
                    Notice period:{' '}
                    <strong>
                      {noticePeriodMonths} month{noticePeriodMonths > 1 ? 's' : ''}
                    </strong>
                  </span>
                ) : (
                  <></>
                )}

                {compensationFormula ? (
                  <span>
                    Compensation formula: <strong>{compensationFormula}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={disabled}
    />
  );
};
