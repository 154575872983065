import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

const amendmentQueryFields = `
  id
  name
  content
  fromTransactionDiscussion
  category {
    id
  }
`;

interface GetAmendmentQueryVariables {
  amendmentId: string;
}

const GET_AMENDMENT = (amendmentId: string) => {
  if (!amendmentId) throw new Error('Get Amendment: amendmentId is required');

  const variables: GetAmendmentQueryVariables = {
    amendmentId,
  };

  return {
    query: gql`
      query ($amendmentId: ID!) {
        getAmendment(amendmentId: $amendmentId) {
         ${amendmentQueryFields}
        }
      }
    `,
    variables,
  };
};

export const getAmendment = createAsyncThunk('transactions/getAmendment', async (amendmentId: string) => {
  const response = await client.query(GET_AMENDMENT(amendmentId));
  return response;
});
