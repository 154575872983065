import { Fragment, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { last } from 'lodash';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import ParamRefOffset from 'common/model/ParamRefOffset';
import { GROUP_TYPE, OPTION_TYPES_SECTIONS, OptionItem, OptionTypes } from 'utils/types/categories';
import { KeyboardKeys } from 'utils/utils-clean-input';
import { Icons } from 'utils/utils-icons';

interface BodyOptionsProps {
  optionMenu: OptionItem[];
  lastOptionMenu: OptionItem[];
  onSelect: (option: OptionItem) => void;
}

/**
 * This component is the list of options within the dropdown
 *
 * This contains:
 * - Non selectables titles (if exist) and grouped by
 * - Options Menu selectables (non-values, values, and custom values)
 *
 * Custom values can be selected by using 'enter' button
 *
 * @param props.optionMenu Array | List of current dropdown to be shown
 * @param props.onSelect Function | Callback to determine which has selected
 */
export const BodyOptions = ({ optionMenu, lastOptionMenu, onSelect }: BodyOptionsProps): JSX.Element => {
  const [indexValue, setIndexValue] = useState<string>('');

  const hasTable: boolean = !!lastOptionMenu.find(({ group }) => group === GROUP_TYPE.TABLES);

  const lastItem = last(lastOptionMenu);

  const handleSelectParameterWithTable = (label: string, value: string): void => {
    if (lastItem) {
      onSelect({ ...lastItem, group: GROUP_TYPE.VALUES, custom: { label, value } });
    }
  };

  const onKeyEnterPressDown = (event: React.KeyboardEvent<HTMLInputElement>, item: OptionItem): void => {
    if (event.key === KeyboardKeys.Enter) {
      const label = 'Index';
      onSelect({ ...item, custom: { label, value: indexValue } });
      handleSelectParameterWithTable(label, indexValue);
    }
  };

  const getOptionMenu = (filtered: OptionItem[]): JSX.Element[] => {
    return filtered.map((option, index) => {
      return (
        <li
          onClick={() => onSelect(option)}
          className="item"
          key={index}
        >
          {option.label}
          <InnerIcon
            option={option}
            hasTable={hasTable}
          />
        </li>
      );
    });
  };

  const options: JSX.Element[][] = OPTION_TYPES_SECTIONS.map((type: OptionTypes) => {
    const filtered: OptionItem[] = optionMenu.filter(option => option.group === type);

    const title: JSX.Element[] = [];
    if (type !== GROUP_TYPE.CUSTOM) {
      title.push(
        <li
          className="title"
          key={type}
        >
          {type}
        </li>,
      );
    }

    const _options: JSX.Element[] = getOptionMenu(filtered);

    if (filtered.length) return title.concat(_options);

    return [];
  });

  if (hasTable && lastItem?.group === GROUP_TYPE.PARAMETERS) {
    return (
      <Fragment>
        <li className="title">OFFSET</li>
        <li
          className="item"
          onClick={() => handleSelectParameterWithTable('Offset', ParamRefOffset.N)}
        >
          (N)
        </li>
        <li
          className="item"
          onClick={() => handleSelectParameterWithTable('Offset', ParamRefOffset.NMinus1)}
        >
          (N-1)
        </li>
        <li
          className="item"
          onClick={() => handleSelectParameterWithTable('Offset', ParamRefOffset.NPlus1)}
        >
          (N+1)
        </li>
        <li className="title">INDEX</li>
        <li>
          <Input
            className="index-input"
            type={InputFieldType.TEXT}
            placeholder="Enter integer value..."
            value={indexValue}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyEnterPressDown(e, lastItem)}
            onChange={({ target }) => setIndexValue(target.value)}
          />
        </li>
      </Fragment>
    );
  }

  return <>{options}</>;
};

/**
 * This component only check a condition. If has a Icon or not
 */
const InnerIcon = ({ option, hasTable }: { option: OptionItem; hasTable: boolean }): JSX.Element => {
  const { group, children } = option;

  if (hasTable && group === GROUP_TYPE.PARAMETERS) return <Icon icon={Icons.ChevRight} />;

  if (children) {
    let hasNextOptionMenu: boolean = group !== GROUP_TYPE.VALUES && children.length > 0;

    if (hasNextOptionMenu)
      return (
        <Icon
          icon={Icons.ChevRight}
          className="go-right-icon"
        />
      );
  }

  return <></>;
};
