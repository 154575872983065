import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface SetCrossRefInTransactionQueryVariables {
  id: string | undefined;
  landlordId: string | null;
}

export const SET_CROSS_REF_IN_TRANSACTION = (id: string | undefined | null) => {
  if (!id) throw new Error('SET_CROSS_REF_IN_TRANSACTION: id is required');

  const landlordId = getCurrentLandlordEntityId();

  const variables: SetCrossRefInTransactionQueryVariables = { id, landlordId };

  return {
    query: gql`
      query ($id: ID!, $landlordId: ID!) {
        setCrossRefInTransaction(id: $id, landlordId: $landlordId) {
          content
          isAmendment
          documentType {
            name
            id
          }
        }
      }
    `,
    variables,
  };
};

export const setCrossRefInTransaction = createAsyncThunk(
  'transactions/setCrossRefInTransaction',
  async ({ id }: { id: string | undefined | null }) => {
    const response = await client.query(SET_CROSS_REF_IN_TRANSACTION(id));
    return response;
  },
);
