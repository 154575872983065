import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { AmendmentInput } from '.';

interface UpdateAmendmentQueryVariables {
  transactionId: string;
  params: ActiveAmendment;
}

const CREATE_AMENDMENT = (transactionId: string, amendment: ActiveAmendment) => {
  if (!transactionId) throw new Error('Create Amendment: transactionId is required');

  const variables: UpdateAmendmentQueryVariables = {
    transactionId,
    params: amendment,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $params: AmendmentInput!) {
        createAmendment(transactionId: $transactionId, params: $params) {
          id
          name
          content
        }
      }
    `,
    variables,
  };
};

export const createAmendment = createAsyncThunk('transactions/createAmendment', async (_args, { getState }) => {
  const {
    transactionDetail: { activeTransaction, activeAmendment },
  } = getState() as RootState;

  const response = await client.mutate(CREATE_AMENDMENT(activeTransaction.id!, activeAmendment));
  return response;
});
