import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteParameterSubGroupQueryVariables {
  id: string;
}

export const DELETE_PARAMETER_SUB_GROUP = (id: string) => {
  if (!id) throw new Error('Delete Parameter Sub Group: id is required');

  const variables: DeleteParameterSubGroupQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteParameterSubGroup(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteParameterSubGroup = createAsyncThunk(
  'parameters/deleteParameterSubGroup',
  async ({ id }: { id: string }) => {
    const response = await adminClient.mutate(DELETE_PARAMETER_SUB_GROUP(id));
    return response;
  },
);
