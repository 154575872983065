import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import PermissionInput from 'common/model/PermissionInput';

export const CREATE_PERMISSION = (params: PermissionInput) => {
  const variables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: PermissionInput!) {
        createPermission(params: $params) {
          id
          resource
          action
          insertedAt
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const createPermission = createAsyncThunk('auth/createPermission', async (args: { params: PermissionInput }) => {
  const response = await adminClient.mutate(CREATE_PERMISSION(args.params));
  return response;
});
