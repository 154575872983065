import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterSubGroupPickName } from 'common/_classes/ParameterSubGroupDetailClone';
import { getClientType } from 'utils/utils-authentication';

export const LIST_PARAMETER_SUB_GROUPS = () => {
  return {
    query: gql`
      query {
        listParameterSubGroups {
          ${ParameterSubGroupPickName.fragments()}
        }
      }
    `,
  };
};

export const listParameterSubGroups = createAsyncThunk('parameters/listParameterSubGroups', async () => {
  const response = await getClientType().query(LIST_PARAMETER_SUB_GROUPS());
  return response;
});
