import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updatePermissionProperty } from 'store/adminAuth/permissionDetails';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { PERMISSION_ACTION_OPTIONS, RESOURCE_OPTIONS } from 'utils/UI';
import './style.scss';

const PermissionModalForm = ({ onSave }: { onSave: () => void }) => {
  const dispatch = useAppDispatch();
  const { permission, isLoading } = useAppSelector((state: RootState) => state.adminPermissionDetails);

  const onChange = (key: string, value: string) => dispatch(updatePermissionProperty({ key, value }));

  return (
    <Grid className="form-content">
      <Grid.Row>
        <Grid.Column width={8}>
          <SelectField
            label="Resource"
            fieldKey="resource"
            value={permission?.resource ?? null}
            onChange={onChange}
            options={RESOURCE_OPTIONS}
            required={true}
            disabled={isLoading}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Action"
            fieldKey="action"
            options={PERMISSION_ACTION_OPTIONS}
            value={permission?.action}
            onChange={onChange}
            required={true}
            disabled={isLoading}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="p-none">
        <div className="height-0_0714 w-100 m-b-xl m-t-xl border-bottom-sm-grayish-magenta-light"></div>
      </Grid.Row>

      <Grid.Row className="p-none">
        <Button
          className="btn grey-bg"
          onClick={onSave}
        >
          {permission?.id ? 'Save' : 'Create'}
        </Button>
      </Grid.Row>
    </Grid>
  );
};

export default PermissionModalForm;
