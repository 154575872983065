import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import MemberStatus from 'common/model/MemberStatus';
import { MemberClone } from 'common/api/members';
import { Icons } from 'utils/utils-icons';

interface EntitiesListProps {
  isAdmin: boolean;
}

const EntitiesList = ({ isAdmin }: EntitiesListProps) => {
  const { members } = useAppSelector((state: RootState) => state.auth);
  const selectedEntity = localStorage.getItem('sericin_selected_entity');
  const navigate = useNavigate();
  const location = useLocation();

  let sortedMembers: MemberClone[] = orderBy(members, ['entity.name'], ['asc']);

  if (sortedMembers.length !== 0) {
    sortedMembers = sortedMembers.filter(obj => obj.status === MemberStatus.Valid);
    const getIndex = sortedMembers.findIndex(obj => obj?.entity?.id === selectedEntity);

    if (getIndex !== -1) {
      sortedMembers = [...sortedMembers.splice(getIndex, 1), ...sortedMembers];
    }
  }

  const shortenEntityName = (name: string) => {
    if (!name) return '';
    if (name.length > 28) {
      return `${name.substring(0, 27)}...`;
    } else {
      return name;
    }
  };

  const updateDefaultEntityLocally = (entityId: string) => {
    localStorage.setItem('sericin_selected_entity', entityId);
    const pathname = location.pathname;
    if (pathname.includes('tenant-entities') && pathname.includes('details')) {
      navigate(`/tenant-entities/dashboard`);
    } else if (pathname.includes('tenant-entities') && pathname.includes('create')) {
      const href = window.location.href;
      if (href.includes('id')) {
        navigate(`/tenant-entities/dashboard`);
      } else {
        window.location.reload();
      }
    } else if (pathname.includes('properties') && pathname.includes('details')) {
      navigate(`/properties/dashboard`);
    } else if (pathname.includes('properties') && pathname.includes('create')) {
      const href = window.location.href;
      if (href.includes('id')) {
        navigate(`/properties/dashboard`);
      } else {
        window.location.reload();
      }
    } else if (pathname.includes('profile')) {
      navigate(location.pathname);
    } else if (pathname.includes('users') && !isAdmin) {
      navigate('/landlord-entities/dashboard');
    } else if (pathname.includes('details')) {
      // In case user is in a 'detail' page, and user change to a new entity, force the update
      // of the page to the associated listing page.
      const listingPageUrl = pathname.split('/')[1];
      navigate(`/${listingPageUrl}/dashboard`);
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      {sortedMembers.length !== 0 && (
        <>
          <span className="p-t-none settings-menu-title">Entities</span>
          <div className="list-of-entities">
            {sortedMembers.map(({ entity, role }, index) => (
              <Dropdown.Item
                key={`entities-options-${index}`}
                className="entity-item"
                onClick={() => updateDefaultEntityLocally(entity?.id)}
              >
                <div className="item-name">{shortenEntityName(entity?.name)}</div>
                <div className="user-role">{role?.name || ''}</div>
                {selectedEntity === entity?.id && <Icon icon={Icons.Check} />}
              </Dropdown.Item>
            ))}
          </div>
          <hr className="border-sm-grayish-magenta-light" />
        </>
      )}
    </>
  );
};

export default EntitiesList;
