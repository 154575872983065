import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import PermissionFilter from 'common/model/PermissionFilter';

interface ListPermissionsQueryParams {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  filter?: PermissionFilter;
}

const LIST_PERMISSIONS = (args: ListPermissionsQueryParams) => {
  return {
    query: gql`
      query ($first: Int, $last: Int, $after: String, $before: String, $filter: PermissionFilter) {
        listPermissions(first: $first, last: $last, after: $after, before: $before, filter: $filter) {
          edges {
            cursor
            node {
              id
              action
              resource
              insertedAt
              updatedAt
            }
          }
        }
      }
    `,
    variables: args,
  };
};

export const listPermissions = createAsyncThunk(
  'permissions/listPermissions',
  async (args: ListPermissionsQueryParams, { getState }) => {
    const response = await adminClient.query(LIST_PERMISSIONS(args));
    return response;
  },
);
