import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import { getNode } from './getNode';
import { ConditionsInputProps, NodeInputProps, ParamRefInputProps } from './types';

interface UpdateNodeQueryVariables {
  id: string;
  params: NodeInputProps;
}

export const UPDATE_NODE = (params: NodeInputProps, nodeId?: string) => {
  if (!nodeId) throw new Error('Update Node: nodeId is required');

  const variables: UpdateNodeQueryVariables = {
    id: nodeId,
    params,
  };

  return {
    mutation: gql`
      mutation ($params: NodeInput!, $id: ID!) {
        updateNode(id: $id, params: $params) {
          id
          name
        }
      }
    `,
    variables,
  };
};

export const updateNode = createAsyncThunk('nodes/updateNode', async (_args: undefined, { getState, dispatch }) => {
  const state: any = getState() as RootState;
  const { node } = state.nodes;

  const provisionId = state.provisionDetail.activeProvision.id;

  if (!node) return;

  try {
    let { paramRefs, conditions } = node;

    paramRefs = JSON.parse(JSON.stringify(paramRefs || [])) as ParamRefInputProps[];
    conditions = JSON.parse(JSON.stringify(conditions || [])) as ConditionsInputProps[];

    const { id, ...nodeParameters }: any = {
      ...node,
      provisionId,
      paramRefs: paramRefs.filter((paramRef: ParamRefInputProps) => paramRef),
      conditions: conditions.filter((condition: ConditionsInputProps) => condition),
    };

    const response = await adminClient.mutate(UPDATE_NODE(nodeParameters, id));

    if (node.id) dispatch(getNode(node.id));

    return response.data;
  } catch (e) {
    console.error('### An error occurred when trying to fetch a Node', e);
  }
});
