import DropdownSelect from 'atoms/DropdownSelect';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  DataType,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowConfig/RowProps';
import { filterWhile } from './StringFilter';

export const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Greater than or equal to', value: FilterMode.GREATER_THAN_OR_EQUALS },
  { key: 2, text: 'Less than or equal to', value: FilterMode.LESS_THAN_OR_EQUALS },
  { key: 3, text: 'Equals', value: FilterMode.EQUALS },
];

const compare = (filterValue: number, value: number, filterMode?: FilterMode) => {
  switch (filterMode) {
    case FilterMode.EQUALS:
      return value === filterValue;
    case FilterMode.GREATER_THAN_OR_EQUALS:
      return value >= filterValue;
    case FilterMode.LESS_THAN_OR_EQUALS:
      return value <= filterValue;
    default:
      return true;
  }
};

export function applyNumbersFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyNumbersFilter: field key is missing');

  let value = getNestedValue(content, rowConfig.field);

  if (checkIfNotDefined(value)) {
    value = 0;
  }

  if (rowConfig.dataType === DataType.DECIMAL) {
    value = value.toNumber();
  }

  return filterWhile(value, rowAction, compare);
}

const NumberFilter = ({ filterItem, dataType, onChange }: FilterItemChildProps): JSX.Element => {
  return (
    <>
      <DropdownSelect
        placeholder="Select..."
        fieldKey={FilterOnchangeKeys.filterMode}
        value={filterItem.filterMode}
        options={FILTER_OPTIONS}
        onChange={onChange}
        clearable={true}
      />

      <InputField
        type={InputFieldType.NUMBER}
        value={filterItem.filterValue}
        placeholder="Enter number"
        onChange={onChange}
        fieldKey={FilterOnchangeKeys.filterValue}
        isDecimal={dataType === DataType.DECIMAL}
      />
    </>
  );
};

export default NumberFilter;
