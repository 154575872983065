import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface ListTransactionGroupsQueryVariables {
  transactionId: string | null | undefined;
  documentTypeId: string | null;
}

const LIST_TRANSACTION_GROUPS = (transactionId: string | null | undefined, documentTypeId: string | null) => {
  if (!transactionId) throw new Error('List Transaction Groups: transactionId is required');
  if (!documentTypeId) throw new Error('List Transaction Groups: documentTypeId is required');

  const variables: ListTransactionGroupsQueryVariables = {
    transactionId: transactionId,
    documentTypeId: documentTypeId,
  };

  // TODO fragment might be needed
  return {
    query: gql`
      query ($transactionId: ID!, $documentTypeId: ID!) {
        listDiscussionGroups(transactionId: $transactionId, documentTypeId: $documentTypeId) {
          id
          index
          provisionName
          type
          documentType {
            id
          }
          transaction {
            id
          }
          transactionChannels {
            id
            title
            clauseNumber
            index
            status
            documentTypeId
            node {
              id
            }
            messages {
              id
              message
              updatedAt
            }
            type
          }
        }
      }
    `,
    variables,
  };
};

export const listDiscussionGroups = createAsyncThunk(
  'transactions/listDiscussionGroups',
  async ({ documentTypeId }: { documentTypeId?: string }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
      miscellaneous: { activeDocType },
    } = getState() as RootState;

    const docId = documentTypeId ? documentTypeId : activeDocType?.id || null;
    const response = await client.query(LIST_TRANSACTION_GROUPS(activeTransaction.id, docId));
    return response;
  },
);
