import { useEffect } from 'react';
import React from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dictionary, groupBy } from 'lodash';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import { listContextParameters } from 'common/api/parameters';
import { listTransactionDiscussionApprovals } from 'common/api/transactions/approvals/listTransactionDiscussionApprovals';
import { listTransactionParameterApprovals } from 'common/api/transactions/approvals/listTransactionParameterApprovals';
import { listDiscussionGroups } from 'common/api/transactions/listDiscussionGroups';
import { getCurrentDateAndTime } from 'utils/utils-date';
import DiscussionsTable from '../../History/Tabs/Doc/DiscussionsModal/DiscussionTable';
import ChangesBox from './ChangesBox';
import './ApprovalChanges.scss';

export enum ChangesBoxType {
  Parameter = 'Parameter',
  Discussion = 'Discussion',
  Amendment = 'Amendment',
  Provision = 'Provision',
}

const ApprovalChanges = () => {
  const dispatch = useAppDispatch();

  const { transactionApprovalAnswers, activeTransaction } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );

  useEffect(() => {
    dispatch(
      listContextParameters({
        context: ExecuteContext.Transaction,
        contextId: activeTransaction.id,
        provisionId: null,
        conditional: false,
        mode: ParameterModes.Detailed,
      }),
    );
  }, [dispatch]);

  const messageAnswers = transactionApprovalAnswers.filter(
    (transactionApprovalAnswer: TransactionApprovalAnswer) =>
      transactionApprovalAnswer.transactionDiscussionApproval?.id !== undefined,
  );

  const messageWiseAnswers: Dictionary<TransactionApprovalAnswer[]> = groupBy(
    messageAnswers,
    (transactionApprovalAnswer: TransactionApprovalAnswer) =>
      transactionApprovalAnswer.transactionDiscussionApproval.id,
  );

  const parameterAnswers = transactionApprovalAnswers.filter(
    (transactionApprovalAnswer: TransactionApprovalAnswer) =>
      transactionApprovalAnswer.transactionParameterApproval?.id !== undefined,
  );

  const parameterWiseAnswers: Dictionary<TransactionApprovalAnswer[]> = groupBy(
    parameterAnswers,
    (transactionApprovalAnswer: TransactionApprovalAnswer) => transactionApprovalAnswer.transactionParameterApproval.id,
  );

  useEffect(() => {
    dispatch(listTransactionDiscussionApprovals());
    dispatch(listTransactionParameterApprovals());
    dispatch(listDiscussionGroups({}));
  }, []);

  return (
    <>
      <div className="discussion-table-container">
        <DiscussionsTable
          approvalChanges={true}
          messageAnswers={messageAnswers}
        />
      </div>
      {Object.entries(parameterWiseAnswers).map(([_approvalId, answers]) => {
        const date = getCurrentDateAndTime(answers[0]?.transactionParameterApproval.dateSubmitted);
        return (
          <React.Fragment key={_approvalId}>
            <ChangesBox
              type={ChangesBoxType.Parameter}
              date={date}
              rows={answers}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ApprovalChanges;
