import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { AmendmentInput } from './types';

interface CreateAmendmentQueryVariables {
  transactionId: string;
  params: ActiveAmendment;
  transactionMessageId?: string;
}

export const CREATE_AMENDMENT_FROM_TRANSACTION_DISCUSSION = (
  transactionId: string,
  amendment: ActiveAmendment,
  transactionMessageId?: string,
) => {
  const variables: CreateAmendmentQueryVariables = {
    transactionId,
    params: amendment,
    transactionMessageId,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $params: AmendmentInput!, $transactionMessageId: ID!) {
        createAmendmentFromTransactionDiscussion(
          transactionId: $transactionId
          params: $params
          transactionMessageId: $transactionMessageId
        ) {
          id
          name
        }
      }
    `,
    variables,
  };
};

export const createAmendmentFromTransactionDiscussion = createAsyncThunk(
  'transactions/createAmendmentFromTransactionDiscussion',
  async (transactionMessageId: string, { getState }) => {
    const {
      transactionDetail: { activeTransaction, activeAmendment },
    } = getState() as RootState;

    const response = await client.mutate(
      CREATE_AMENDMENT_FROM_TRANSACTION_DISCUSSION(activeTransaction.id!, activeAmendment, transactionMessageId),
    );
    return response;
  },
);
