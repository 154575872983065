import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { OrganisationInput } from './types';

interface UpdateOrganisationQueryVariables {
  id: string;
  params: OrganisationInput;
}

export const UPDATE_ORGANISATION = (organisationId: string, updatedOrganisation: OrganisationInput) => {
  const variables: UpdateOrganisationQueryVariables = {
    id: organisationId,
    params: {
      name: updatedOrganisation.name,
      type: updatedOrganisation.type,
      index: updatedOrganisation.index,
      logo: updatedOrganisation.logo,
      operatingStatus: updatedOrganisation.operatingStatus,
      archived: updatedOrganisation.archived,
      parentId: updatedOrganisation.parentId,
    },
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: OrganisationInput!) {
        updateOrganisation(id: $id, params: $params) {
          id
          name
          type
          index
          logo
          operatingStatus
          archived
          parentId
        }
      }
    `,
    variables,
  };
};

export const updateOrganisation = createAsyncThunk(
  'organisations/updateOrganisation',
  async ({ id, updatedData }: { id: string; updatedData: OrganisationInput }, { getState }) => {
    const state = getState() as RootState;
    const organisationState: any = state.organisationsListing;

    const response = await client.mutate(UPDATE_ORGANISATION(id, updatedData));

    return response;
  },
);
