import { CValuationReport } from 'common/_classes/valuationReport2';
import SortOrder from 'common/model/SortOrder';
import { deepClone } from 'utils/tsHelper';
import { FilterDataType } from '..';
import { FilterMatchType, RowAction, RowProps, initFilterCondition } from '../RowConfig/RowProps';
import { applySorting } from '../ValuationRow';
import RowTemplate from './RowTemplate';

interface TableBodyProps {
  contents: CValuationReport[];
  setContents: (value: CValuationReport[]) => void;
  filterData: FilterDataType;
  setFilterData: (filterData: FilterDataType) => void;
  rowConfigs: RowProps[];
  onFilter: (filterData: FilterDataType) => void;
  onFilterPopupOpen: (rowConfig?: RowProps) => void;
}

const TableBody = ({
  contents,
  setFilterData,
  setContents,
  filterData,
  rowConfigs,
  onFilter,
  onFilterPopupOpen,
}: TableBodyProps) => {
  /**
   * On Sorting Change
   * @param row
   * @param sortOrder
   *
   * Note: onSortingChange gets event from the RowTemplate indicating a row with a sorting.
   */
  const onSortingChange = (rowConfig: RowProps, sortOrder: SortOrder): void => {
    const updatedFilterData: FilterDataType = deepClone(filterData);
    const rowConfigId = rowConfig.id;

    if (!filterData[rowConfigId]) {
      updatedFilterData[rowConfigId] = { ...rowConfig };
    }

    const filterDataArray = Object.entries(updatedFilterData);

    const updatedWithSortOrder = filterDataArray.map(data => {
      const objKey = data[0];

      if (objKey === 'activeConfigId') return data;

      const parsedKey = parseInt(objKey);

      const value = data[1] as RowAction & RowProps;

      if (!isNaN(parsedKey) && parsedKey !== rowConfigId) {
        return [objKey, { ...value, sortOrder: undefined }];
      } else {
        return [objKey, { ...value, sortOrder }];
      }
    });

    setFilterData(Object.fromEntries(updatedWithSortOrder));

    setContents(applySorting(contents, rowConfig, sortOrder));
  };

  return (
    <tbody>
      <RowTemplate
        rowConfigs={rowConfigs}
        contents={contents}
        filterData={filterData}
        onFilterPopupOpen={onFilterPopupOpen}
        onSortingChange={onSortingChange}
        setFilterData={setFilterData}
        onFilter={onFilter}
      />
    </tbody>
  );
};

export default TableBody;
