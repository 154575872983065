import { createSlice } from '@reduxjs/toolkit';
import { OrganisationListingClone } from 'common/_classes';
import { toast } from 'react-toastify';
import PageInfo from 'common/model/PageInfo';
import { listOrganisations } from 'common/api/organisations';

interface OrganisationsListingState {
  organisationsList: OrganisationListingClone[];
  isLoading: boolean;
  search: string | null;
  isSearching: boolean;
  pageInfo: PageInfo[];
}

const initialState: OrganisationsListingState = {
  organisationsList: [],
  isLoading: false,
  search: null,
  isSearching: false,
  pageInfo: [],
};

const organisationsListingSlice = createSlice({
  name: 'organisationsListing',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    resetOrganisationSearch: state => {
      state.search = null;
    },
  },
  extraReducers: builder => {
    // list organisations
    builder.addCase(listOrganisations.pending, (state, action) => {
      if (action.meta.arg.searchValue) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listOrganisations.fulfilled, (state, action) => {
      const listOrganisations = action?.payload?.listOrganisations;
      if (listOrganisations) {
        const { edges, pageInfo } = action?.payload?.listOrganisations;
        const organisationsList: OrganisationListingClone[] = edges ? edges.map((element: any) => element.node) : [];
        state.organisationsList = organisationsList;
        state.pageInfo = pageInfo;
      }

      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listOrganisations.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to loading the Organisations');
    });
  },
});

export const { updateSearch, resetOrganisationSearch } = organisationsListingSlice.actions;

export default organisationsListingSlice.reducer;
