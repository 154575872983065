import { CValuationReport } from 'common/_classes/valuationReport2';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import { EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';
import { valuationDataFloorMap } from './premisesBody';

export const expansionRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { expansionFlag, expansionRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(expansionFlag)}</div>}
      content={
        expansionRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === expansionRights.wholeOrPart,
            )?.text;

            const rentAssessment = EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS.find(
              option => option.value === expansionRights.rentAssessment,
            )?.text;

            const latestDate = expansionRights.latestDate
              ? moment(expansionRights.latestDate).format('DD/MM/YYYY')
              : null;

            return (
              <div
                className="d-flex-column flex-gap-s"
                style={{ minWidth: '30rem' }}
              >
                <span>
                  Building: <strong>{expansionRights.premises.buildingName}</strong>
                </span>

                <span className="d-flex flex-gap-s">
                  Floors: <strong>{valuationDataFloorMap(expansionRights.premises.floors)}</strong>
                </span>

                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                <span>
                  Rent assessment: <strong>{rentAssessment}</strong>
                </span>

                {latestDate ? (
                  <span>
                    Exercisable until: <strong>{latestDate}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!expansionFlag}
    />
  );
};
