import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { DROPDOWN_OPTION } from 'utils/UI';
import { SpaceSelectorRow } from './columnConfig';

export const inputElement = (
  row: SpaceSelectorRow,
  onChange: Function,
  field: string,
  rowIndex: number,
  type: InputFieldType,
) => {
  return (
    <InputField
      fieldKey={field}
      // @ts-ignore
      value={row[field]}
      onChange={(_key, value) => onChange(field, value, rowIndex)}
      type={type}
    />
  );
};

export const dropDownElement = (
  row: SpaceSelectorRow,
  onChange: Function,
  field: string,
  rowIndex: number,
  options: DROPDOWN_OPTION[],
) => {
  return (
    <div className="form-field">
      <Dropdown
        // @ts-ignore
        value={row[field]}
        onChange={(event: DropdownChangeEvent) => {
          onChange(field, event.value, rowIndex);
        }}
        options={options}
        optionLabel="text"
        data-test=""
        className="width-full"
      />
    </div>
  );
};

enum LicenseType {
  Premises = 'Premises',
  DedicatedArea = 'Dedicated Area',
  NonExclusive = 'Non-Exclusive',
}

interface LICENSE_TYPE_OPTIONS extends Omit<DROPDOWN_OPTION, 'value'> {
  value: LicenseType;
}

export const LICENSE_TYPE_OPTIONS: LICENSE_TYPE_OPTIONS[] = [
  { key: 1, text: 'Premises', value: LicenseType.Premises },
  { key: 2, text: 'Dedicated Area', value: LicenseType.DedicatedArea },
  { key: 3, text: 'Non-Exclusive', value: LicenseType.NonExclusive },
];

enum GrantType {
  Lease = 'Lease',
  License = 'License',
}

interface GRANT_TYPE_OPTIONS extends Omit<DROPDOWN_OPTION, 'value'> {
  value: GrantType;
}

export const GRANT_TYPE_OPTIONS: GRANT_TYPE_OPTIONS[] = [
  { key: 1, text: 'Lease', value: GrantType.Lease },
  { key: 2, text: 'License', value: GrantType.License },
];
