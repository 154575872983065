import { Icon } from '@iconify/react';
import { FilterDataType } from 'components/ComparablesTable';
import FilterPopup from 'components/ComparablesTable/FilterPopup';
import SortOrder from 'common/model/SortOrder';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowProps } from '../../RowConfig/RowProps';

interface RowTemplateProps {
  rowConfigs: RowProps[];
  contents: any[];
  onFilterPopupOpen: (rowConfig: RowProps) => void;
  filterData: FilterDataType;
  onSortingChange: (rowConfig: RowProps, sortOrder: SortOrder) => void;
  setFilterData: (filterData: FilterDataType) => void;
  onFilter: (filterData: FilterDataType) => void;
}

/**
 * Row Template for row items
 * Contains value, filters,sorting icons
 * @returns
 */
function RowTemplate({
  rowConfigs,
  contents,
  onFilterPopupOpen,
  onSortingChange,
  filterData,
  setFilterData,
  onFilter,
}: RowTemplateProps): JSX.Element {
  const getSortingElement = (rowConfig: RowProps, filterData: FilterDataType): JSX.Element => {
    const rowFilter = filterData[rowConfig.id];

    const icon = () => {
      switch (rowFilter?.sortOrder) {
        case SortOrder.Asc:
          return (
            <Icon
              className="m-l-xs"
              icon={Icons.DescSort}
              onClick={() => onSortingChange(rowConfig, SortOrder.Desc)}
            />
          );

        case SortOrder.Desc:
          return (
            <Icon
              className="m-l-xs"
              icon={Icons.AscSort}
              onClick={() => onSortingChange(rowConfig, SortOrder.Asc)}
            />
          );

        default:
          return (
            <Icon
              className="m-l-xs"
              icon={Icons.AllSort}
              onClick={() => onSortingChange(rowConfig, SortOrder.Asc)}
            />
          );
      }
    };

    return <span className={`sort-icon ${conditionalClassName(rowFilter?.sortOrder, 'active')}`}>{icon()}</span>;
  };

  return (
    <>
      {rowConfigs.map((rowConfig, index) => {
        return (
          <tr
            className={rowConfig.isHeading ? 'row-header' : 'row-sub-header'}
            key={`rows_${rowConfig.label}_${rowConfig.id}-${index}`}
          >
            {/* 1st column, with name of the row and the filtering/sorting buttons */}
            <td className={`font-600 ${rowConfig.columnClass}`}>
              <div className="d-flex align-center">
                <span>{rowConfig.label}</span>

                {!rowConfig.isHeading ? (
                  <span className="action-icons d-inline-flex align-center flex-gap-xs">
                    {!rowConfig.hideFilter ? (
                      <FilterPopup
                        filterData={filterData}
                        onFilter={onFilter}
                        setFilterData={setFilterData}
                        onFilterOpen={() => onFilterPopupOpen(rowConfig)}
                        trigger={
                          <span
                            className={`filter-icon ${conditionalClassName(filterData[rowConfig.id]?.filterActive, 'active')}`}
                          >
                            <Icon icon={Icons.Filter} />
                          </span>
                        }
                      />
                    ) : (
                      <></>
                    )}

                    {!rowConfig.hideSorting ? getSortingElement(rowConfig, filterData) : <></>}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </td>
            {/* all other columns for the body with the contents */}
            {contents.map((content, index) => {
              return rowConfig.isHeading ? (
                <td
                  className="font-600"
                  key={`content_id-${index}`}
                ></td>
              ) : (
                <td
                  className={rowConfig.columnClass}
                  key={`${rowConfig.label}_${rowConfig.id}_${content.id}-${index}`}
                >
                  {/* select custom body if it exist, else the field*/}
                  {rowConfig.body
                    ? rowConfig.body(content)
                    : rowConfig.field
                      ? getNestedValue(content, rowConfig.field)
                      : '---'}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

export default RowTemplate;
