import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterSubGroupDetailClone } from 'common/_classes/ParameterSubGroupDetailClone';
import { RootState } from 'store';

interface UpdateParameterSubGroupParams {
  name: string;
  index: number;
  parameterGroupId: string;
}

interface UpdateParameterSubGroupQueryVariables {
  id: string;
  params: UpdateParameterSubGroupParams;
}

const UPDATE_PARAMETER_SUB_GROUP = (activeSubGroup: ParameterSubGroupDetailClone) => {
  const { id, name, index, parameterGroup } = activeSubGroup;

  if (!id) throw new Error('Update Parameter Sub Group: id is required');
  if (!name) throw new Error('Update Parameter Sub Group: name is required');
  if (!index) throw new Error('Update Parameter Sub Group: index is required');
  if (!parameterGroup || !parameterGroup.id)
    throw new Error('Update Parameter Sub Group: parameter group id is requiresd');

  const params: UpdateParameterSubGroupParams = {
    name,
    index,
    parameterGroupId: parameterGroup.id,
  };

  const variables: UpdateParameterSubGroupQueryVariables = {
    id,
    params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: ParameterSubGroupInput!) {
        updateParameterSubGroup(id: $id, params: $params) {
          ${ParameterSubGroupDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateParameterSubGroup = createAsyncThunk(
  'parameters/updateParameterSubGroup',
  async (_args, { getState }) => {
    const {
      parametersListing: { activeSubGroup },
    }: any = getState() as RootState;
    const response = await adminClient.mutate(UPDATE_PARAMETER_SUB_GROUP(activeSubGroup));
    return response;
  },
);
