import { CValuationReport } from 'common/_classes/valuationReport2';
import { Popup } from 'semantic-ui-react';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import { LANDLORD_APPROVAL_TYPE_OPTIONS, RIGHT_RESTRICTION_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';

export const assignmentRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { assignmentFlag, assignmentRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(assignmentFlag)}</div>}
      content={
        assignmentRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === assignmentRights.wholeOrPart,
            )?.text;

            const restrictedTo = RIGHT_RESTRICTION_OPTIONS.find(
              option => option.value === assignmentRights.restrictedTo,
            )?.text;

            const landlordApproval = LANDLORD_APPROVAL_TYPE_OPTIONS.find(
              option => option.value === assignmentRights.landlordApproval,
            )?.text;

            const premisePercentage = assignmentRights.premisePercentage
              ? assignmentRights.premisePercentage.toString()
              : null;

            return (
              <div className="d-flex-column flex-gap-s">
                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {premisePercentage ? (
                  <span>
                    Premise percentage assignable: <strong>{premisePercentage}%</strong>
                  </span>
                ) : (
                  <></>
                )}

                {restrictedTo ? (
                  <span>
                    Restricted to: <strong>{restrictedTo}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {landlordApproval ? (
                  <span>
                    Landlord approval: <strong>{landlordApproval}</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!assignmentFlag}
    />
  );
};
