import { OrganisationListingClone } from 'common/_classes';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { createNewOrganisation } from 'store/organisations/organisationDetailSlice';
import { updateSearch } from 'store/organisations/organisationsListingSlice';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listOrganisations } from 'common/api/organisations';
import OrganisationHeaderToolbar from './OrganisationHeaderToolbar';
import { OrganisationColumnConfig, OrganisationsRow } from './OrganisationTablesConfig';

const OrganisationsDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, organisationsList } = useAppSelector((state: RootState) => state.organisationsListing);

  const onCreateOrganisation = (): void => {
    // TODO: Replace this with create organisation API
    dispatch(createNewOrganisation());
    navigate('/organisations/create');
  };

  const onViewDetails = (id: string): void => {
    const url = `/organisations/${id}/details`;
    navigate(url);
  };

  const getOrganisations = (params: PaginationAPIProps): void => {
    dispatch(listOrganisations(params));
  };

  const currentList: OrganisationsRow[] = organisationsList.map(
    (organisation: OrganisationListingClone) => new OrganisationsRow(organisation),
  );

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Organisations' }];

    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE ORGANIZATION',
      dataTest: 'create-organisation-button',
      onCreateNew: onCreateOrganisation,
      url: '/organisations/create',
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <OrganisationHeaderToolbar />,
      createNewButton,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          getOrganisations(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={OrganisationColumnConfig(onViewDetails)}
        dataTest="organisation-table"
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default OrganisationsDashboard;
