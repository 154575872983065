import { CValuationReport } from 'common/_classes/valuationReport2';
import Badge, { BadgeColor } from 'atoms/Badge';
import { conditionalClassName } from 'utils/tsHelper';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';

export const unitViewBody = (item: CValuationReport): JSX.Element[] => {
  const {
    data: {
      premisesCharacteristics: { views: floorViews },
    },
  } = item;

  return floorViews.map((floorView, index) => {
    const firstItem = index === 0;
    return (
      <div
        key={`unit-view-${floorView.id}-${index}`}
        className={`d-flex flex-gap-s align-center ${conditionalClassName(!firstItem, 'm-t-s p-t-s border-top')}`}
      >
        <span className="width-auto">{floorView.floorName}</span>

        <div className="d-flex-column flex-gap-xs align-center">
          {floorView.spaces.map((spaceView, index) => (
            <div
              className="d-flex align-center"
              key={`space-view-${spaceView.id}-${index}`}
            >
              <span className="m-r-xs width-auto">{spaceView.spaceName}</span>
              {spaceView.characteristics.map((characteristic, index) => (
                <Badge
                  badgeColor={BadgeColor.GRAY}
                  key={`unit-characteristics-${spaceView.id}-${index}`}
                  className={conditionalClassName(!firstItem, 'm-l-xxs')}
                >
                  {replaceUnderscoreWithSpace(characteristic)}
                </Badge>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  });
};
