import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface MergeTransactionChannelsMutationVariables {
  transactionChannelId: string;
  channelIds: string[];
}

const MERGE_TRANSACTION_CHANNELS = (transactionChannelId: string, channelIds: string[]) => {
  if (!transactionChannelId) throw new Error('Merge Transaction Channels: transactionChannelId is required');

  const variables: MergeTransactionChannelsMutationVariables = {
    transactionChannelId,
    channelIds,
  };

  return {
    mutation: gql`
      mutation ($transactionChannelId: ID!, $channelIds: [ID!]) {
        mergeTransactionChannels(transactionChannelId: $transactionChannelId, channelIds: $channelIds) {
          id
          status
          type
          title
          documentTypeId
          transaction {
            id
          }
          node {
            id
          }
          amendment {
            id
          }
          insertedAt
        }
      }
    `,
    variables,
  };
};

export const mergeTransactionChannels = createAsyncThunk(
  'transactions/mergeTransactionChannels',
  async ({ transactionChannelId, channelIds }: { transactionChannelId: string; channelIds: string[] }) => {
    const response = await client.mutate(MERGE_TRANSACTION_CHANNELS(transactionChannelId, channelIds));
    return response;
  },
);
