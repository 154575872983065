import { FLOOR_PORTION_OPTIONS } from 'components/PremisesForm/utils-premiseForm';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { getObjectId } from 'utils/tsHelper';
import {
  ActiveTransactionChannel,
  DocGeneral,
  DocHistoryProps,
  HistoryDocumentsProps,
  HistoryGeneralProps,
  PremiseProps,
} from './types';

export * from './createAmendment';
export * from './listTenantsAndAutoSelectFirstTenant';
export * from './createAmendmentFromTransactionDiscussion';
export * from './createTransaction';
export * from './createTransactionAnswers';
export * from './createTransactionChannel';
export * from './createTransactionMessage';
export * from './createTransactionMessage';
export * from './deleteAmendment';
export * from './deleteContractOfTransaction';
export * from './deleteTransaction';
export * from './generateModificationNotes';
export * from './getAmendment';
export * from './getAutomatedTransactionIdentifier';
export * from './listPremises';
export * from './getLandlordTransaction';
export * from './getTenantTransaction';
export * from './getTransactionChannel';
export * from './getTransactionContentsOnly';
export * from './listTransactionProvisions';
export * from './listAmendments';
export * from './listLandlordAndTenantEntities';
export * from './listPolicies';
export * from './listTransactionAlerts';
export * from './listTransactionChannels';
export * from './listTransactionMessages';
export * from './listTransactions';
export * from './listLandlordTransactions';
export * from './listTenantTransactions';
export * from './prepTransactionAnswerOfImageTypeForUpload';
export * from './prepTransactionDocForUpload';
export * from './prepTransactionDocsForUpload';
export * from './transactionAbortOrReinstate';
export * from './types';
export * from './updateAmendment';
export * from './updateTransaction';
export * from './updateTransactionChannel';
export * from './updateTransactionMessage';
export * from './updateTransactionProvision';
export * from './updateTransactionWithInitalization';
export * from './uploadSendToTenantPDF';
export * from './sendToLandlord';

//TODO there is a bug here with the typeas in floorIds, there is no spaceIds!
export const setPremiseInput = (premises: PremiseProps[]) => {
  const premisesList = [];
  const { property, floorIds } = premises[0];
  if (floorIds) {
    const floorsStatus = floorIds.map((floor: any) => {
      return {
        floorId: floor.id,
        whole: floor.floorPortion === FLOOR_PORTION_OPTIONS.WHOLE,
      };
    });

    const spaceIdsList: string[] = floorIds.flatMap((floor: any) => floor.spaceIds);
    const time = new Date();
    premisesList.push({
      floorsStatus: floorsStatus,
      propertyId: property.id,
      spaceIds: spaceIdsList,
      // TO DO: Delete this, it's for test purposes only
      // charges: {
      //   version: 2,
      //   monthlyAcPayable: true,
      //   outOfHoursAcPayable: true,
      //   standardAcHoursStart: time.toLocaleTimeString('en-GB', { hour12: false }), // Example: "14:30:00",
      //   standardAcHoursEnd: time.toLocaleTimeString('en-GB', { hour12: false }), // Example: "14:30:00",
      //   standardOutOfHoursAcRate: 29,
      //   acZoning: 'PER_FLOOR',
      //   validFrom: '2025-02-02',
      //   validTo: '2025-01-01',
      //   spaceCharges: [
      //     {
      //       comments: 'Cool comment',
      //       rates: 45,
      //       governmentRent: 20,
      //       standardRateManagementCharges: 10,
      //       standardRateMonthlyAcFees: 11,
      //       spaceId: spaceIdsList[0],
      //     },
      //   ],
      // },
    });
  }

  return premisesList;
};

export const setDocHistoryInput = (historyList: DocHistoryProps[]) => {
  return historyList.map(history => {
    const { user, userId, tenant, tenantId, pendingChanges, note, date, documents, version, status } = history;

    let docs = null;
    if (documents !== null) {
      docs = documents.map((document: DocumentIntendedToBeUploaded) => {
        return {
          name: document.name,
          path: document.path,
          public: document.public,
        };
      });
    }

    return {
      userId: getObjectId(userId, user),
      tenantId: getObjectId(tenantId, tenant),
      pendingChanges,
      note,
      date,
      documents: docs,
      version,
      status,
    };
  });
};

export const setHistoryDocumentsInput = (historyDocuments: HistoryDocumentsProps[]) => {
  return historyDocuments.map(historyDocument => {
    return {
      documentTypeId: historyDocument.documentTypeId,
      history: setDocHistoryInput(historyDocument.history),
    };
  });
};

export const setTransactionChannelInput = (transactionChannel: ActiveTransactionChannel) => {
  return {
    amendmentId: transactionChannel.amendment?.id,
    nodeId: transactionChannel.nodeId ? transactionChannel.nodeId : transactionChannel.node?.id,
    documentTypeId: transactionChannel.documentTypeId,
    status: transactionChannel.status,
    title: transactionChannel.title,
    type: transactionChannel.type,
  };
};

export const setHistoryGeneralInput = (historyGeneral: HistoryGeneralProps[]) => {
  return historyGeneral.map((history: HistoryGeneralProps) => {
    const documentsList = history.documents.map((docGeneral: DocGeneral) => {
      const document: DocumentIntendedToBeUploaded | null =
        docGeneral.document !== null
          ? {
              name: docGeneral.document.name,
              path: docGeneral.document.path,
              public: docGeneral.document.public,
            }
          : null;
      return {
        documentTypeId: docGeneral.documentTypeId,
        document: document,
      };
    });

    return {
      date: history.date,
      notes: history.notes,
      documents: documentsList,
      status: history.status,
      userId: getObjectId(history.userId, history.user),
    };
  });
};
