import { TransactionChannelClone } from 'common/_classes';
import { ContextMenu } from 'primereact/contextmenu';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementStatusTemplate } from 'atoms/TableReactPrime/columnTemplates';
import { TabType, scrollDownChat, scrollToClause } from 'components/PreviewTab/Discussion';
import ParameterChanges from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges/ParameterChanges';
import {
  DiscussionMode,
  resetMessages,
  setDiscussionMode,
  updateGroupChannelIndex,
  updateGroupIndex,
} from 'store/transactions/transactionDetailSlice';
import DiscussionGroup from 'common/model/DiscussionGroup';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import TransactionChannel from 'common/model/TransactionChannel';
import { getTransactionChannel, listTransactionChannels, listTransactionMessages } from 'common/api/transactions';
import { listDiscussionGroups } from 'common/api/transactions/listDiscussionGroups';
import { mergeTransactionChannels } from 'common/api/transactions/mergeTransactionChannels';
import { updateDiscussionGroups } from 'common/api/transactions/updateDiscussionGroups';
import { getActiveDocument } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import {
  ClausesPop,
  CommentsPopUp,
  DiscussionGroupClone,
  discussionColumnConfig,
  parameterChangesStatus,
  statuses,
} from './DiscussionTableConfig';
import './DiscussionsTable.scss';

const DiscussionsTable = ({
  condensed,
  documentTypeId,
  approvalChanges,
  messageAnswers,
}: {
  condensed?: boolean;
  documentTypeId?: string;
  approvalChanges?: boolean;
  messageAnswers?: TransactionApprovalAnswer[];
  setIsParameterChanges?: (isParameterChanges: boolean) => void;
}): JSX.Element => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [messageAnswer, setMessageAnswer] = useState<TransactionApprovalAnswer | undefined>(undefined);

  const openParameterChanges = (messageAnswer: TransactionApprovalAnswer | undefined, channel: TransactionChannel) => {
    dispatch(listTransactionChannels({}));
    openDiscussion(channel.id, channel.node?.id);
    setMessageAnswer(messageAnswer);
    setIsModelOpen(true);
  };
  let config = discussionColumnConfig(messageAnswers, openParameterChanges);
  const fieldsToIgnore = ['tenantComments', 'landlordResponse', 'detailsApprovals'];
  const fieldsToIgnoreApprovalChanges = ['detailsApprovals'];
  config = condensed
    ? config.filter((item: CustomColumnProps) => !fieldsToIgnore.includes(item.field as string))
    : !approvalChanges
      ? config.filter((item: CustomColumnProps) => !fieldsToIgnoreApprovalChanges.includes(item.field as string))
      : config;
  const [columnConfig, setColumnConfig] = useState<CustomColumnProps[]>(config);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setColumnConfig(config);
  }, []);

  useEffect(() => {
    // Remove display flex from discussions modal
    const removeDisplayFlex = () => {
      const dimmers = document.querySelectorAll('.ui.page.modals.dimmer');

      dimmers.forEach(dimmer => {
        const discussionsModal = dimmer.querySelector('.ui.modal.discussions-modal');

        if (discussionsModal) {
          // @ts-ignore
          dimmer.style.display = ''; // Reset the inline style
        }
      });
    };

    // Delay the execution to ensure the DOM is ready
    const timeoutId = setTimeout(() => {
      removeDisplayFlex();
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const { discussionGroups } = useSelector((state: RootState) => state.transactionDetail);
  const currentList: DiscussionGroupClone[] =
    discussionGroups !== null
      ? discussionGroups.map((discussionGroup: DiscussionGroup) => new DiscussionGroupClone(discussionGroup))
      : [];

  const [data, setData] = useState(currentList);

  useEffect(() => {
    if (approvalChanges) {
      setData(currentList);
    }
  }, [discussionGroups, approvalChanges, isModelOpen]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);

  const onRowReorder = (e: any) => {
    setData(e.value); // Update the data state with the new order
    dispatch(updateGroupIndex(e.value));
    dispatch(updateDiscussionGroups());
  };

  const onDragEnd = (result: DropResult, rowId: string) => {
    const { source, destination } = result;

    if (!destination) return;

    let updatedChannels = [] as TransactionChannelClone[];
    const updatedData = data.map(row => {
      if (row.id === rowId) {
        const items = Array.from(row.channels);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);
        updatedChannels = items;
        return { ...row, channels: items };
      }
      return row;
    }) as DiscussionGroupClone[];

    setData(updatedData);
    dispatch(updateGroupChannelIndex({ groupId: rowId, channels: updatedChannels }));
    dispatch(updateDiscussionGroups());
  };

  const handleCheckboxChange = (isChecked: boolean, id: string) => {
    setSelectedRows((prev: any) => (isChecked ? [...prev, id] : prev.filter((i: string) => i !== id)));
  };

  const channels = currentList.flatMap(item => item.channels);

  const mergeDiscussions = (channelId: string) => {
    let mergeFrom = selectedRows as string[];
    const mergeTo = channelId;
    if (mergeFrom.length === 1 && mergeFrom[0] === mergeTo) {
      return;
    }

    if (mergeFrom.includes(mergeTo)) {
      mergeFrom = mergeFrom.filter((obj: string) => obj !== mergeTo);
    }

    dispatch(mergeTransactionChannels({ transactionChannelId: mergeTo, channelIds: mergeFrom })).then(
      (response: any) => {
        // remove redundant parameter blocks
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(listDiscussionGroups({ documentTypeId })).then((response: any) => {
            // remove redundant parameter blocks
            if (response.meta.requestStatus === 'fulfilled') {
              if (!condensed) {
                const { listDiscussionGroups } = response.payload.data;
                const updatedGroups = listDiscussionGroups.map(
                  (discussionGroup: DiscussionGroup) => new DiscussionGroupClone(discussionGroup),
                );
                setData(updatedGroups);
              }
            }
          });
        }
      },
    );
    setSelectedRows([]);
  };

  const menuModel = [
    {
      label: 'Merge To',
      icon: 'pi pi-arrow-down-left',
      items: channels.map((channel: TransactionChannel) => {
        return {
          label: channel.title,
          command: () => mergeDiscussions(channel.id),
        };
      }),
    },
  ];

  const contextMenuRef = useRef(null as any);

  const handleRowContextMenu = (event: React.MouseEvent<HTMLTableRowElement>) => {
    if (contextMenuRef.current !== null) {
      event.preventDefault();
      contextMenuRef.current.show(event); // Open the context menu
    }
  };

  const handleOutsideClick = (event: any) => {
    const contextMenuElement = contextMenuRef.current?.getElement?.();
    if (contextMenuRef.current && contextMenuElement && !contextMenuElement.contains(event.target)) {
      contextMenuRef.current?.hide?.(event); // Call the hide method if it exists
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const activeDocument: HTMLElement | undefined = getActiveDocument()?.documentElement;

  const openDiscussion = (channelId: string, nodeId: string | undefined | null) => {
    if (condensed || approvalChanges) {
      if (nodeId) {
        scrollToClause(nodeId, activeDocument);
      }
      dispatch(resetMessages());
      dispatch(getTransactionChannel({ id: channelId })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(setDiscussionMode(DiscussionMode.View));
          setTimeout(() => {
            scrollDownChat({ tabType: TabType.Preview });
          }, 100);
        }
      });
    }
  };

  const rowExpansionTemplate = (row: DiscussionGroupClone) => {
    return (
      <>
        {selectedRows.length !== 0 && (
          <ContextMenu
            model={menuModel}
            ref={contextMenuRef}
          />
        )}
        <DragDropContext onDragEnd={result => onDragEnd(result, row.id)}>
          <Droppable droppableId={`droppable-${row.id}`}>
            {provided => (
              <div
                className={`row-expansion-template ${condensed && 'condensed'}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {row.channels.map((channel: TransactionChannel, index: number) => (
                  <Draggable
                    key={`discussion-${index}`}
                    draggableId={`discussion-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tr
                        className={`draggable-item ${snapshot.isDragging ? 'dragging' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        onContextMenu={handleRowContextMenu}
                      >
                        <td>
                          <span
                            {...provided.dragHandleProps}
                            className="drag-drop-icon"
                          >
                            <Icon icon={Icons.Bars} />
                          </span>
                          <span>
                            <Checkbox
                              className="inner-row-checkbox"
                              // @ts-ignore
                              checked={selectedRows.includes(channel.id)}
                              onChange={(e, data) => handleCheckboxChange(data.checked as boolean, channel.id)}
                            />
                          </span>
                        </td>
                        <td>
                          <span
                            className={condensed ? `discussion-name` : ''}
                            onClick={() => openDiscussion(channel.id, channel.node?.id)}
                          >
                            {channel.title}
                          </span>
                        </td>
                        <td>
                          <span>{ClausesPop(channel.clauseNumber, channel.id, index)}</span>
                        </td>
                        {!condensed && (
                          <>
                            <td>
                              <span>{CommentsPopUp(channel.messages, channel.id, index)}</span>
                            </td>
                            <td>
                              <span>{messageAnswer?.transactionDiscussionApproval?.draftMessage}</span>
                            </td>
                          </>
                        )}
                        {approvalChanges && (
                          <td>
                            <a onClick={() => openParameterChanges(messageAnswers?.[0], channel)}>View Details</a>
                            {parameterChangesStatus(messageAnswers?.[0])}
                          </td>
                        )}
                        <td>{filterElementStatusTemplate(channel.status, statuses)}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  };

  const onRowToggle = (e: any) => {
    setExpandedRows(e.data);
  };

  return (
    <>
      <TableReactPrime
        fetchContent={({ ...rest }) => {}}
        isFetching={false}
        content={data}
        columnConfig={columnConfig}
        showReorderingToggler={true}
        allowDragDrop={true}
        onRowReorder={onRowReorder}
        rowExpansion={true}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={onRowToggle}
      />
      <ParameterChanges
        isOpen={isModelOpen}
        onModalStateUpdate={setIsModelOpen}
        messageAnswer={messageAnswer}
      />
    </>
  );
};

export default DiscussionsTable;
