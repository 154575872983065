import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface SendTenantCommentsMutationVariables {
  transactionId: string | null | undefined;
  documentTypeId: string;
}

export const SEND_TENANT_COMMENTS = (transactionId: string | null | undefined, documentTypeId: string) => {
  if (!transactionId) throw new Error('Send Tenant Comments: transactionId is required');

  const variables: SendTenantCommentsMutationVariables = {
    transactionId,
    documentTypeId,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $documentTypeId: ID!) {
        sendTenantComments(transactionId: $transactionId, documentTypeId: $documentTypeId) {
          result
        }
      }
    `,
    variables,
  };
};

export const sendTenantComments = createAsyncThunk(
  'transactions/sendTenantComments',
  async ({ documentTypeId }: { documentTypeId: string }, { getState }) => {
    const {
      transactionDetail: {
        activeTransaction: { id: transactionId },
      },
    } = getState() as RootState;

    const response = await client.mutate(SEND_TENANT_COMMENTS(transactionId, documentTypeId));
    return response;
  },
);
