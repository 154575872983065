import { CValuationReport } from 'common/_classes/valuationReport2';
import { Popup } from 'semantic-ui-react';
import { filterBooleanElementBody } from 'atoms/TableReactPrime/columnTemplates';
import { LANDLORD_APPROVAL_TYPE_OPTIONS, RIGHT_RESTRICTION_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';

export const subletRightsBody = (item: CValuationReport): JSX.Element => {
  const {
    data: {
      specialRights: { subletFlag, subletRights },
    },
  } = item;

  return (
    <Popup
      hoverable={true}
      position="top left"
      trigger={<div>{filterBooleanElementBody(subletFlag)}</div>}
      content={
        subletRights ? (
          (() => {
            const exercisability = WHOLE_OR_PART_OPTIONS.find(
              option => option.value === subletRights.wholeOrPart,
            )?.text;

            const restrictedTo = RIGHT_RESTRICTION_OPTIONS.find(
              option => option.value === subletRights.restrictedTo,
            )?.text;

            const landlordApproval = LANDLORD_APPROVAL_TYPE_OPTIONS.find(
              option => option.value === subletRights.landlordApproval,
            )?.text;

            const premisePercentage = subletRights.premisePercentage ? subletRights.premisePercentage.toString() : null;

            const landlordProfitShare = subletRights.landlordProfitShare
              ? subletRights.landlordProfitShare.toString()
              : null;

            return (
              <div className="d-flex-column flex-gap-s">
                {exercisability ? (
                  <span>
                    Exercisable in: <strong>{exercisability}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {premisePercentage ? (
                  <span>
                    Premise percentage sublettable: <strong>{premisePercentage}%</strong>
                  </span>
                ) : (
                  <></>
                )}

                {restrictedTo ? (
                  <span>
                    Restricted to: <strong>{restrictedTo}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {landlordApproval ? (
                  <span>
                    Landlord approval: <strong>{landlordApproval}</strong>
                  </span>
                ) : (
                  <></>
                )}

                {landlordProfitShare ? (
                  <span>
                    Profit to landlord: <strong>{landlordProfitShare}%</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            );
          })()
        ) : (
          <></>
        )
      }
      disabled={!subletFlag}
    />
  );
};
