import { store } from 'store';
import { Editor } from 'tinymce';
import { ToolbarButton } from 'components/ContextToolbar';
import computeAllIndexes from 'components/Editor/Clause/ComputeIndex';
import { updateActiveDocumentContent } from 'store/provisions/provisionDetailSlice';
import { ContentNodeType, deleteNode } from 'common/api/nodes';
import { updateProvisionContent } from 'common/api/provisions';
import { editorContextMenuForceClose } from 'utils/tsHelper';

export const contextToolbarForceClose = () => {
  const menuQuery = '.tox-pop';
  const dialog = document.querySelector(menuQuery) as HTMLElement;
  if (dialog) {
    dialog.style.display = 'none';
  }
};

/**
 * Return the parent element from current element
 */
const getClauseFromIndex = (span: Element) => {
  // Return parent
  return span.parentElement;
};

/**
 * Return the parent element from parent element from current element
 *
 * Return null if it does not exist
 */
const getOuterClause = (clause: Element) => {
  // Return parent of parent
  return clause.parentNode;
};

/**
 * Check if element exists, going one level up and insert the element
 * before the current element
 */
const insertAfter = (newNode: Node, existingNode: Node) => {
  try {
    // Try to insert before the parent element
    existingNode?.parentNode?.insertBefore(newNode, existingNode.nextSibling);
  } catch (e) {
    return;
  }
};

/**
 * [deprecated] Old delete Clause function
 */
const deleteClause = (editor: Editor, clause: Element) => {
  // Get current element
  const spanTag = editor.selection.getNode();

  // Get parent of current element (probably the clause)
  const contentDiv = spanTag.parentElement!;

  // Get ID from the clause
  const nodeId = contentDiv.getAttribute('data-node-id') || '';

  // Try to get the title element tag from the Clause
  const nodeTitle = clause.querySelector('[data-title]');

  // Remove the current focused element tag
  editor.dom.remove(spanTag);

  // Remvoe the node Title from the Clause
  if (nodeTitle) editor.dom.remove(nodeTitle);

  // Get and salve the Clause content
  const text = contentDiv.textContent!;

  // Remove the previous clause content
  editor.dom.remove(contentDiv);

  // Insert only the inner content from the Clause
  editor.insertContent(text);

  // Trigger redux action to remove the Node on the API
  store.dispatch(
    deleteNode({
      id: nodeId,
    }),
  );
};

/**
 * Update the Redux Store with the Editor content and Save it on the API
 */
export const updateAndSaveEditorContent = (editor: Editor) => {
  // Get the value from Editor init config
  const documentTypeId = editor.getParam('id');
  // Check if ID exists
  if (!documentTypeId) {
    console.warn('Trying to save without an ID');
    return;
  }

  // Update the content on the Redux Store
  store.dispatch(updateActiveDocumentContent({ editor, documentTypeId }));

  setTimeout(() => {
    // Save the content on the API
    store.dispatch(updateProvisionContent({ documentTypeId }));
  }, 200);
};

const addClauseTitle = {
  id: 'insertTitle',
  text: 'Add clause title',
  icon: 'clauseTitle',
  action: (editor: Editor) => {
    // get node from current click
    const node = editor.selection.getNode();

    // try to get the clause element as 'parent'
    const parent = node.parentNode;

    // avoid working with undefined object
    if (!parent) return;

    // get all elements from the clause
    let children = Array.from(parent.children) as Element[];

    // get the index number inside of clause, if it exists
    const indexNode = children.find(html => html.getAttribute('data-index'));

    const props = { 'data-title': 'true' };
    const titleHtml = editor.dom.createHTML('div', props, 'New Title');

    if (!indexNode) return;
    // insert the node as a sibling of the current node;
    indexNode.insertAdjacentHTML('afterend', titleHtml);
    children = Array.from(parent.children) as Element[];

    // to ensure the clause has the data title now
    const titleInserted = children.find(html => html.getAttribute('data-title'));

    if (!titleInserted) return;

    // do a mouse selection and ready to be edited
    editor.selection.select(titleInserted, true);
    editor.focus();

    // Update redux store and save the content on the API
    updateAndSaveEditorContent(editor);

    editorContextMenuForceClose();
    contextToolbarForceClose();
  },
};

const removeClauseTitle = {
  id: 'removeTitle',
  text: 'Remove clause title',
  icon: 'clauseTitle',
  action: (editor: Editor) => {
    // Current clicked/focused node
    const node = editor.selection.getNode();

    // Get the parent. Goes one level above
    const parent = node.parentNode;

    // Check if exist
    if (!parent) return;

    // get all children of the parent for finding the title
    const children = Array.from(parent.children) as Element[];

    // Finding title node
    const title = children.find(html => html.getAttribute('data-title'));

    // If found, then remove the title
    if (title) {
      title.remove();
    }

    contextToolbarForceClose();
  },
};

export const hierarchyUp = (editor: Editor) => {
  const node = editor.selection.getNode() as any;

  const clause = getClauseFromIndex(node);

  if (clause?.getAttribute('data-node-type') !== ContentNodeType.CLAUSE) {
    return false;
  }

  const outerClause = getOuterClause(clause!);

  if (outerClause?.nodeName.toLocaleLowerCase() === 'body') {
    return;
  }

  insertAfter(clause!, outerClause!);
  const level = clause?.getAttribute('data-level');
  const updatedLevel = String(Number(level) - 1);

  if (updatedLevel && updatedLevel > '0') {
    clause?.setAttribute('data-level', updatedLevel);
    contextToolbarForceClose();
  }
};

export const hierarchyDown = (editor: Editor) => {
  const node = editor.selection.getNode() as any;

  const clause = getClauseFromIndex(node);

  if (clause?.getAttribute('data-node-type') !== ContentNodeType.CLAUSE) {
    return false;
  }

  const upperInnerChild = clause?.previousSibling?.lastChild;

  insertAfter(clause!, upperInnerChild!);
  const level = clause?.getAttribute('data-level');

  if (level) {
    clause?.setAttribute('data-level', String(Number(level) + 1));
    contextToolbarForceClose();
  }
};

const toolbarButtons: ToolbarButton[] = [
  {
    id: 'deleteClause',
    text: 'Delete',
    icon: 'trash',
    action: editor => {
      // Current clicked/focused node
      const node = editor.selection.getNode();

      // Just rename the object to be used on loop
      let parentNode: Element | null = node;
      // Check if the current node's parent is the clause

      /**
       * The method if the node is
       * * Outside of a Table
       * * Outside of a List
       * * Outside of a Clause
       */
      const checkIfNotClause = (parent: typeof node) => {
        // Check if current parent is not a Clause or Parameter
        const currentType = parent.getAttribute('data-node-type');
        if (!currentType) return true;

        // Check if the current type is not a Clause
        return currentType !== ContentNodeType.CLAUSE;
      };

      // Goes up every HTML level until it finds a clause
      while (checkIfNotClause(parentNode as typeof node)) {
        parentNode = parentNode.parentNode as typeof node;
        // Its the case when no clause has been found
        if (parentNode.tagName === 'BODY') {
          parentNode = null;
          // Remove the corrupted clause
          node.remove();
          break;
        }
      }

      // Check if clause parent found
      if (parentNode) {
        // Remove the fixed tags from Clause (Index and Title)
        parentNode.querySelector('div[data-index]')?.remove();
        parentNode.querySelector('div[data-title]')?.remove();

        // Copy the Clause inner content
        const innerHTML = parentNode.innerHTML;

        // Force Editor to do a selection to entire 'parentNode'
        editor.selection.select(parentNode as Node);
        // Set clause inner content
        parentNode.outerHTML = innerHTML;
      }

      // Update redux store and save the content on the API
      updateAndSaveEditorContent(editor);
      contextToolbarForceClose();
    },
  },
  {
    // TODO: Unused at the moment
    id: 'hierUp',
    text: 'Hierarchy Up',
    icon: 'leftArrow',
    action: editor => {
      hierarchyUp(editor);
    },
  },
  {
    // TODO: Unused at the moment
    id: 'hierDown',
    text: 'Hierarchy Down',
    icon: 'rightArrow',
    action: editor => {
      hierarchyDown(editor);
    },
  },
  {
    id: 'insertTextBefore',
    text: 'Insert text before clause',
    icon: 'pullUp',
    action: editor => {
      // Current clicked/focused node
      const node = editor.selection.getNode();
      // Returns a node list of all parents matching the specified selector
      const [, div1] = editor.dom.getParents(node) as any; // Gets second element
      // REFERENCE: https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentHTML#parameters
      // Insert the new element tag as a sibling
      div1.insertAdjacentHTML('beforebegin', '<p>Enter text here...</p>');
      // Update redux store and save the content on the API
      updateAndSaveEditorContent(editor);
      setTimeout(() => {
        contextToolbarForceClose();
      }, 100);
    },
  },
  {
    id: 'insertTextAfter',
    text: 'Insert text after clause',
    icon: 'pullDown',
    action: editor => {
      // Current clicked/focused node
      const node = editor.selection.getNode();
      // Returns a node list of all parents matching the specified selector
      const [, div1] = editor.dom.getParents(node) as any;
      // REFERENCE: https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentHTML#parameters
      // Insert the new element tag as a sibling
      div1.insertAdjacentHTML('afterend', '<p>Enter text here...</p>');

      // Update redux store and save the content on the API
      updateAndSaveEditorContent(editor);

      setTimeout(() => {
        contextToolbarForceClose();
      }, 100);
    },
  },
];

/** Function to build context-menu based on the defined array of ToolbarButtons
 * @param {Editor} editor
 */
const ContextClauseToolbar = (editor: Editor) => {
  // List of options for the Context Clause
  const contextButtons = [addClauseTitle, removeClauseTitle, ...toolbarButtons];

  // Register every option. The option SHOULD be registered before used.
  for (const button of contextButtons) {
    // Add a button passing each object's attributes as parameters
    editor.ui.registry.addButton(button.id, {
      text: button.text,
      icon: button.icon,
      onAction: () => {
        button.action(editor);
        computeAllIndexes(editor);
      },
    });
  }

  // Map over each button and take it's id.
  // id | id | id
  const buttonsWithAdd: string = ['']
    .concat(contextButtons.map(btn => btn.id).filter(id => id !== 'removeTitle'))
    .join(' | ');
  const buttonsWithRemove: string = ['']
    .concat(contextButtons.map(btn => btn.id).filter(id => id !== 'insertTitle'))
    .join(' | ');

  // Context with Add Context
  editor.ui.registry.addContextToolbar('clauseIndexToolbar1', {
    /* Predicate returns a boolean
    Toolbar is shown accordingly */
    predicate: function (node) {
      const editorShortcut = store.getState().editor.editorShortcut;

      if (editorShortcut) {
        return false;
      }

      const nodeType = node.getAttribute('data-node-type');

      if (!nodeType) return false;

      const hasTitle = (node.parentNode as any).innerHTML.indexOf('data-title') > -1;

      // Open only in the clause numbering
      return nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX) && hasTitle === false;
    },
    items: buttonsWithAdd,
    position: 'selection',
    scope: 'node',
  });

  // Context with the Remove Title
  editor.ui.registry.addContextToolbar('clauseIndexToolbar2', {
    /* TODO: Create a reusable function */
    predicate: node => {
      const editorShortcut = store.getState().editor.editorShortcut;
      if (editorShortcut) {
        return false;
      }

      const nodeType = node.getAttribute('data-node-type');

      if (!nodeType) return false;

      const hasTitle = (node.parentNode as any).innerHTML.indexOf('data-title') > -1;

      // Open only in the clause numbering
      return nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX) && hasTitle;
    },
    items: buttonsWithRemove,
    position: 'selection',
    scope: 'node',
  });
};

export default ContextClauseToolbar;
