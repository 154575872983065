import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import { AttachedUsersListingType } from 'utils/types/userManagement/adminEntities';
import { getPhoneNumber } from 'utils/utils-string';
import './AttachedUsersListing.scss';

const AttachedUsersListing = ({ id }: AttachedUsersListingType) => {
  const members = useAppSelector((state: RootState) => {
    const entitiesList = state.adminEntities.entitiesList;

    const entity = entitiesList.find(({ id: memberId }) => memberId === id);

    const members = entity?.members || [];

    return members;
  });

  return (
    <ul className="listing pm-none">
      {members.map(
        ({
          user: { id, firstName, lastName, photoUrl, email, countryCode, phoneNumber },
          role: { name},
        }) => (
          <li
            className="item"
            key={id}
          >
            <div className="top">
              <Avatar
                src={photoUrl || ''}
                size={AvatarSize.Small}
              />
              <span className="name">{`${firstName} ${lastName}`}</span>
              <span className="role m-r-xs m-l-xs">{`(${name})`}</span>
            </div>
            <div className="middle">{`Email: ${email}`}</div>
            <div className="bottom">{`Phone: ${getPhoneNumber(countryCode, phoneNumber)}`}</div>
          </li>
        ),
      )}
    </ul>
  );
};

export default AttachedUsersListing;
