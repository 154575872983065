import DropdownSelect from 'atoms/DropdownSelect';
import InputField from 'atoms/FormField/Input';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  FilterMatchType,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowConfig/RowProps';

export const STRING_FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Text contains', value: FilterMode.CONTAINS },
  { key: 2, text: 'Text does not contain', value: FilterMode.DOES_NOT_CONTAIN },
];

export const filterWhile = (
  value: any,
  rowAction: RowAction,
  compare: (value: any, filterValue: any, filterMode: FilterMode) => boolean,
): boolean => {
  const { filterMatchType = FilterMatchType.ALL, filterConditions = [] } = rowAction;

  if (filterMatchType === FilterMatchType.ALL) {
    // Return false as soon as any condition fails
    return filterConditions.every(({ filterMode, filterValue }) => compare(value, filterValue, filterMode!));
  }

  if (filterMatchType === FilterMatchType.ANY) {
    // Return true as soon as any condition passes
    return filterConditions.some(({ filterMode, filterValue }) => compare(value, filterValue, filterMode!));
  }

  // Default return value if the filterMatchType is not recognized
  return false;
};

const compare = (value: string, filterValue: string, filterMode: FilterMode) => {
  const trimmedValue = value?.toLowerCase().trim();
  const trimmedFilterValue = filterValue?.toLowerCase().trim();

  switch (filterMode) {
    case FilterMode.CONTAINS:
      return trimmedValue.includes(trimmedFilterValue);

    case FilterMode.DOES_NOT_CONTAIN:
      return !trimmedValue.includes(trimmedFilterValue);
  }

  return true;
};

export function applyStringFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyStringFilter: field key is missing');

  let value: string = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  return filterWhile(value, rowAction, compare);
}

const StringFilter = ({ onChange, filterItem }: FilterItemChildProps): JSX.Element => {
  return (
    <>
      <DropdownSelect
        placeholder="Select..."
        fieldKey={FilterOnchangeKeys.filterMode}
        value={filterItem.filterMode}
        options={STRING_FILTER_OPTIONS}
        onChange={onChange}
      />

      <InputField
        value={filterItem.filterValue}
        placeholder={'Enter text...'}
        onChange={onChange}
        fieldKey={FilterOnchangeKeys.filterValue}
      />
    </>
  );
};

export default StringFilter;
