import { Button } from 'semantic-ui-react';
import {
  FilterMatchType,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
  initFilterItem,
} from 'components/ComparablesTable/RowConfig/RowProps';
import { deepClone } from 'utils/tsHelper';
import { FilterPopupProps } from '..';
import FlexibleFormItem from './FlexibleFormItem';

export enum FilterFormLayout {
  Column = 'column-layout',
  Row = 'row-layout',
}

const FilterForm = ({ setFilterData, filterData, onFilter }: FilterPopupProps) => {
  const onClear = (): void => {
    const updatedFilterData = deepClone(filterData);
    if (updatedFilterData[filterData.activeConfigId!].defaultFilter) {
      // updatedFilterData[filterData.activeConfigId!] = {
      //   ...updatedFilterData[filterData.activeConfigId!],
      //   filterValue: undefined,
      //   filterMode: undefined,
      //   filterLogicalOperator: undefined,
      //   filterValue2: undefined,
      //   filterMode2: undefined,
      //   filterActive: false,
      // };
    } else if (!updatedFilterData[filterData.activeConfigId!].sortOrder) {
      delete updatedFilterData[filterData.activeConfigId!];
    } else {
      const data = updatedFilterData[filterData.activeConfigId!];

      updatedFilterData[filterData.activeConfigId!] = {
        id: data.id,
        sortOrder: data.sortOrder,
        label: data.label,
      };
    }
    updatedFilterData.activeConfigId = null;

    setFilterData(updatedFilterData);
    onFilter(updatedFilterData);
  };

  /**
   * onApplyRowFilters
   */
  const onApply = (): void => {
    const updatedFilterData = deepClone(filterData);
    // updatedFilterData[rowFilter.id].filterActive = true;
    setFilterData(updatedFilterData);

    onFilter(updatedFilterData);
  };

  const onChange =
    (index: number, rowFilter: RowAction & RowProps) => (key: string, value: any, updateRoot?: boolean) => {
      const rowFilterClone = deepClone(rowFilter);
      const updatedFilterItem = rowFilter.filterConditions![index];

      if (updateRoot) {
        throw new Error('HERE !!');
        // updatedRowFilter = { ...updatedRowFilter, ...value };
      } else {
        updatedFilterItem[key as FilterOnchangeKeys] = value;

        rowFilterClone.filterConditions![index] = updatedFilterItem;
      }

      const updatedFilterData = deepClone(filterData);
      updatedFilterData[rowFilter.id] = rowFilterClone;

      setFilterData(updatedFilterData);
    };

  const onAddFilterItem = (rowFilter: RowAction & RowProps) => {
    const filterDataClone = deepClone(filterData);
    const filterConditions = filterDataClone[rowFilter.id].filterConditions!;

    filterDataClone[rowFilter.id].filterConditions = [...filterConditions, initFilterItem(rowFilter.dataType)];

    setFilterData(filterDataClone);
  };

  const onDeleteFilterItem = (index: number, rowFilter: RowAction & RowProps) => {
    const filterDataClone = deepClone(filterData);
    const filterConditions = filterDataClone[rowFilter.id].filterConditions!.filter(
      (_item, itemIndex) => itemIndex !== index,
    );

    filterDataClone[rowFilter.id!].filterConditions = filterConditions;

    setFilterData(filterDataClone);
  };

  const onFilterTypeChange = (rowFilter: RowAction & RowProps) => (_key: string, value: string) => {
    const filterDataClone = deepClone(filterData);

    filterDataClone[rowFilter.id].filterMatchType = value as FilterMatchType;

    setFilterData(filterDataClone);
  };

  const getFilterDataAndLayout = () => {
    const { defaultFilterIds, activeConfigId } = filterData;
    if (activeConfigId !== null && activeConfigId !== undefined)
      return { data: [filterData[activeConfigId]], layout: FilterFormLayout.Column };

    return { data: defaultFilterIds.map(id => filterData[id]), layout: FilterFormLayout.Row };
  };
  const { data, layout } = getFilterDataAndLayout();

  return (
    <div className={`comparables-table-filter-popup ${layout}`}>
      {data.map(data => (
        <FlexibleFormItem
          layout={layout}
          rowFilter={data}
          onFilterTypeChange={onFilterTypeChange}
          onChange={onChange}
          onDeleteFilterItem={onDeleteFilterItem}
          onAddFilterItem={onAddFilterItem}
        />
      ))}

      <div className="comparables-table-buttons-row p-sm p-t-none">
        <Button onClick={onClear}>CLEAR</Button>
        <Button
          onClick={onApply}
          // disabled={disableApplyButton(rowFilter)}
        >
          APPLY
        </Button>
      </div>
    </div>
  );
};

export default FilterForm;
