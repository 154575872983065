/*
 * Definition of the column (names, format...)
 */
import { PermissionRow } from 'common/_classes/auth/adminPermissions';
import moment from 'moment';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementDate } from 'atoms/TableReactPrime/columnTemplates';
import { PermissionRowActionButtons } from './PermissionRowActionButtons';

// import Permission from 'common/model/Permission';

export const adminPermissionsColumnConfig = ({
  onViewDetails,
}: {
  onViewDetails: (id: string) => void;
}): CustomColumnProps[] => {
  return [
    {
      field: 'resource',
      header: 'Resource',
      filter: true,
      filterPlaceholder: 'Search by Resource',
      filterField: 'resource',
      sortable: true,
    },
    {
      field: 'action',
      header: 'Action',
      filter: true,
      filterPlaceholder: 'Search by Action',
      filterField: 'action',
      sortable: true,
    },

    {
      field: 'insertedAt',
      header: 'Inserted At',
      filter: true,
      filterPlaceholder: 'Search by Inserted At',
      filterField: 'insertedAt',
      sortable: true,
      body: (row: PermissionRow) => {
        return moment(row.updatedAt).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },

    {
      field: 'updatedAt',
      header: 'Updated At',
      filter: true,
      filterPlaceholder: 'Search by Updated At',
      filterField: 'updatedAt',
      sortable: true,
      body: (row: PermissionRow) => {
        return moment(row.updatedAt).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },

    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: PermissionRow) => (
        <PermissionRowActionButtons
          rowId={row.id!}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
