import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Permission from 'common/model/Permission';
import { createPermission } from 'common/api/auth/createPermission';
import { deletePermission } from 'common/api/auth/deletePermission';
import { listPermissions } from 'common/api/auth/listPermissions';
import { updatePermission } from 'common/api/auth/updatePermission';

// import models here

const initialState: {
  search: string | null;
  permissionsList: Permission[];
  isLoading: boolean;
} = {
  search: null,
  permissionsList: [],
  isLoading: false,
};

const adminListPermissions = createSlice({
  name: 'adminListPermissions',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    //add listPermissions reducers here

    builder.addCase(listPermissions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.permissionsList = action.payload.data.listPermissions.edges.map((edge: any) => edge.node);
    });

    builder.addCase(deletePermission.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deletePermission.fulfilled, (state, action) => {
      state.isLoading = false;
      state.permissionsList = state.permissionsList.filter(
        permission => permission.id !== action.payload.data.deletePermission.id,
      );
      toast.success('Permission deleted successfully');
    });
    builder.addCase(deletePermission.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
      toast.error('Something went wrong trying to delete permission');
    });
    builder.addCase(createPermission.fulfilled, (state, action) => {
      state.permissionsList.push(action.payload.data.createPermission);
    });
    builder.addCase(updatePermission.fulfilled, (state, action) => {
      state.permissionsList = state.permissionsList.map(permission =>
        permission.id === action.payload.data.updatePermission.id ? action.payload.data.updatePermission : permission,
      );
    });
  },
});

export const { updateSearch } = adminListPermissions.actions;

export default adminListPermissions.reducer;
