import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterSubGroupDetailClone } from 'common/_classes/ParameterSubGroupDetailClone';
import { RootState } from 'store';

interface CreateParamsSubGroupParams {
  name: string;
  index: number;
  parameterGroupId: string;
}

interface CreateParamsSubGroupQueryVariables {
  params: CreateParamsSubGroupParams;
}

const CREATE_PARAMETER_SUB_GROUP = (activeSubGroup: ParameterSubGroupDetailClone) => {
  const { name, index, parameterGroup } = activeSubGroup;

  if (!name) throw new Error('Create Parameter Sub Group: name is required');
  if (!index) throw new Error('Create Parameter Sub Group: index is required');
  if (!parameterGroup || !parameterGroup.id)
    throw new Error('Create Parameter Sub Group: parameter group id is required');

  const params: CreateParamsSubGroupParams = {
    name,
    index,
    parameterGroupId: parameterGroup.id,
  };

  const variables: CreateParamsSubGroupQueryVariables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: ParameterSubGroupInput!) {
        createParameterSubGroup(params: $params) {
          ${ParameterSubGroupDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createParameterSubGroup = createAsyncThunk(
  'parameters/createParameterSubGroup',
  async (_args, { getState }) => {
    const {
      parametersListing: { activeSubGroup },
    }: any = getState() as RootState;
    const response = await adminClient.mutate(CREATE_PARAMETER_SUB_GROUP(activeSubGroup));
    return response;
  },
);
