import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { setIsModalOpen, setPermission } from 'store/adminAuth/permissionDetails';
import Permission from 'common/model/Permission';
import PermissionInput from 'common/model/PermissionInput';
import { createPermission } from 'common/api/auth/createPermission';
import { updatePermission } from 'common/api/auth/updatePermission';
import PermissionModalForm from './PermissionModalForm';

const PermissionModal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpen, permission } = useAppSelector((state: RootState) => state.adminPermissionDetails);

  const toggleModal = (value: boolean): void => {
    dispatch(setIsModalOpen(value));
    if (value) {
      dispatch(setPermission({}));
    } else {
      dispatch(setPermission(null));
    }
  };

  const onSave = () => {
    if (permission?.id) {
      dispatch(
        updatePermission({
          params: permission as Permission,
        }),
      );
    } else {
      dispatch(createPermission({ params: permission as PermissionInput }));
    }
  };

  return (
    <ModalTemplate
      isOpen={isModalOpen}
      onToggleModalStatus={toggleModal}
      trigger={<Button className="btn grey-bg">ADD NEW PERMISSION</Button>}
      title={`${permission?.id ? 'Edit Permission Details' : 'Add New Permission'}`}
    >
      <PermissionModalForm onSave={onSave} />
    </ModalTemplate>
  );
};

export default PermissionModal;
