import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export interface ContextAndDocType {
  context: string;
  contextId: string | undefined;
  documentTypeId: string | null;
  documentName?: string;
}

interface GetPdfHtmlQueryVariables extends ContextAndDocType {
  landlordId: string | null;
  final: boolean;
}

export const GET_PDF_HTML = ({ context, contextId, documentTypeId }: ContextAndDocType) => {
  if (!contextId) throw new Error('Get Pdf Html: Context ID is missing');

  const variables: GetPdfHtmlQueryVariables = {
    context,
    contextId,
    landlordId: getCurrentLandlordEntityId(),
    documentTypeId,
    final: false,
  };

  return {
    query: gql`
      query ($context: ExecuteContext!, $contextId: ID!, $landlordId: ID, $documentTypeId: ID!, $final: Boolean!) {
        getPdfHtml(
          context: $context
          contextId: $contextId
          landlordId: $landlordId
          documentTypeId: $documentTypeId
          final: $final
        )
      }
    `,
    variables,
  };
};

export const getPdfHtml = createAsyncThunk(
  'miscellaneous/getPdfHtml',
  async ({ context, contextId, documentTypeId }: ContextAndDocType, { getState }) => {
    const {
      miscellaneous: { activeDocType },
    } = getState() as RootState;
    const docId = documentTypeId ? documentTypeId : activeDocType?.id || null;
    const response = await client.query(GET_PDF_HTML({ context, contextId, documentTypeId: docId }));
    return response;
  },
);
