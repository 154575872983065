import { ParameterChoiceBase } from 'common/_classes';
import { Fragment } from 'react';
import { Checkbox, Form, Icon } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { difference } from 'lodash';
import Badge, { BadgeColor } from 'atoms/Badge';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonMultiChoice, initAnswerValue } from 'common/api/formatters/types';
import { Choice } from '../SingleChoice';
import './MultiChoice.scss';

interface MultiChoiceProps {
  value: AnswerJsonMultiChoice;
  choices?: ParameterChoiceBase[];
  mandatory?: boolean;
  onChange: (value: Choice) => void;
  tabIndex: number | null;
}

const MultiChoice = ({ value, choices = [], mandatory, onChange, tabIndex }: MultiChoiceProps): JSX.Element => {
  const choiceIds: string[] = choices.map((choice: ParameterChoiceBase) => choice.id);
  const initValue: AnswerJsonMultiChoice = initAnswerValue(AnswerTypes.MultiChoice, choiceIds);

  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const updateAnswerValue = (answerValue: Choice): void => {
    onChange(answerValue);
  };

  const onChangeHandlerClear = (): void => {
    updateAnswerValue({
      values: null,
      inverses: null,
    });
  };

  // If "none" was previously ON and user click on "none": it unselect it, this is equivalent to a clear
  // Else, this is equivalent to actually define it as "none"
  const onChangeHandlerNone = (): void => {
    let answerValue: Choice;
    if (value.values?.length === 0) {
      answerValue = {
        values: null,
        inverses: null,
      };
    } else {
      answerValue = initValue;
    }
    updateAnswerValue(answerValue);
  };

  const onChangeHandlerId = (choice_id: string): void => {
    let answerValue: Choice;

    let valueList: string[] = value.values ? [...value.values] : [];

    // If id is already in the list, => remove it using a filter method
    // else => add it
    if (valueList.includes(choice_id)) {
      valueList = valueList.filter((item: string) => item !== choice_id);
    } else {
      valueList.push(choice_id);
    }

    // Remove values from earlier options version
    let cleanValues: string[] = choiceIds.filter((choiceId: string) => valueList.includes(choiceId));

    if (mandatory && cleanValues.length === 0) {
      // in 'mandatory' behavior, in case an option must be selected, if none are selected
      // this is equivalent to a clear =? the values and inverses must be null.
      updateAnswerValue({
        values: null,
        inverses: null,
      });
    } else {
      // New answer version
      answerValue = {
        values: cleanValues,
        inverses: difference(choiceIds, cleanValues),
      };

      updateAnswerValue(answerValue);
    }
  };

  /**
   * Check if none is set in multi-choice
   * @returns
   */
  const checkIfNoneIsSet = (): boolean => {
    if (value && value.values && value.inverses) {
      return value.values.length === 0 && value.inverses.length !== 0;
    } else {
      return false;
    }
  };

  return (
    <Form>
      <div className="mcq-choices">
        <Form.Group
          grouped
          className="mcq-choices-left"
        >
          {choices?.map(({ text, id, archive }) => (
            <Fragment key={id}>
              {/* TODO Temporary If check */}
              {!archive && (
                <>
                  <Checkbox
                    label={text}
                    onChange={() => onChangeHandlerId(id)}
                    // @ts-ignore
                    checked={value.values ? value.values.includes(id) : false}
                    value={id}
                    disabled={archive}
                  />

                  {archive && (
                    <Badge
                      badgeColor={BadgeColor.RED}
                      className="m-l-xs"
                    >
                      Archived
                    </Badge>
                  )}
                  <br />
                </>
              )}
            </Fragment>
          ))}
        </Form.Group>
        <Fragment key={userId}>
          {mandatory ? (
            <div>(Select at least one choice)</div>
          ) : (
            <Checkbox
              label="None"
              onChange={() => onChangeHandlerNone()}
              // @ts-ignore
              checked={checkIfNoneIsSet()}
            />
          )}
        </Fragment>
      </div>
      <span
        onClick={() => onChangeHandlerClear()}
        className="parameters-questions-clear-data mcq"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default MultiChoice;
