import OperatingStatus from 'common/model/OperatingStatus';
import OrganisationType from 'common/model/OrganisationType';

export interface IOrganisationDetailClone {
  id?: string | null;
  name: string | null;
  operatingStatus: OperatingStatus | null;
  orgKey: string | null;
  index: number | null;
  logo: string | null;
  archived: boolean;
  type: OrganisationType | null;
  parentId: string | null;
}

export class OrganisationDetailClone implements IOrganisationDetailClone {
  id?: string | null;
  name: string | null;
  operatingStatus: OperatingStatus | null;
  orgKey: string | null;
  index: number | null;
  logo: string | null;
  archived: boolean;
  type: OrganisationType | null;
  parentId: string | null;

  constructor(input: IOrganisationDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.operatingStatus = input.operatingStatus;
    this.orgKey = input.orgKey;
    this.logo = input.logo;
    this.index = input.index;
    this.archived = input.archived;
    this.type = input.type;
    this.parentId = input.parentId;
  }

  static fragment(): string {
    return `
      id
      name
      operatingStatus
      orgKey
      logo
      archived
      index
      type
      parentId
      members {
        id
      }
      entities {
        id
        name
      }
      roles{
        id
        name
      }
    `;
  }
}
