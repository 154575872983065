import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { resetDocxData } from 'store/transactions/transactionDetailSlice';
import { sendTenantComments } from 'common/api/transactions/sendTenantComments';
import DiscussionsTable from './DiscussionTable';
import './DiscussionsModal.scss';

const DiscussionsModal = ({ documentTypeId }: { documentTypeId: string }) => {
  const { discussionGroups } = useSelector((state: RootState) => state.transactionDetail);

  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (discussionGroups !== null) {
      setOpen(true);
    }
  }, [discussionGroups]);

  const onModalStateUpdate = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const onSave = () => {
    dispatch(sendTenantComments({ documentTypeId }));
    dispatch(resetDocxData());
  };

  return (
    <ModalTemplate
      isOpen={open}
      onToggleModalStatus={onModalStateUpdate}
      className="discussions-modal"
      title="Discussions"
      showHeader={true}
      exitBtnTitle="Save & Exit"
      onModalClose={onSave}
    >
      <Grid className="pm-none">
        <Grid.Row className="p-none m-b-m">
          <div>
            <p className="m-b-none">
              <b>Drag & drop</b> to prioritise subject matters and discussions, or to move discussions to another
              subject matter
            </p>
            <p className="m-b-none">
              <b>Collapse & expand</b> subject matters to show or hide discussion topics
            </p>
            <p className="m-b-none">
              <b>Select multiple discussions & merge</b> related edits/comments under the same topic
            </p>
          </div>
        </Grid.Row>
        <Grid.Row className="p-none">
          <Grid.Column
            width={16}
            className="p-l-none"
          >
            <DiscussionsTable documentTypeId={documentTypeId} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ModalTemplate>
  );
};

export default DiscussionsModal;
