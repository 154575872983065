import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { MemberClone } from 'common/api/members';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

const UserProfile = () => {
  const { firstName, lastName, members } = useAppSelector((state: RootState) => state.auth);
  const selectedEntity = getCurrentLandlordEntityId();
  let entityName;

  let name = `${firstName} ${lastName}`;
  if (name.length > 17) {
    name = firstName;
  }

  if (members.length !== 0) {
    const memberList: MemberClone[] = members;
    const getIndex = memberList.findIndex(obj => obj?.entity?.id === selectedEntity);
    if (getIndex !== -1) {
      entityName = memberList[getIndex].entity.name;
    }
  }

  const shortenEntityName = (nameEntity?: string) => {
    if (nameEntity && nameEntity.length > 20) {
      return `${nameEntity.substring(0, 19)}...`;
    } else {
      return nameEntity;
    }
  };

  return (
    <div className="user-details">
      <p
        className="user-name color-white"
        data-test="nav-user-name"
        no-entity={`${selectedEntity === null}`}
      >
        {name}
      </p>
      {selectedEntity !== null && <p className="user-type color-gray-whitish-light">{shortenEntityName(entityName)}</p>}
    </div>
  );
};

export default UserProfile;
