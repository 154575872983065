import { renderToString } from 'react-dom/server';
import { toast } from 'react-toastify';
import { store } from 'store';
import { Editor } from 'tinymce';
import { v4 as uuidv4 } from 'uuid';
import Clause from 'components/Editor/Clause/Clause';
import { setCrossRefDetails, setCrossRefModal, updateNewClauseId } from 'store/provisions/provisionDetailSlice';
import { ContentNodeType, createParameterNode, createTextBlockNode } from 'common/api/nodes';
import { updateProvisionContent } from 'common/api/provisions';
import { editorContextMenuForceClose } from 'utils/tsHelper';
import { countSubstr } from 'utils/utils-string';
import { updateAndSaveEditorContent } from '../ContextClauseToolbar';

const updateNewClause = (editor: Editor, nodeId: string, outerHTML: string) => {
  // Clause selection
  const query = `[data-node-id="${nodeId}"]`;
  const [handleNode] = editor.dom.select(query);
  // Add the content inside the clause
  if (handleNode) handleNode.innerHTML += outerHTML;

  // Trigger redux actions and save the content on the API

  // update the new clause id
  store.dispatch(updateNewClauseId({ nodeId }));

  updateAndSaveEditorContent(editor);
};

// Check if multiple paragraphs or lists were selected
const multiSelection = (selectedContent: string) => {
  const paraCount = countSubstr('</p>', selectedContent);
  if (paraCount >= 2) {
    toast.warning('Cannot select multiple paragraphs');
    return true;
  }

  const listCount = countSubstr('</li>', selectedContent);
  if (listCount >= 2) {
    toast.warning('Cannot select multiple lists');
    return true;
  }

  if (paraCount >= 1 && listCount >= 1) {
    toast.warning('Cannot select paragraphs and lists together');
    return true;
  }

  return false;
};

// Remove redundant nodes like empty text node and empty parameter node
const removeRedundantNodes = (editor: Editor, type: ContentNodeType) => {
  const nodes = editor.dom.select(`[data-node-type='${type}']`);

  Array.from(nodes).map(element => {
    if (element.innerHTML === '') {
      editor.dom.remove(element);
    }
  });
};

const cursorAtEnd = (editor: Editor) => {
  const selection = editor.selection;
  const range = selection.getRng(); // Get the current range
  return range.startOffset === range.endOffset;
};

const menuCloseAndRemoveHighlight = (editor: Editor) => {
  editorContextMenuForceClose();
  // Remove highlight
  editor.selection.select(editor.getBody(), true);
  editor.selection.collapse(true);
};

export const createClauseNode = (editor: Editor) => {
  // Where the selection starts and where it ends
  const getSelection = editor.selection.getSel() as any;
  // Clicked node
  const node = editor.selection.getNode();
  const nodeType = node.getAttribute('data-node-type') as ContentNodeType;
  if ([ContentNodeType.TEXT, ContentNodeType.PARAMETER, ContentNodeType.CLAUSE_REFERENCE].includes(nodeType)) {
    toast.error('Clause node cannot be created inside parameter and text block.');
    return;
  }
  // To check if the objects are assigned
  if (!node || !getSelection) return;

  if (cursorAtEnd(editor)) {
    const { newNode, nodeId } = clauseHtmlCreation(editor);

    const [, div1] = editor.dom.getParents(node) as any;
    const nodeContent = (newNode.childNodes[0] as HTMLElement).outerHTML;

    div1.insertAdjacentHTML('afterend', nodeContent);

    const outerHTML = '<p>Enter text here...</p>';
    updateNewClause(editor, nodeId, outerHTML);

    menuCloseAndRemoveHighlight(editor);
    return;
  }

  // Find the same level selection
  let firstSelection = editor.selection.getStart();
  let lastSelection = editor.selection.getEnd();

  const selection = [];

  let selectedContent = editor.selection.getContent();
  let paraCount = countSubstr('</p>', selectedContent);

  const emptyPara = '<p> </p>';
  let singleLineSpecialCase = false;

  // Check if empty para in the beginning of selection
  if (selectedContent.slice(0, 8) === emptyPara) {
    // Ignore the empty para and move to the next sibling
    firstSelection = firstSelection.nextElementSibling as Element;
    selectedContent = selectedContent.slice(8);
    if (paraCount === 2) {
      singleLineSpecialCase = true;
    }
    if (paraCount !== 2) {
      paraCount = paraCount - 1;
    }
  }
  // Check if empty para at the end of selection
  else if (selectedContent.slice(-8) === emptyPara) {
    // Ignore the empty para and move to the previous sibling
    lastSelection = lastSelection.previousElementSibling as Element;
    selectedContent = selectedContent.slice(0, -8);
    if (paraCount === 2) {
      singleLineSpecialCase = true;
    }
    if (paraCount !== 2) {
      paraCount = paraCount - 1;
    }
  }

  // Same line selection
  if (paraCount <= 1) {
    const nodeId = uuidv4();
    let outerHTML = node.outerHTML;
    // Create a new clause element
    const clause = (
      <Clause
        index={1}
        nodeId={nodeId}
        level={0}
      />
    );
    // Turns react element to string
    const getParaTag = outerHTML.substring(0, 2);
    // Check if content is not inside paragraph
    if (getParaTag !== '<p') {
      outerHTML = `<p>${outerHTML}</p>`;
    }
    node.innerHTML = renderToString(clause);
    // Add the content inside the clause node
    updateNewClause(editor, nodeId, outerHTML);
  } else {
    const allHtmlTags = editor.dom.select('*');
    // Add the node position to html elements
    Array.from(allHtmlTags).forEach((element, index) => {
      element.setAttribute('position', `${index}`);
    });

    // Multiple lines selection and also handle the single line special case
    // Get all children from current node (this is important to start a search)
    const children = Array.from(node.children || []);
    // get nearest Paragraph node for nested content
    while (firstSelection.nodeName !== 'P') {
      firstSelection = firstSelection.parentNode as any;
    }

    while (lastSelection.nodeName !== 'P') {
      lastSelection = lastSelection.parentNode as any;
    }

    // Copy content between two points
    // Start of selection and end of selection
    for (let i = 0; i < children.length; i++) {
      const child = children.at(i);

      // Check if the start of selection matches with the child and START to copy
      if (child?.isEqualNode(firstSelection)) {
        selection.push(child);
        if (singleLineSpecialCase) {
          break;
        }
      }
      // Check if the end of selection matches with the child and STOP to copy
      else if (child?.isEqualNode(lastSelection)) {
        selection.push(child);
        break;
      }
      // Add elements in between start and end
      else if (selection.length !== 0) {
        selection.push(child);
      }
    }

    // Turn selection into string
    const outerHTML = selectedContent;

    // REPLACING STEP
    const targetNode = selection.at(0);

    // Check if selection has some node
    if (!targetNode) return;

    // Place cursor at position 0
    editor.selection.setCursorLocation(targetNode, 0);

    // Remove the old content
    for (let i = 0; i < selection.length; i++) {
      const element = selection.at(i);
      if (element) editor.dom.remove(element as Node);
    }

    // Create the new Clause and get the nodeId and Node
    const { newNode, nodeId } = clauseHtmlCreation(editor);

    // Do the paste using the cursor as reference
    editor.selection.setNode(newNode as any);
    // Add the selected content inside the clause
    updateNewClause(editor, nodeId, outerHTML);
  }
  menuCloseAndRemoveHighlight(editor);
};

export const createTextBlock = (editor: Editor) => {
  const selectedContent = editor.selection.getContent();
  const documentTypeId = editor.getParam('id');

  if (multiSelection(selectedContent)) {
    return;
  }

  // Clicked node
  let selectedNode = editor.selection.getNode() as any;

  const attribute = selectedNode.getAttribute('data-node-type');
  if (attribute !== null) {
    if (attribute.includes[(ContentNodeType.PARAMETER, ContentNodeType.CLAUSE_REFERENCE)]) {
      toast.warning('Text node cannot be created inside a parameter node');
      return;
    }
  }

  // Check if text node is also selected
  if (selectedContent.includes('data-node-type="text"')) {
    toast.warning('Selection with text node is not allowed');
    return;
  }

  const firstNode = selectedNode;

  // get nearest text node for nested content
  while (selectedNode.getAttribute('data-node-type') !== ContentNodeType.TEXT) {
    selectedNode = selectedNode.parentNode;

    // check if first level node
    if (selectedNode.nodeName === '#document') {
      selectedNode = firstNode;
      break;
    }
  }

  const level = selectedNode.getAttribute('data-node-level');
  const levelValue: number = level !== null ? Number(level) + 1 : 1;
  if (levelValue > 3) {
    return;
  } else {
    store.dispatch(createTextBlockNode({ editor, level: levelValue })).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        // remove redundant parameters
        removeRedundantNodes(editor, ContentNodeType.PARAMETER);
        store.dispatch(updateProvisionContent({ documentTypeId }));
      }
    });
  }

  editorContextMenuForceClose();
};

export const createParameterBlock = (editor: Editor) => {
  const selectedContent = editor.selection.getContent();

  if (multiSelection(selectedContent)) {
    return;
  }

  const selectedNode = editor.selection.getNode();
  const contentHasParameter = selectedContent.includes('data-node-type="parameter"');

  if (selectedNode.getAttribute('data-node-type') === ContentNodeType.PARAMETER || contentHasParameter) {
    toast.warning('Parameter node cannot be created inside a parameter node');
    return;
  }

  const contentHasText = selectedContent.includes('data-node-type="text"');

  if (contentHasText) {
    toast.warning('Parameter node cannot contain a text node');
    return;
  }

  // Parameter creation
  store.dispatch(createParameterNode({ editor })).then((response: any) => {
    // remove redundant parameter blocks
    if (response.meta.requestStatus === 'fulfilled') {
      // remove redundant text nodes
      removeRedundantNodes(editor, ContentNodeType.TEXT);
    }
  });

  editorContextMenuForceClose();
};

export const createCrossReference = () => {
  editorContextMenuForceClose();
  store.dispatch(setCrossRefDetails(null));
  store.dispatch(setCrossRefModal(true));
};

/**
 * Creates Clause and return a HTML element and the current nodeID
 */
const clauseHtmlCreation = (editor: Editor) => {
  // Generates the new ID
  const nodeId = uuidv4();

  // Create a JSX component
  const clause = (
    <Clause
      index={1}
      nodeId={nodeId}
      level={0}
    />
  );

  // Convert it into a String, and then convert into a HTML element
  const newNode = editor.dom.createFragment(renderToString(clause));

  // Return NodeID and the HTML element
  return { nodeId, newNode };
};
