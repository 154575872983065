import { Accordion } from 'semantic-ui-react';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import Badge, { BadgeColor } from 'atoms/Badge';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { UserProps } from 'common/api/transactions';
import UploadedDocs from '../../../../UploadedDocs';
import './TenantSendAndReceive.scss';

export enum ApprovalRequestResponse {
  APPROVE = 'approve',
  REJECT = 'reject',
}

const TenantSendAndReceive = ({
  user,
  date,
  documents,
  note,
  version,
  tenantComments,
}: {
  user: UserProps | undefined;
  date: string;
  documents: DocumentIntendedToBeUploaded[] | null;
  note: string | null;
  pendingChanges: boolean;
  version: number | undefined;
  index: number;
  tenantComments?: boolean;
}) => {
  return (
    <div className="final-tenant-send-and-receive box-flex">
      <div className="line-dot"></div>
      <div className="flex-box">
        {/* Request for Approval Box */}
        <Accordion className="approval-accordian m-t-sm">
          <Accordion.Title
            active={true}
            index={1}
            className="p-sm"
          >
            <p className="date-title color-blue-very-dark-grayish">{date} </p>
            <span className="content-title m-t-xs m-b-none">
              {tenantComments ? 'Received tenants comments' : 'Sent to tenant'}
            </span>
          </Accordion.Title>
          <Accordion.Content
            active={true}
            className="p-sm p-t-xxs"
          >
            <div className="person color-blue-very-dark-grayish">
              Sent by &nbsp;
              <Avatar
                src={user?.photoUrl || ''}
                size={AvatarSize.Small}
                className="m-l-xs m-r-xs"
              />
              <span className="bold-person color-blue-very-dark">
                {user?.firstName} {user?.lastName}
              </span>
            </div>

            {note && (
              <p className="person m-t-sm">
                Note
                <span
                  className="editor-to-html note-text color-blue-very-dark"
                  dangerouslySetInnerHTML={{
                    __html: note,
                  }}
                ></span>
              </p>
            )}
            <span className={`person color-blue-very-dark-grayish version-number ${tenantComments && 'inside-tenant'}`}>
              Version &nbsp;
              <Badge
                color={BadgeColor.LIGHT_GREY}
                className="p-t-xxs p-b-xxs p-l-xs p-r-xs"
              >
                V.{version}
              </Badge>
            </span>
          </Accordion.Content>
        </Accordion>
        {/* @ts-ignore */}
        <div className={tenantComments ? 'move-up' : documents?.length > 2 ? 'move-up' : ''}>
          {documents?.map((document: DocumentIntendedToBeUploaded) => (
            <UploadedDocs
              fileName={document.name}
              fileURL={document.url}
              docx={document.name.includes('.docx')}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TenantSendAndReceive;
