import LandlordEntitiesCreator from 'views/entities/landlordEntities/EntityCreator/EntityCreator';
import AdminEntitiesListing from 'views/userAccountManagement/AdminEntities';
import AdminPermissionsListing from 'views/userAccountManagement/AdminPermissions';
import PermissionsPage from 'views/userAccountManagement/AdminPermissions';
import AdminUsersListing from 'views/userAccountManagement/AdminUsers';
import OrganisationPage from 'views/userAccountManagement/organisation';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const UserAccountManagementRoutes = () => {
  if (checkIfAdminURL()) {
    return [
      {
        path: '/users',
        element: isAuthenticated(<AdminUsersListing />),
      },
      {
        path: '/entities',
        element: isAuthenticated(<AdminEntitiesListing />),
      },
      {
        path: '/permissions',
        element: isAuthenticated(<AdminPermissionsListing />),
      },
      {
        path: '/permissions/dashboard',
        element: isAuthenticated(<AdminPermissionsListing />),
      },
    ];
  } else {
    return [
      {
        path: '/organisation',
        element: isAuthenticated(<OrganisationPage />),
      },
      {
        path: '/organisation/subsidiary/:id/details',
        element: isAuthenticated(<OrganisationPage />),
      },
      {
        path: '/organisation/subsidiary/create',
        element: isAuthenticated(<LandlordEntitiesCreator />),
      },
    ];
  }
};

export default UserAccountManagementRoutes;
