import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import ModalTemplate from 'components/ModalTemplate';
import { addToDocHistory } from 'store/transactions/transactionDetailSlice';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { sendToLandlord, updateTransaction } from 'common/api/transactions';
import { DROPDOWN_OPTION } from 'utils/UI';
import './ConfigureSendToLandlordModal.scss';

export interface ApprovalRequest {
  userId: string | null;
  tenantId: string | null | undefined;
  pendingChanges: boolean | null;
  note: string | null;
  documents: string[];
  status: ValidDocumentStatus;
}

const ConfigureSendToLandlordModal = () => {
  const dispatch = useAppDispatch();

  const { id: userId } = useAppSelector((state: RootState) => state.auth);
  const {
    activeTransaction: { tenantId, documentTypeIds },
  } = useAppSelector((state: RootState) => state.transactionDetail);
  const { documentTypesOptions } = useAppSelector((state: RootState) => state.miscellaneous);

  const newApprovalRequest: ApprovalRequest = {
    userId: userId,
    tenantId,
    pendingChanges: false,
    note: null,
    documents: [],
    status: ValidDocumentStatus.ReceivedCommentsFromTenantPortal,
  };

  const [open, setOpen] = useState<boolean>(false);
  const [approvalRequest, setApprovalRequest] = useState(newApprovalRequest);
  const { documents } = approvalRequest;

  const updateApprovalRequest = (key: string, value: any) => {
    let copyApprovalRequest = { ...approvalRequest };
    // @ts-ignore
    copyApprovalRequest[key] = value;
    copyApprovalRequest.tenantId = tenantId;
    setApprovalRequest(copyApprovalRequest);
  };

  const sendToLandlordFunc = async () => {
    setOpen(false);
    dispatch(addToDocHistory(approvalRequest));
    dispatch(updateTransaction({ approval: true, tenantPortal: true })).then((response: any) => {
      dispatch(sendToLandlord({ documentTypeIds: approvalRequest.documents }));
    });
    setApprovalRequest(newApprovalRequest);
  };

  const onModalStateUpdate = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const documentTypesOptionsFiltered: DROPDOWN_OPTION[] = documentTypesOptions.filter(doc =>
    documentTypeIds.some((docId: string) => docId === doc.value),
  );

  return (
    <ModalTemplate
      isOpen={open}
      onToggleModalStatus={onModalStateUpdate}
      className="approval configure-landlord-modal large"
      title="Configure send-to Landlord"
      showHeader={true}
      trigger={<Button className="btn grey-outline send-to-tenant">Send To Landlord</Button>}
    >
      <Form>
        <Grid className="pm-none">
          <Grid.Row className="p-none">
            <Grid.Column
              width={16}
              className="p-l-none"
            >
              <SelectField
                label="Which document(s) would you like to send?"
                placeholder="Select documents..."
                value={documents}
                multiple={true}
                options={documentTypesOptionsFiltered}
                onChange={updateApprovalRequest}
                info={true}
                required={true}
                fieldKey="documents"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="p-none btn-row m-t-m">
            <Button
              className="btn grey-outline"
              onClick={() => onModalStateUpdate(false)}
              data-test="category-cancel-button"
            >
              Save And Exit
            </Button>
            <Button
              className="btn red-bg"
              disabled={approvalRequest.documents.length === 0}
              onClick={sendToLandlordFunc}
              data-test="category-create-button"
            >
              Send To Landlord
            </Button>
          </Grid.Row>
        </Grid>
      </Form>
    </ModalTemplate>
  );
};

export default ConfigureSendToLandlordModal;
