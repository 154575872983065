import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface SendToLandlordMutationVariables {
  transactionId: string | null | undefined;
  documentTypeIds: string[];
  currentLandlordEntityId: string | null;
}

export const SEND_TO_LANDLORD = (transactionId: string | null | undefined, documentTypeIds: string[]) => {
  if (!transactionId) throw new Error('Send to landlord: transactionId is required');

  const variables: SendToLandlordMutationVariables = {
    transactionId,
    documentTypeIds,
    currentLandlordEntityId: getCurrentLandlordEntityId(),
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $documentTypeIds: [ID!], $currentLandlordEntityId: ID!) {
        sendToLandlordPdf(
          transactionId: $transactionId
          documentTypeIds: $documentTypeIds
          currentLandlordEntityId: $currentLandlordEntityId
        ) {
          result
        }
      }
    `,
    variables,
  };
};

export const sendToLandlord = createAsyncThunk(
  'transactions/sendToLandlord',
  async ({ documentTypeIds }: { documentTypeIds: string[] }, { getState }) => {
    const {
      transactionDetail: {
        activeTransaction: { id: transactionId },
      },
    } = getState() as RootState;

    const response = await client.mutate(SEND_TO_LANDLORD(transactionId, documentTypeIds));
    return response;
  },
);
