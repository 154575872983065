import { CValuationReport } from 'common/_classes/valuationReport2';
import Badge, { BadgeColor } from 'atoms/Badge';
import { conditionalClassName } from 'utils/tsHelper';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';

export const buildingGradeBody = (item: CValuationReport): string | JSX.Element[] => {
  const {
    data: { buildingFeatures },
  } = item;

  return buildingFeatures.useType
    ? buildingFeatures.useType.map((useType, index) => (
        <Badge
          badgeColor={BadgeColor.GRAY}
          key={`building-usetype-${item.id}-${index}`}
          className={conditionalClassName(index !== 0, 'm-l-xs')}
        >
          {replaceUnderscoreWithSpace(useType)}
        </Badge>
      ))
    : '';
};
