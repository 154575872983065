import { CValuationDataFloor } from 'common/_classes/valuationReport2';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import SelectField from 'atoms/FormField/Select';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import { FilterMode, FilterOnchangeKeys, RowAction, RowProps } from '../../RowConfig/RowProps';
import { LIST_FILTER_OPTIONS } from './ListFilter';
import { filterWhile } from './StringFilter';

const compare = (value: CValuationDataFloor[], filterValue: string[], filterMode: FilterMode) => {
  const trimmedValue = value.map(floor => floor.name.toLowerCase().trim());
  const trimmedFilterValue = filterValue.map(value => value.toLowerCase().trim());

  switch (filterMode) {
    case FilterMode.CONTAINS:
      return trimmedValue.some(tv => trimmedFilterValue.includes(tv));

    case FilterMode.DOES_NOT_CONTAIN:
      return trimmedValue.every(tv => !trimmedFilterValue.includes(tv));

    default:
      return true;
  }
};

export function applyPremiseFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyPremiseFilter: field key is missing');

  let value: CValuationDataFloor[] = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  return filterWhile(value, rowAction, compare);
}

const PremiseFilter = ({ options, onChange, filterItem }: FilterItemChildProps): JSX.Element => {
  return (
    <>
      <SelectField
        placeholder="Select..."
        fieldKey={FilterOnchangeKeys.filterMode}
        value={filterItem.filterMode}
        options={LIST_FILTER_OPTIONS}
        onChange={onChange}
        className="m-b-s"
      />
      <MultiSelect
        appendTo="self"
        value={filterItem.filterValue}
        options={options}
        itemTemplate={(option: any) => option.text}
        onChange={(e: MultiSelectChangeEvent) => onChange(FilterOnchangeKeys.filterValue, e.value)}
        placeholder="Value"
        className="p-column-filter w-100"
        dataKey="value"
        optionLabel="text"
      />
    </>
  );
};

export default PremiseFilter;
