import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import PremiseChargesTable from 'components/PremiseChargesTable';
import { SpaceSelectorRow } from 'components/PremiseChargesTable/columnConfig';
import { updateTestCharges } from 'store/transactions/transactionDetailSlice';
import { ModeProps } from 'common/api/miscellaneous';

const PremisesCharges = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const { testCharges } = useAppSelector((state: RootState) => state.transactionDetail);

  const onChange = (content: SpaceSelectorRow[]) => {
    dispatch(updateTestCharges(content));
  };

  return (
    <PremiseChargesTable
      mode={mode}
      content={testCharges}
      onChange={onChange}
    />
  );
};

export default PremisesCharges;
