import Condition from 'common/model/Condition';
import Conditions from 'common/model/Conditions';
import ParameterChoice from 'common/model/ParameterChoice';
import { deepClone } from 'utils/tsHelper';
import { ParameterChoiceBase } from '../policies';

export * from './checkParameterUse';
export * from './createParameter';
export * from './createParameterCategory';
export * from './createParameterGroup';
export * from './createParameterTable';
export * from './deleteParameter';
export * from './deleteParameterCategory';
export * from './deleteParameterGroup';
export * from './deleteParameterTable';
export * from './getParameter';
export * from './getParameterGroupHtml';
export * from './getParameterGroupPdf';
export * from './getParameterTable';
export * from './listContextParameters';
export * from './listContextParametersGroup';
export * from './listParameterCategories';
export * from './listParameterCategoriesAndGroups';
export * from './listParameterGroups';
export * from './listParameters';
export * from './listParametersWithAnswerType';
export * from './listParameterTables';
export * from './listParameterTablesPage';
export * from './updateParameter';
export * from './updateParameterCategory';
export * from './updateParameterGroup';
export * from './updateParametersIndex';
export * from './updateParameterTable';
export * from './types';

//TODO move to the constructor of ParameterInput
export const formatChoices = (choices: ParameterChoice[] | ParameterChoiceBase[] | null, isCreate = false) => {
  if (!choices) return [];

  return choices.map(element => ({
    ...(!isCreate && { id: element.id }),
    default: element.default,
    text: element.text,
    archive: element.archive,
    index: element.index,
    conditions: formatConditions(element.conditions),
  }));
};

export const formatConditions = (conditions: Conditions[]) => {
  if (conditions && conditions.length) {
    const clonedConditions = deepClone(conditions) || [];
    return clonedConditions.map((condition: Conditions) => {
      let list: Condition[] = [];
      if (condition.list.length) {
        list = condition.list.map((listItem: Condition) => {
          return { ...listItem, answer: JSON.stringify(listItem.answer) };
        });
      }
      return { ...condition, list };
    });
  }

  return [];
};
