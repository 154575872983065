import { TransactionMessageClone } from 'common/_classes';
import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dictionary, groupBy } from 'lodash';
import { MessageInputBox } from 'components/PreviewTab/Discussion';
import ThreadType from 'common/model/ThreadType';
import { listTransactionMessages } from 'common/api/transactions';
import { conditionalClassName } from 'utils/tsHelper';
import { formattedMonthDate, get12HrsTime } from 'utils/utils-date';
import { MessageBoxSize } from '../..';
import './Messages.scss';

const Messages = ({
  channelId,
  approvalId,
  messageBoxSize,
}: {
  channelId?: string;
  approvalId?: string;
  messageBoxSize: MessageBoxSize;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const [messageList, setMessagesList] = useState([]);
  const [messageInput, setMessageInput] = useState<string>('');

  const messages = messageList.filter((message: TransactionMessageClone) => message.amendmentFlag === false);

  const dateWiseMessages: Dictionary<TransactionMessageClone[]> = groupBy(
    messages,
    (message: TransactionMessageClone) => formattedMonthDate(message.insertedAt.toString()),
  );

  useEffect(() => {
    if (channelId) {
      dispatch(listTransactionMessages({ channelId, threads: [ThreadType.Landlord] })).then((response: any) => {
        const messages = response.payload.data.listTransactionMessages;
        setMessagesList(messages);
      });
    }
  }, [channelId]);

  const Message = ({
    message,
    isLastMessage,
  }: {
    message: TransactionMessageClone;
    isLastMessage: boolean;
  }): JSX.Element => {
    return (
      <div
        className={`message
          ${userId === message.user.id ? 'user-message m-l-auto' : 'other-user-message'}
          ${conditionalClassName(message.amendmentFlag, 'amendment-message')}
          p-xs m-r-s
          ${conditionalClassName(!isLastMessage, 'm-b-s')}
        `}
        data-test="message-container"
        key={`message-${message.id}`}
      >
        <span
          className="person"
          data-test="user-message"
        >
          <span
            className="name"
            data-test="sender-name"
          >
            <b>{message.user.firstName}</b>
          </span>
          <span
            className="time m-l-xxs m-r-s"
            data-test="message-timestamp"
          >
            {message.edited && 'Edited '}
            {get12HrsTime(message.insertedAt)}
          </span>
        </span>

        <pre
          className={`text ${conditionalClassName(message.amendmentFlag, 'p-t-xs p-b-xs')}`}
          data-test="message-content"
        >
          <div dangerouslySetInnerHTML={{ __html: message.message }} />
        </pre>
      </div>
    );
  };

  return (
    <>
      <div
        id={`list-of-messages-${approvalId}`}
        className={`list-of-messages ${messageBoxSize}`}
      >
        {Object.entries(dateWiseMessages).map(([date, messages]) => (
          <div key={`date-wise-${date}`}>
            <div
              className="date m-r-sm m-l-sm m-t-xs"
              data-test="message-date"
            >
              {formattedMonthDate(date)}
            </div>

            {messages.map((message: TransactionMessageClone, index: number) => (
              <Message
                message={message}
                key={message.id}
                isLastMessage={index === messages.length - 1}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="discussion-wrapper inside-approvals">
        <div className="message-box m-b-sm">
          <MessageInputBox
            message={messageInput}
            setMessage={setMessageInput}
            amendmentFlag={false}
            thread={ThreadType.Landlord}
            channelId={channelId}
            setMessagesList={setMessagesList}
            approvalId={approvalId}
          />
        </div>
      </div>
    </>
  );
};

export default Messages;
