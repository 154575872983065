import { CValuationDataFloor, CValuationReport } from 'common/_classes/valuationReport2';
import Badge, { BadgeColor } from 'atoms/Badge';
import { conditionalClassName } from 'utils/tsHelper';

export const premisesBody = (item: CValuationReport): JSX.Element[] => {
  const {
    data: {
      premises: { floors },
    },
  } = item;

  return valuationDataFloorMap(floors);
};

export const valuationDataFloorMap = (floors: CValuationDataFloor[]): JSX.Element[] => {
  return floors.map((floor, index) => (
    <div
      key={floor.id}
      className={`d-flex flex-gap-s align-center ${conditionalClassName(index !== 0, 'm-t-s p-t-s border-top')}`}
    >
      <span className="width-auto">{floor.name}</span>

      <div className="d-flex flex-gap-xs">
        {floor.spaces.map(space => (
          <Badge
            badgeColor={BadgeColor.GRAY}
            key={`unit-characteristics-${space.id}-${index}`}
            className={conditionalClassName(index !== 0, 'm-l-xxs')}
          >
            {space.name}
          </Badge>
        ))}
      </div>
    </div>
  ));
};
