import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sortBy } from 'lodash';
import NodeAlert from 'components/Editor/NodeAlert';
import ParameterAnswerAccordion, { GuidelineLocationTypes } from 'components/ParameterAnswerAccordion';
import { onUpdateParameterAnswers } from 'components/ParametersGroupQuestionsForm';
import { getNodeIds } from 'components/PreviewTab/PreviewEditorMain';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { executeListOfConditions, executeListOfFormatters } from 'common/api/formatters';
import { listContextParameters } from 'common/api/parameters';
import { AnswerProps } from 'common/api/policies';
import { ContentClone } from 'common/api/provisions';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { checkIfAnswerExists, getAnswerValueByParameterId, getAnswers } from 'utils/utils-answer';
import { parseSelectedContent } from '..';

const answersBox = (parameter: any, index: number, answers: AnswerProps[]) => {
  const tableId = parameter.paramRef.tableId;
  const tabIndex = parameter.paramRef.index;
  const checkIfAnswer = checkIfAnswerExists({
    answers,
    answerType: parameter.answerType,
    parameterId: parameter.id,
    tableId,
    tabIndex,
  });

  return (
    <div key={`question-forms-${index}`}>
      <ParameterAnswerAccordion
        parameter={parameter}
        index={index}
        key={index}
        checkIfAnswer={checkIfAnswer}
        tableId={tableId}
        tabIndex={tabIndex}
        guidelineLocation={GuidelineLocationTypes.BOTTOM}
      />
    </div>
  );
};

const ParametersList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeParameters, activeDocType } = useAppSelector((state: RootState) => state.miscellaneous);
  const { activePolicy, activePolicyAnswers, activePolicyContents } = useAppSelector(
    (state: RootState) => state.policyDetail,
  );
  const { activeTransaction, activeTransactionAnswers, activeTransactionContents } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );

  const { parameterMode, conditional } = useAppSelector((state: RootState) => state.miscellaneous);
  const { nodeError, activeNode } = useAppSelector((state: RootState) => state.nodes);

  const policyPage: boolean = checkIfPoliciesPage();
  const context: ExecuteContext = policyPage ? ExecuteContext.Policy : ExecuteContext.Transaction;
  const contents: ContentClone[] = policyPage ? activePolicyContents : activeTransactionContents;

  const activeContext = policyPage ? activePolicy : activeTransaction;

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);

  const checkIfParameter: boolean = activeParameters.length !== 0;

  const listNodeIds = () => {
    const parsed: Document = parseSelectedContent(contents, activeDocType);
    const allParameters: NodeListOf<Element> = parsed.querySelectorAll('[data-node-type="parameter"]');
    const allClauses: NodeListOf<Element> = parsed.querySelectorAll('[data-node-type="clause"]');
    const allTextNodes: NodeListOf<Element> = parsed.querySelectorAll('[data-node-type="text"]');

    return {
      parameterIds: getNodeIds(allParameters),
      clauseIds: getNodeIds(allClauses),
      textIds: getNodeIds(allTextNodes),
    };
  };

  const getContextParameters = () => {
    dispatch(
      listContextParameters({
        context,
        contextId: activeContext.id,
        provisionId: null,
        conditional: true,
        mode: ParameterModes.Detailed,
      }),
    );
  };

  const runFormatterAndConditions = () => {
    const { clauseIds, parameterIds, textIds } = listNodeIds();
    dispatch(
      executeListOfFormatters({
        context: context,
        contextId: activeContext.id,
        nodeIds: parameterIds,
      }),
    );

    dispatch(
      executeListOfConditions({
        context: context,
        contextId: activeContext.id,
        nodeIds: [...clauseIds, ...textIds],
      }),
    );
  };

  const onUpdateAnswers = () => {
    onUpdateParameterAnswers(
      activeTransaction.id,
      activeTransactionAnswers,
      getContextParameters,
      runFormatterAndConditions,
      parameterMode,
      conditional,
      dispatch,
      policyPage ? false : activeNode?.iterable,
    );
  };

  if (nodeError) {
    return <NodeAlert message="Alert, the node no longer exists in the database" />;
  }

  return (
    <>
      {checkIfParameter && (
        <div className="answer-buttons">
          <Button
            className="btn grey-bg"
            data-test="update-answers-button"
            onClick={onUpdateAnswers}
          >
            UPDATE ANSWERS
          </Button>
        </div>
      )}

      {sortBy(activeParameters, 'index').map((parameterDetails, index) => {
        const { id, tableName, paramRef } = parameterDetails;

        let value = getAnswerValueByParameterId(answers, parameterDetails);
        value = value ? `${value}` : 'undefined';

        const paramIndex = paramRef.index;

        return (
          <div
            className="preview-side-menu-parameter-wrapper"
            data-test="preview-side-menu-parameter-wrapper"
            key={`parameter-list-${id}`}
          >
            {tableName && (
              <p className="preview-side-menu-table-title m-t-clear-xs">
                <span>Table: </span>
                {tableName}
                {paramIndex !== null ? (
                  <> ({paramIndex})</>
                ) : (
                  <span className="table-index-undefined">(undefined)</span>
                )}
              </p>
            )}
            <div>{answersBox(parameterDetails, index, answers)}</div>
          </div>
        );
      })}
    </>
  );
};

export default ParametersList;
