import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { OrganisationInput } from './types';

interface CreateOrganisationQueryVariables {
  params: OrganisationInput;
}

export const CREATE_ORGANISATION = (newOrganisation: OrganisationInput) => {
  const variables: CreateOrganisationQueryVariables = {
    params: {
      name: newOrganisation.name,
      type: newOrganisation.type,
      index: newOrganisation.index,
      logo: newOrganisation.logo,
      operatingStatus: newOrganisation.operatingStatus,
      archived: newOrganisation.archived,
      parentId: newOrganisation.parentId,
    },
  };

  return {
    mutation: gql`
      mutation ($params: OrganisationInput!) {
        createOrganisation(params: $params) {
          id
          name
        }
      }
    `,
    variables,
  };
};

export const createOrganisation = createAsyncThunk('organisations/createOrganisation', async (_arg, { getState }) => {
  const state = getState() as RootState;
  //TODO: add create organisation state
  const organisations: any = state.organisationsListing;
  const activeOrganisation: any = organisations.activeOrganisation;
  const response = await client.mutate(CREATE_ORGANISATION(activeOrganisation));

  return response;
});
