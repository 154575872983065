import { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate, { ModalTemplateSize } from 'components/ModalTemplate';
import { OnCreateTransactionMessageParams } from 'components/PreviewTab/Discussion';
import { resetActiveAmendment, updateActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { getAmendment } from 'common/api/transactions';
import { createAmendment, updateAmendment } from 'common/api/transactions';
import { amendmentDisabled } from 'utils/tsValidator';
import ContentEditor from './ContentEditor';
import GeneralForm from './GeneralForm';
import './AmendmentCreatorModal.scss';

interface AmendmentCreatorModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  id?: string;
  fromTransactionDiscussion: boolean;
  onAfterAmendmentCreated?: {
    params: OnCreateTransactionMessageParams;
    action: (params: OnCreateTransactionMessageParams) => void;
  };
}

const AmendmentCreatorModal = ({
  isOpen,
  setIsOpen,
  id,
  fromTransactionDiscussion,
  onAfterAmendmentCreated,
}: AmendmentCreatorModalProps) => {
  if (fromTransactionDiscussion && !onAfterAmendmentCreated) {
    throw new Error('AmendmentCreatorModal: onAfterAmendmentCreated is required');
  }
  const dispatch = useAppDispatch();

  const { activeAmendment, isLoading } = useAppSelector((state: RootState) => state.transactionDetail);

  const transactionDiscussionAmendment = activeAmendment.fromTransactionDiscussion && id;

  useEffect(() => {
    if (isOpen && id) {
      dispatch(getAmendment(id));
    }
  }, [isOpen]);

  useEffect(() => {
    // create mode
    if (fromTransactionDiscussion && !id) {
      dispatch(updateActiveAmendment({ key: 'fromTransactionDiscussion', value: true }));
    }
  }, [fromTransactionDiscussion, id]);

  /**
   * On Save button Click
   */
  const onSave = () => {
    if (!id) {
      dispatch(createAmendment()).then((response: any) => {
        if (fromTransactionDiscussion) {
          if (response.meta.requestStatus === 'fulfilled') {
            const message = response.payload.data.createAmendment.content;
            onAfterAmendmentCreated?.action({ ...onAfterAmendmentCreated.params, message });
          }
        }
      });
    } else {
      dispatch(updateAmendment());
    }

    setIsOpen(false);
  };

  const mode = transactionDiscussionAmendment || (isLoading && id) ? MODE_OPTIONS.READ : MODE_OPTIONS.EDIT;

  return (
    <ModalTemplate
      size={ModalTemplateSize.large}
      isOpen={isOpen}
      onToggleModalStatus={setIsOpen}
      onModalClose={() => dispatch(resetActiveAmendment())}
      title={`${transactionDiscussionAmendment ? 'View' : id ? 'Update' : 'Create'} Ammendment`}
      className="amendment-creator-modal"
    >
      <div className="grid m-none">
        <div className="col-3 d-flex-column justify-space-between p-m">
          <GeneralForm mode={mode} />

          {mode === MODE_OPTIONS.EDIT && (
            <div className="d-flex justify-center flex-gap-m btn-row">
              <Button
                className="btn grey-outline"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="btn grey-bg"
                onClick={onSave}
                disabled={amendmentDisabled(activeAmendment)}
              >
                Save
              </Button>
            </div>
          )}
        </div>
        <div className="col-9 bg-grayish-magenta-light p-m">
          <ContentEditor mode={mode} />
        </div>
      </div>
    </ModalTemplate>
  );
};

export default AmendmentCreatorModal;
