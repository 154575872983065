import { CDuration } from 'common/_classes/valuationReport2';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { checkNotEmpty } from 'utils/tsValidator';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { FilterItemChildProps } from '.';
import { FilterMode, FilterOnchangeKeys, RowAction, RowProps } from '../../RowConfig/RowProps';
import { FILTER_OPTIONS } from './NumberFilter';
import { filterWhile } from './StringFilter';

const compare = (filterValue: CDuration, value: CDuration, filterMode?: FilterMode) => {
  if (!filterValue) return true;

  let { years, months, days } = filterValue;

  years = checkNotEmpty(years) && typeof years === 'string' ? parseInt(years) : years;
  months = checkNotEmpty(months) && typeof months === 'string' ? parseInt(months) : months;
  days = checkNotEmpty(days) && typeof days === 'string' ? parseInt(days) : days;

  switch (filterMode) {
    case FilterMode.EQUALS:
      return value.days === days && value.months === months && value.years === years;

    case FilterMode.GREATER_THAN:
      return (
        value.years > years ||
        (value.years === years && value.months > months) ||
        (value.years === years && value.months === months && value.days > days)
      );

    case FilterMode.LESS_THAN:
      return (
        value.years < years ||
        (value.years === years && value.months < months) ||
        (value.years === years && value.months === months && value.days < days)
      );

    case FilterMode.GREATER_THAN_OR_EQUALS:
      return (
        value.years > years ||
        (value.years === years && value.months > months) ||
        (value.years === years && value.months === months && value.days >= days)
      );

    case FilterMode.LESS_THAN_OR_EQUALS:
      return (
        value.years < years ||
        (value.years === years && value.months < months) ||
        (value.years === years && value.months === months && value.days <= days)
      );

    default:
      return true;
  }
};

export function applyDurationFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyDurationFilter: field key is missing');

  let value: CDuration | null = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  return filterWhile(value, rowAction, compare);
}

const DurationFilter = ({ onChange, filterItem }: FilterItemChildProps): JSX.Element => {
  return (
    <>
      <SelectField
        className=""
        placeholder="Select..."
        fieldKey={FilterOnchangeKeys.filterMode}
        value={filterItem.filterMode}
        options={FILTER_OPTIONS}
        onChange={onChange}
        clearable={true}
      />

      <div className="grid pm-none flex-gap-xxs">
        <div className="col-4 p-none">
          <InputField
            type={InputFieldType.NUMBER}
            value={filterItem.filterValue.years}
            placeholder="Years..."
            onChange={(key, years) => onChange(key, { ...filterItem.filterValue, years })}
            fieldKey={FilterOnchangeKeys.filterValue}
          />
        </div>
        <div className="col-4 p-none">
          <InputField
            type={InputFieldType.NUMBER}
            value={filterItem.filterValue.months}
            placeholder="Months..."
            onChange={(key, months) => onChange(key, { ...filterItem.filterValue, months })}
            fieldKey={FilterOnchangeKeys.filterValue}
          />
        </div>
        <div className="col-4 p-none">
          <InputField
            type={InputFieldType.NUMBER}
            value={filterItem.filterValue.days}
            placeholder="Days..."
            onChange={(key, days) => onChange(key, { ...filterItem.filterValue, days })}
            fieldKey={FilterOnchangeKeys.filterValue}
          />
        </div>
      </div>
    </>
  );
};

export default DurationFilter;
