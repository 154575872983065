import { Calendar } from 'primereact/calendar';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { sortBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { Icons } from 'utils/utils-icons';
import { capitalizeFirstLetter, replaceUnderscoreWithSpace } from 'utils/utils-string';

export const filterElementDate = (
  options: ColumnFilterElementTemplateOptions,
  view: 'date' | 'month' | 'year' | undefined = 'date',
): JSX.Element => {
  return (
    <Calendar
      value={options.value}
      onChange={e => {
        options.filterCallback(e.value, options.index);
      }}
      view={view}
      dateFormat="dd/mm/yy"
      placeholder={view === 'month' ? 'MM/YYYY' : 'DD/MM/YYYY'}
      mask="99/99/9999"
    />
  );
};

export const filterElementNumber = (options: ColumnFilterElementTemplateOptions): JSX.Element => {
  return (
    <InputNumber
      value={options.value}
      onChange={e => options.filterCallback(e.value, options.index)}
    />
  );
};

export const filterElementNumberRange = (
  options: ColumnFilterElementTemplateOptions,
  range = [0, 100],
): JSX.Element => {
  console.log('options -> ', options);
  console.log('range -> ', range);
  return (
    <>
      <Slider
        value={options.value}
        onChange={e => options.filterCallback(e.value)}
        range
        className="m-3"
        min={range[0]}
        max={range[1]}
      ></Slider>
      <div className="flex align-items-center justify-content-between px-2">
        <span>{options.value ? options.value[0] : range[0]}</span>
        <span>{options.value ? options.value[1] : range[1]}</span>
      </div>
    </>
  );
};

const filterElementSingleSelectTemplate = (status: string): JSX.Element => {
  return <Badge>{status}</Badge>;
};

export const filterElementSingleSelect = (
  options: ColumnFilterElementTemplateOptions,
  allOptions: string[],
): JSX.Element => {
  return (
    <Dropdown
      value={options.value}
      options={allOptions}
      onChange={e => options.filterCallback(e.value, options.index)}
      itemTemplate={filterElementSingleSelectTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

export interface StatusBadgeArgument {
  value: string;
  color: BadgeColor;
}

export const filterElementStatusTemplate = (statusText: string, statuses: StatusBadgeArgument[]) => {
  let text: string = replaceUnderscoreWithSpace(statusText);
  const found: StatusBadgeArgument | undefined = statuses.find(status => status.value === statusText);

  if (found) {
    return <Badge badgeColor={found.color}>{text}</Badge>;
  }

  return <Badge>{text}</Badge>;
};

export const filterElementStatusTemplateV2 = (statusText: string, statuses: StatusBadgeArgument[]) => {
  let text: string = replaceUnderscoreWithSpace(statusText);
  const found: StatusBadgeArgument | undefined = statuses.find(status => status.value === statusText);

  if (found) {
    return <div className={`badge-container ${found.color}`}>{text}</div>;
  }

  return <div>{text}</div>;
};

export const filterElementTypeTemplate = (type: string) => {
  return capitalizeFirstLetter(type.toLowerCase().replace('_', ' '));
};

export const filterElementType = (options: ColumnFilterElementTemplateOptions, statuses: string[]): JSX.Element => {
  return (
    <MultiSelect
      value={options.value}
      options={statuses}
      itemTemplate={filterElementTypeTemplate}
      onChange={(e: MultiSelectChangeEvent) => options.filterCallback(e.value)}
      placeholder="Any"
      className="p-column-filter"
    />
  );
};

export const filterElementStatus = (
  options: ColumnFilterElementTemplateOptions,
  statuses: StatusBadgeArgument[],
): JSX.Element => {
  const dropdownOptions: string[] = sortBy(statuses.map(status => status.value));
  return (
    <MultiSelect
      value={options.value}
      options={dropdownOptions}
      itemTemplate={(value: string) => filterElementStatusTemplate(value, statuses)}
      onChange={(e: MultiSelectChangeEvent) => options.filterCallback(e.value)}
      placeholder="Any"
      className="p-column-filter"
    />
  );
};

export const filterElementCountry = (
  options: ColumnFilterElementTemplateOptions,
  countryNames: (string | undefined)[],
): JSX.Element => {
  return (
    <MultiSelect
      value={options.value}
      options={countryNames}
      itemTemplate={filterElementCountryTemplate}
      onChange={(e: MultiSelectChangeEvent) => options.filterCallback(e.value)}
      placeholder="Any"
      className="p-column-filter"
    />
  );
};

export const filterElementCountryTemplate = (countryName: string, countryFlag?: string): JSX.Element => {
  return (
    <div>
      <i className={`flag ${countryFlag}`}></i> {countryName}
    </div>
  );
};

export const filterElementYesOrNoTemplate = (status: string): JSX.Element => {
  return <Badge badgeColor={status ? BadgeColor.GREEN : BadgeColor.GRAY}>{status ? 'YES' : 'NO'}</Badge>;
};

export const filterElementYesOrNo = (options: ColumnFilterElementTemplateOptions, label: string): JSX.Element => {
  return (
    <div className="flex align-items-center gap-2">
      <label
        htmlFor="verified-filter"
        className="font-bold"
      >
        {label}
      </label>
      <TriStateCheckbox
        value={options.value}
        onChange={e => options.filterCallback(e.value)}
      />
    </div>
  );
};

export const filterElementText = (options: ColumnFilterElementTemplateOptions, values: string[]): JSX.Element => {
  return (
    <MultiSelect
      value={options.value}
      options={values}
      itemTemplate={text => text}
      onChange={(e: MultiSelectChangeEvent) => options.filterCallback(e.value)}
      placeholder="Any"
      className="p-column-filter"
    />
  );
};

/**
 * Table column body for boolean
 * @param {boolean} value
 * @returns
 */
export const filterBooleanElementBody = (value: boolean): JSX.Element => {
  return value ? (
    <Icon
      className="param-icon color-green"
      icon={Icons.TickCircleSolid}
    />
  ) : (
    <Icon
      className="param-icon color-orange"
      icon={Icons.MinusCircleFill}
    />
  );
};
