import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/adminAuth/adminListPermissions';
import { listPermissions } from 'common/api/auth/listPermissions';
import PermissionModal from './PermissionModal';

function AdminPermissionsHeaderToolbar() {
  const { search } = useAppSelector((state: RootState) => state.adminListPermissions);

  const onGetData = (value?: string) => {
    // @ts-ignore
    return listPermissions({ searchValue: value, first: 1000 });
  };

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <PermissionModal />
    </>
  );
}

export default AdminPermissionsHeaderToolbar;
