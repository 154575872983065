import { ParameterChoiceBase } from 'common/_classes';
import { Fragment } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import Badge, { BadgeColor } from 'atoms/Badge';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonMultiChoice, initAnswerValue } from 'common/api/formatters';
import { OmitMultiple } from 'utils/UI';

export interface Choice
  extends OmitMultiple<Pick<AnswerJsonMultiChoice, 'values' | 'inverses'>, 'values' | 'inverses'> {
  values: string[] | null;
  inverses: string[] | null;
}

interface SingleChoiceProps {
  value: Choice;
  choices: ParameterChoiceBase[];
  onChange: (value: Choice) => void;
  tabIndex: number | null;
}

const SingleChoice = ({ value, choices = [], onChange, tabIndex }: SingleChoiceProps): JSX.Element => {
  const filteredChoices = choices.filter(
    choice =>
      !Array.isArray(choice.applicableRowIndexes) ||
      choice.applicableRowIndexes.length === 0 ||
      (tabIndex !== null && choice.applicableRowIndexes.includes(tabIndex)),
  );

  const choiceIds: string[] = filteredChoices.map((choice: ParameterChoiceBase) => choice.id);

  const onChangeHandler = (selectedValue: string): void => {
    let selectedCopy: Choice = { ...value };
    selectedCopy = {
      values: [selectedValue],
      inverses: choiceIds.filter((choiceId: string) => choiceId !== selectedValue),
    };
    onChange(selectedCopy);
  };

  const onChangeHandlerClear = (): void => {
    onChange({
      values: null,
      inverses: null,
    });
  };

  return (
    <Form>
      <Form.Group grouped>
        {filteredChoices?.map((choice: ParameterChoiceBase, index: number) => (
          <Fragment key={index}>
            {/* TODO Temporary If check */}
            {!choice.archive && (
              <div className="d-flex align-center">
                <Form.Radio
                  label={choice.text}
                  name="surrenderVariant"
                  onChange={() => onChangeHandler(choice.id)}
                  checked={value.values ? value.values.includes(choice.id) : false}
                  value={choice.id}
                  disabled={choice.archive}
                />

                {choice.archive && (
                  <Badge
                    badgeColor={BadgeColor.RED}
                    className="m-l-s"
                  >
                    Archived
                  </Badge>
                )}
              </div>
            )}
          </Fragment>
        ))}
      </Form.Group>

      <span
        onClick={() => onChangeHandlerClear()}
        className="parameters-questions-clear-data"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default SingleChoice;
