import moment from 'moment';
import { Line as LineChart } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import {
  refusalRightsBody,
  renewalRightsBody,
  rentReviewDetailsBody,
} from 'components/ComparablesTable/RowConfig/RowBody';
import { isValidJSON } from 'utils/utils-string';

export type AdditionalData = {
  Table?: {
    columnConfig: CustomColumnProps[];
  };
  Chart?: {
    data: {
      labels: string[];
      datasets: any[];
    };
  };
};

export type MessageType = {
  isFromBot: boolean;
  sender: string;
  message: string;
  timestamp: number;
  additionalData?: AdditionalData;
};

// check display type in message by regex
const DISPLAY_TYPE_REGEX = new RegExp(/\{Table\}|\{Chart\}/g);

const MessageHeader = ({ name, isFromBot, timestamp }: { name: string; isFromBot: boolean; timestamp: number }) => {
  return (
    <div className="message-item-header">
      <strong className={`message-item-header__name${isFromBot ? ` message-item-header__name--bot` : ``}`}>
        {name}
      </strong>
      {timestamp && <time className="message-item-header__time">{moment(timestamp).format('HH:mm A')}</time>}
    </div>
  );
};

type AnyObject = { [key: string]: any };
type AnyArray = any[];

// Function to convert keys to camelCase
function convertKeysToCamelCase(obj: AnyObject | AnyArray): AnyObject | AnyArray {
  if (_.isArray(obj)) {
    return _.map(obj, item => convertKeysToCamelCase(item)) as AnyArray;
  } else if (_.isPlainObject(obj)) {
    return _.mapValues(
      _.mapKeys(obj, (value, key) => _.camelCase(key)),
      value => {
        return convertValue(value);
      },
    );
  } else {
    return obj;
  }
}

// Helper function to convert value
function convertValue(value: any): any {
  if (_.isArray(value)) {
    return _.map(value, item => convertKeysToCamelCase(item));
  } else if (_.isPlainObject(value)) {
    return convertKeysToCamelCase(value);
  } else {
    return value;
  }
}

const convertValuesToString = (row: AnyObject): AnyObject => {
  row = convertKeysToCamelCase(row);
  return Object.entries(row).reduce((acc: AnyObject, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      if (key === 'rentReviews') {
        acc[key] = rentReviewDetailsBody(value);
      } else if (key === 'optionsToRenew') {
        acc[key] = renewalRightsBody(value);
      } else {
        acc[key] = JSON.stringify(value, null, 2); // Convert object or array to string
      }
    } else {
      acc[key] = String(value); // Convert primitive values to string
    }
    return acc;
  }, {});
};

const Message = ({
  message,
  sender,
  isFromBot,
  timestamp,
  additionalData,
}: {
  message: string;
  sender?: string;
  isFromBot: boolean;
  timestamp: number;
  additionalData?: AdditionalData;
}) => {
  const navigate = useNavigate();
  // render content with different types of messages: [text, table, chart, etc]
  const renderContent = ({ message, additionalData }: { message: any; additionalData?: AdditionalData }) => {
    message = isValidJSON(message) ? JSON.parse(message) : message;
    const type = message?.format;
    switch (type) {
      case 'table':
        const rows = message.rows;
        const columns = message.headers;
        const title = message.title;
        return (
          <div>
            <p>{title}</p>
            <TableReactPrime
              content={rows.map(convertValuesToString)}
              columnConfig={columns}
            />
          </div>
        );

      case 'string':
        return <p>{message.value}</p>;
      case 'object':
        if (message.value?.tenancy_id) {
          return (
            <p>
              {message.value.message}
              <p
                className="tenancy-link"
                onClick={() => navigate(`/tenancy/${message.value?.tenancy_id}/details`)}
              >
                Link to Tenancy
              </p>
            </p>
          );
        }

        if (message.title === 'ROFO Option:') {
          return <p>{refusalRightsBody(message.value)}</p>;
        }

        if (message.title === 'Upcoming rent review:') {
          // @ts-ignore
          return <p>{rentReviewDetails(convertKeysToCamelCase(message.value))}</p>;
        }

        return <p>{message.value.message}</p>;
      default:
        return <p>{message}</p>;
    }

    // MIGHT BE USED LATER
    // split message by newline and render <p> tag for each line
    //const lines = message.split('\n');
    //return lines.map((line, index) => {
    // if (DISPLAY_TYPE_REGEX.test(line) && line.match(DISPLAY_TYPE_REGEX)!.length > 0 && additionalData) {
    //   switch (line.match(DISPLAY_TYPE_REGEX)![0]) {
    //     case '{Table}':
    //       const table = additionalData['Table'];
    //       if (table) {
    //         return (
    //           <TableReactPrime
    //             key={index}
    //             columnConfig={table.columnConfig}
    //           />
    //         );
    //       }
    //     case '{Chart}':
    //       const lineChart = additionalData['Chart'];
    //       if (lineChart) {
    //         return (
    //           <LineChart
    //             key={index}
    //             data={lineChart.data}
    //           />
    //         );
    //       }
    //     default:
    //       return <p key={index}>{line}</p>;
    //   }
    // }
    //return <p key={index}>{line}</p>;
    // });
  };

  return (
    <div className={`message-item${isFromBot ? ` message-item--bot` : ``}`}>
      <div className={`message-item__content`}>
        <MessageHeader
          name={sender ? sender : 'You'}
          isFromBot={isFromBot}
          timestamp={timestamp}
        />
        {/*<code>{message}</code>*/}
        <div>{renderContent({ message, additionalData })}</div>
      </div>
    </div>
  );
};

export default Message;
