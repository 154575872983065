enum Resources {
  Dashboard = 'DASHBOARD',
  Transactions = 'TRANSACTIONS',
  Tenancy = 'TENANCY',
  Tenants = 'TENANTS',
  Policies = 'POLICIES',
  Properties = 'PROPERTIES',
  Portfolios = 'PORTFOLIOS',
  Organisations = 'ORGANISATIONS',
  Benchmarks = 'BENCHMARKS',
  Users = 'USERS',
  Parameters = 'PARAMETERS',
  Provisions = 'PROVISIONS',
  Formatters = 'FORMATTERS',
  Translators = 'TRANSLATORS'
}
export default Resources;
