import { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementNumberRange } from 'atoms/TableReactPrime/columnTemplates';
import Role from 'common/model/Role';
import AssignedUsersToolTip from './AssignedUsersToolTip';
import { RolesRowActionButtons } from './RolesRowActionButtons';

export class RolesRow {
  id: string;
  name: string;
  membersCount: number;

  constructor(role: Role) {
    this.id = role.id;
    this.name = role.name;
    this.membersCount = role.members.length;
  }
}

/*
 * Definition of the column (names, format...)
 */
export const rolesColumnConfig = (): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Role',
      filter: true,
      filterPlaceholder: 'Search by Role',
      filterField: 'name',
      sortable: true,
    },
    {
      field: 'approvalLevel',
      header: 'Approval Level',
      filter: true,
      filterPlaceholder: 'Search by Approval Level',
      filterField: 'approvalLevel',
      sortable: true,
      dataType: 'text',
      mandatory: true,
      body: row => (
        <div className="approval-level color-blue-very-dark-grayish bg-gray-very-light">{row.approvalLevel}</div>
      ),
    },
    {
      field: 'membersCount',
      header: 'Assigned Users',
      filterPlaceholder: 'Search by Assigned Users',
      filter: true,
      filterField: 'membersCount',
      sortable: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      body: row => (
        <AssignedUsersToolTip
          trigger={<span className="cursor-default">{row.membersCount}</span>}
          id={row.id}
          disabled={!row.membersCount || row.membersCount === 0}
        />
      ),
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: RolesRow) => <RolesRowActionButtons row={row} />,
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
