import { useState } from 'react';
import { Button, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import AmendmentCreatorModal from 'views/transactions/AmendmentCreatorModal';
import Amendment from 'common/model/Amendment';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { deleteAmendment } from 'common/api/transactions';
import { Colors } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { singleCategoryList } from './ProvisionsBox';

const Amendments = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [id, setId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activeTransactionAmendments } = useAppSelector((state: RootState) => state.transactionDetail);
  const { provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);

  const amendments: Amendment[] = activeTransactionAmendments || [];

  const orderedAmendments: Amendment[] = orderBy(
    amendments,
    amendment => {
      return amendment.insertedAt;
    },
    'asc',
  );

  const onDeleteAmendment = (amendement: Amendment): void => {
    dispatch(deleteAmendment(amendement.id!));
  };

  const onViewAmendment = (amendement: Amendment): void => {
    setIsModalOpen(true);
    setId(amendement.id);
  };

  const cardHeaderTemplate = (amendment: Amendment): JSX.Element => {
    return (
      <div className="column-custom-template">
        <Popup
          className="popup-info"
          content={amendment.name}
          trigger={<span className="name color-black">{amendment.name}</span>}
        />
      </div>
    );
  };

  const cardMenuItems = (amendment: Amendment): DropdownMenuItem[] => {
    return [
      {
        key: '1',
        label: 'View Details',
        icon: Icons.EyeOpen,
        onClick: () => onViewAmendment(amendment),
      },
      ...(amendment.fromTransactionDiscussion
        ? []
        : [
            {
              key: '2',
              label: 'Delete',
              icon: Icons.Delete,
              onClick: () => onDeleteAmendment(amendment),
            },
          ]),
    ];
  };

  const cardBodyTemplate = (amendment: Amendment): JSX.Element => {
    return (
      <Grid className="component-card-body color-blue-desaturated-dark">
        <Grid.Row className="p-none">
          <Grid.Column
            className="p-none"
            width={5}
          >
            Appears in:
          </Grid.Column>
          <Grid.Column
            className="p-none color-blue-very-dark-grayish"
            width={11}
          >
            Supplementary Agreement
          </Grid.Column>
        </Grid.Row>

        {amendment.fromTransactionDiscussion && (
          <Grid.Row className="p-none">
            <Grid.Column
              className="p-none"
              width={5}
            >
              Created in:
            </Grid.Column>
            <Grid.Column
              className="p-none color-blue-very-dark-grayish"
              width={11}
            >
              Discussion
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  };

  const cardConfigs: CardTemplateConfiguration<ProvisionCategoryClone, Amendment> = {
    columnConfiguration: {
      header: {
        titleField: 'name',
        showEdit: false,
      },
      width: '20rem',
    },
    cardConfiguration: {
      header: {
        headerTemplate: (amendment: Amendment) => cardHeaderTemplate(amendment),
        cardMenuItems: (amendment: Amendment) => cardMenuItems(amendment),
      },
      bodyTemplate: (amendment: Amendment) => cardBodyTemplate(amendment),
      onClick: onViewAmendment,
    },
  };

  const createAmendment = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsModalOpen(true);
    setId('');
  };

  return (
    <div className="transaction-provision-section border-sm-grayish-magenta-light">
      <h4 className="provisions-title color-blue-very-dark m-none">Custom Amendments</h4>
      <Button
        className="btn grey-bg add-remove"
        data-test="edit-button"
        onClick={createAmendment}
      >
        Add
      </Button>
      <CardViewTemplate<ProvisionCategoryClone, Amendment>
        categoryList={singleCategoryList(provisionCategoriesList, Colors.YELLOW)}
        configuration={cardConfigs}
        getCardElementsList={() => orderedAmendments}
      />

      <AmendmentCreatorModal
        id={id}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        fromTransactionDiscussion={false}
      />
    </div>
  );
};

export default Amendments;
